export enum Entgratart {
	None = 0,
	Manually = 1,
	Webern = 2,
	Trowalisieren = 3,
	BothSides = 4,
	Undefined = 5,
}

export function getEntgratartText(value: Entgratart) : string {
	switch (value) {
		case Entgratart.None:
			return "Nicht\u00A0entgraten";
		case Entgratart.Manually:
			return "Manuell";
		case Entgratart.Webern:
			return "Einseitig\u00A0(Unterseite)";
		case Entgratart.Trowalisieren:
			return "Trowalisieren";
		case Entgratart.BothSides:
			return "Beidseitig";
		case Entgratart.Undefined:
			return "Undefiniert";
		default:
			return "Nicht\u00A0verfügbar";
	}
}