var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.isValidForm,
            callback: function ($$v) {
              _vm.isValidForm = $$v
            },
            expression: "isValidForm",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { "non-linear": "", disabled: _vm.isCartValid },
                      model: {
                        value: _vm.stepCount,
                        callback: function ($$v) {
                          _vm.stepCount = $$v
                        },
                        expression: "stepCount",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "1",
                                editable: !_vm.currentOfferCalculating,
                                color: "accent",
                              },
                            },
                            [_vm._v(" Warenkorb ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "2",
                                editable:
                                  _vm.isCartValid &&
                                  !_vm.currentOfferCalculating,
                                color: "accent",
                              },
                            },
                            [_vm._v(" Adressen ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "3",
                                editable:
                                  _vm.isCartValid &&
                                  !_vm.currentOfferCalculating &&
                                  _vm.areAddressesValid,
                                color: "accent",
                              },
                            },
                            [_vm._v(" Lieferung ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "4",
                                editable:
                                  _vm.isCartValid &&
                                  !_vm.currentOfferCalculating &&
                                  _vm.areAddressesValid &&
                                  _vm.isValidForm &&
                                  _vm.isValidManufacturerSelected,
                                color: "accent",
                              },
                            },
                            [_vm._v(" Angebot ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0 ma-0" },
                                [
                                  !_vm.isCartValid && _vm.hasItemsInCart
                                    ? _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            " Einige Artikel in diesem Warenkorb werden derzeit blexonisiert, wurden noch nicht mit der neusten Version blexonisiert, oder der Preis hat sich geändert und wurde noch nicht neu berechnet."
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " Bitte berechnen Sie die Artikel neu oder wenden Sie sich bei Fragen an unser Helpdesk. "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex child-flex pa-0 ma-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "blexon-button",
                                          attrs: { disabled: !_vm.isCartValid },
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 2
                                            },
                                          },
                                        },
                                        [_vm._v(" Weiter ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "pa-0 mt-0" },
                                [_c("cart")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0 ma-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex child-flex pa-0 ma-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 1
                                            },
                                          },
                                        },
                                        [_vm._v("Zurück")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex child-flex pa-0 ma-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "blexon-button",
                                          attrs: {
                                            disabled:
                                              !_vm.isCartValid ||
                                              !_vm.areAddressesValid,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 3
                                            },
                                          },
                                        },
                                        [_vm._v(" Weiter ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "pa-0 mt-2 mx-0" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("p", { staticClass: "text-h6" }, [
                                        _vm._v("Lieferadresse"),
                                      ]),
                                      _vm.deliveryAddress.id > 0
                                        ? _c("address-tile", {
                                            key: _vm.deliveryAddressKey,
                                            attrs: {
                                              type: _vm.orderDeliveryType,
                                              propAddress: _vm.deliveryAddress,
                                              isOrder: false,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openAddressPicker(
                                                _vm.deliveryAddress.type
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Weitere Adressen... ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", {
                                    staticStyle: { margin: "0 -1px" },
                                    attrs: { vertical: "" },
                                  }),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("p", { staticClass: "text-h6" }, [
                                        _vm._v("Rechnungsadresse"),
                                      ]),
                                      _vm.invoiceAddress.id > 0
                                        ? _c("address-tile", {
                                            key: _vm.invoiceAddressKey,
                                            attrs: {
                                              type: _vm.orderInvoiceType,
                                              propAddress: _vm.invoiceAddress,
                                              isOrder: false,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openAddressPicker(
                                                _vm.invoiceAddress.type
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Weitere Adressen... ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex child-flex",
                                      attrs: { cols: "6", md: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.currentOfferCalculating,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 2
                                            },
                                          },
                                        },
                                        [_vm._v(" Zurück ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex child-flex",
                                      attrs: { cols: "6", md: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "blexon-button",
                                          attrs: {
                                            disabled:
                                              _vm.currentOfferCalculating ||
                                              !_vm.isValidForm ||
                                              !_vm.isValidManufacturerSelected,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 4
                                            },
                                          },
                                        },
                                        [_vm._v(" Weiter ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("offer-information", {
                                    on: {
                                      "recalculate-offer": function ($event) {
                                        return _vm.updateOffer()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.currentOffer != null
                                ? _c(
                                    "v-row",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isCountryValidForDelivery,
                                          expression:
                                            "isCountryValidForDelivery",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("delivery-component"),
                                          _c(
                                            "v-card",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [_vm._v(" Zahlungsart ")]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _vm.isBilledDigitally
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " Wir senden Ihnen eine Rechnung "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v(
                                                            "elektronisch"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " an " +
                                                            _vm._s(_vm.email) +
                                                            ". "
                                                        ),
                                                      ])
                                                    : _c("p", [
                                                        _vm._v(
                                                          " Wir senden Ihnen eine Rechnung "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v("per Post"),
                                                        ]),
                                                        _vm._v(
                                                          " an " +
                                                            _vm._s(
                                                              _vm.addressString
                                                            ) +
                                                            ". "
                                                        ),
                                                      ]),
                                                  !_vm.isBilledDigitally
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            title:
                                                              "Navigiert zu den Adresseinstellungen.",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "step-back"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-clipboard-edit"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Adresse ändern "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticStyle: { margin: "0 -1px" },
                                        attrs: { vertical: "" },
                                      }),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [_vm._v(" Auftragsnummer ")]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Sie können für diesen Auftrag eine eigene Auftragsnummer vergeben."
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " Wir erwähnen diese Nummer auf dem Lieferschein und der Rechnung."
                                                    ),
                                                  ]),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      placeholder:
                                                        "Ihre Auftragsnummer",
                                                      rules:
                                                        _vm.optionalStringMax35Rule,
                                                      color: "accent",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.customKommissionsnummer =
                                                          _vm.customKommissionsnummer.trim()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.customKommissionsnummer,
                                                      callback: function ($$v) {
                                                        _vm.customKommissionsnummer =
                                                          $$v
                                                      },
                                                      expression:
                                                        "customKommissionsnummer",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "v-card-title",
                                                { staticClass: "text-h5" },
                                                [_vm._v(" Benachrichtigungen ")]
                                              ),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " Wir senden die Auftragsbestätigung und die Benachrichtigung über die Fertigstellung Ihres Auftrags an Ihre E-Mail Adresse (" +
                                                        _vm._s(
                                                          _vm.userEmailAddress
                                                        ) +
                                                        "). Optional können diese Nachrichten an eine weitere E-Mail Adresse gesendet werden. In den "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click:
                                                            _vm.goToCustomerSettings,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Kundenkonto-Einstellungen"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " kann ein Standardwert für diese E-Mail definiert werden. "
                                                    ),
                                                  ]),
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "accent",
                                                      "hide-details": "",
                                                      label:
                                                        "Nachrichten an eine zusätzliche Adresse schicken",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onIsAdditionalEmailSelectedChanged()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.isAdditionalEmailSelected,
                                                      callback: function ($$v) {
                                                        _vm.isAdditionalEmailSelected =
                                                          $$v
                                                      },
                                                      expression:
                                                        "isAdditionalEmailSelected",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isAdditionalEmailSelected,
                                                        expression:
                                                          "isAdditionalEmailSelected",
                                                      },
                                                    ],
                                                    attrs: {
                                                      placeholder:
                                                        "E-Mail Adresse",
                                                      rules:
                                                        _vm.optionalEmailRule,
                                                      color: "accent",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        _vm.additionalOrderConfirmationEmail =
                                                          _vm.additionalOrderConfirmationEmail.trim()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.additionalOrderConfirmationEmail,
                                                      callback: function ($$v) {
                                                        _vm.additionalOrderConfirmationEmail =
                                                          $$v
                                                      },
                                                      expression:
                                                        "additionalOrderConfirmationEmail",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "4" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex child-flex",
                                      attrs: { cols: "6", md: "2" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.stepCount = 3
                                            },
                                          },
                                        },
                                        [_vm._v("Zurück")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("offer-information", {
                                    on: {
                                      "recalculate-offer": function ($event) {
                                        return _vm.updateOffer()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.currentOffer !== null
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [_c("summary-component")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.addressPickerDialog,
                callback: function ($$v) {
                  _vm.addressPickerDialog = $$v
                },
                expression: "addressPickerDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v("Adresse für Bestellung auswählen"),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.addressPickerDialog
                        ? _c("addresses-overview", {
                            attrs: {
                              type: _vm.currentAddressPickerType,
                              isOrder: true,
                            },
                            on: {
                              setAddressForOrder: () => {
                                _vm.refreshAddresses()
                                _vm.addressPickerDialog = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.addressPickerDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }