
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { PowderCoatingTreatmentDto } from '@/dtos/powderCoatingTreatmentDto';
import { ArticleFaceType, PowderFinish, PowderStructure, PowderApplication } from '@/dtos/treatmentTypes';
import { SurfaceColorDto } from '@/dtos/surfaceColorDto';
import { PowderDto } from '@/dtos/powderDto';

@Component
export default class ArticleTreatmentEditDialog extends Vue {
	@Prop()
	private articleTreatmentEditDialog!: boolean;
	@Prop()
	private articles!: CustomerArticleDto[];
	@Prop()
	private isUpdatingArticleData!: boolean;
	private selectedPowderFinish: PowderFinish = -1;
	private showAllPowders: boolean = true;
    private powderCoatingFinishOptions: any = [
		{
			name: 'Alle',
			value: -1
		},
        {
            name: 'Matt',
            value: PowderFinish.Matt
        },
        {
            name: 'Glanz',
            value: PowderFinish.Glossy
        },
    ];
    private selectedPowderStructure: PowderStructure = -1;
	private powderStructureHint: string = '';
    private powderCoatingStructureOptions: any = [
		{
			name: 'Alle',
			value: -1
		},
		{
			name: 'Glatt',
			value: PowderStructure.Smooth
		},
        {
            name: 'Fein',
            value: PowderStructure.Fine
        },
        {
            name: 'Grob',
            value: PowderStructure.Rough
        },
    ];
    private selectedPowderFace: ArticleFaceType = 2;
    private articleFaceOptions: any = [
        {
            name: 'Oberseite',
            value: ArticleFaceType.Visible
        },
        {
            name: 'Unterseite',
            value: ArticleFaceType.Back
        },
        {
            name: 'Beidseitig',
            value: ArticleFaceType.Both
        },
    ];
	private selectedPowderId: number = -1;
    private selectedPowderColorId: number = -1;
    private ralFilterTerm: string = '';

	private get isSingleArticle() {
		return this.articles.length === 1;
	}

	private get singleArticle() {
		return this.articles[0];
	}

	private get anyArticlesPowdercoated() {
		return this.articles.some((a:CustomerArticleDto) => a.powderCoatingTreatment != null);
	}

	private get anyArticleCalculating() {
		return this.articles.some((a:CustomerArticleDto) => a.calculating);
	}

	private get powders() {
        return this.$store.state.powders;
    }

	private get filteredPowders() {
		if (!this.powders || this.powders.length <= 0) return [];
		// We're only showing powders for external use.
		// There is an external use powder for every color we have.
		// This prevents duplicates showing up in the selector.
		let filtered = this.powders.filter((p:PowderDto) => 
			(p.available) &&
			(p.application == PowderApplication.ExternalFassade || (p.structure == PowderStructure.Rough && p.application == PowderApplication.ExternalIndustry)) &&
			(this.selectedPowderColorId < 0 || p.surfaceColor.id === this.selectedPowderColorId) &&
			(this.selectedPowderStructure < 0 || p.structure == this.selectedPowderStructure) &&
			(this.selectedPowderFinish < 0 || p.finish == this.selectedPowderFinish) &&
			(this.showAllPowders === true || this.isStandardPowder(p.setupCost))
		);

		let cleaned = new Array<PowderDto>();
		filtered.forEach((p:PowderDto) => {
			if (!cleaned.some((c:any) => p.surfaceColor.id == c.surfaceColor.id &&
				p.structure == c.structure &&
				p.finish == c.finish)) 
			{
				cleaned.push(p);
			}
		})

		cleaned = cleaned.sort((c1:PowderDto, c2:PowderDto) => {
			if (this.isStandardPowder(c1.setupCost) == this.isStandardPowder(c2.setupCost)) {
				if (c1.structure === c2.structure) {
					return c1.finish - c2.finish;
				} else {
					return c1.structure - c2.structure;
				}
			} else {
				return this.isStandardPowder(c1.setupCost) ? -1 : 1
			}
		});

		if (cleaned.length <= 0) {
			// Deselect a chosen powder if it's not in the current filter anymore
			this.selectedPowderId = -1;
		}
		if (cleaned.length === 1) {
			// If there is only one powder in the current selection, select it
			this.selectedPowderId = cleaned[0].id;
		}
		return cleaned;
	}

	private get selectedPowder(): PowderDto | null {
		if (this.selectedPowderId < 0) return null;
		return this.powders.filter((p: PowderDto) => p.id == this.selectedPowderId)[0];
	}

	private get colors() {
		return this.$store.getters.getAllSurfaceColors;
	}

	private get filteredSurfaceColors() {
		let filtered = this.colors;
		if (!this.showAllPowders) {
			filtered = filtered
				.filter((c: SurfaceColorDto) => this.powders.some((p:PowderDto) => 
					p.surfaceColor.id == c.id && this.isStandardPowder(p.setupCost)));
		}
		if (this.ralFilterTerm.length > 0) {
			filtered = filtered
				.filter((c: SurfaceColorDto) => c.ralCode.includes(this.ralFilterTerm));
		}
		return filtered;
	}

	private isStandardPowder(setupCost: number) {
		return setupCost <= 0;
	}

	private isColorAvailable(colorId: number) {
		return this.powders.some((p:PowderDto) => 
			p.available &&
			p.surfaceColor.id === colorId &&
			(p.application == PowderApplication.ExternalFassade || (p.structure == PowderStructure.Rough && p.application == PowderApplication.ExternalIndustry)) &&
			(this.selectedPowderStructure < 0 || p.structure == this.selectedPowderStructure) &&
			(this.selectedPowderFinish < 0 || p.finish == this.selectedPowderFinish) &&
			(this.showAllPowders === true || this.isStandardPowder(p.setupCost))
		);
	}

	private get isPowderCoatingAvailable() {
        return this.$store.state.isPowderCoatingAvailable;
    }

	private get powderTreatmentValid() {
		return this.selectedPowderId > 0 && 
			this.selectedPowderFace >= 0 &&
			this.selectedPowder?.available;
	}

	private getStructureText(value: number) {
		return this.powderCoatingStructureOptions.filter((p:any) => p.value === value)[0].name;
	}

	private getFinishText(value: number) {
		return this.powderCoatingFinishOptions.filter((p:any) => p.value === value)[0].name;
	}

	private getColorClass(colorId: number) {
		if (this.isColorAvailable(colorId)) return ''
		if (this.selectedPowderColorId == colorId) {
			return 'selectedUnavailableColor'
		} else {
			return 'unavailableColor'
		}
	}

	@Watch('articleTreatmentEditDialog')
	private async openTreatmentDialog() {
		if (this.articleTreatmentEditDialog === true) {
			if (this.powders.length === 0) {
				await this.getPowders();
			}
			this.showAllPowders = true;
			if (this.isSingleArticle && this.singleArticle.powderCoatingTreatment != null) {
				// this.selectedPowderFinish = this.singleArticle.powderCoatingTreatment.powder.finish;
				// this.selectedPowderStructure = this.singleArticle.powderCoatingTreatment.powder.structure;
				this.selectedPowderFinish = -1;
				this.selectedPowderStructure = -1;
				this.selectedPowderFace = this.singleArticle.powderCoatingTreatment.treatmentFace;
				this.selectedPowderColorId = this.singleArticle.powderCoatingTreatment.powder.surfaceColor.id;
				this.selectedPowderId = this.singleArticle.powderCoatingTreatment.powder.id;
			} else {
				this.selectedPowderId = -1;
				this.selectedPowderColorId = -1;
				this.selectedPowderFinish = -1;
				this.selectedPowderStructure = -1;
				this.selectedPowderFace = 2;
			}
		}
	}

    private async getPowders() {
        try {
            const response = await httpClient().get('Article/GetPowders');
            this.$store.commit('setPowders', response.data)
        } catch(err) {
            this.$store.dispatch('setSnackbarErrorText', 'Pulver konnten nicht geladen werden.');
        }
    }

	private async saveTreatments() {
		if (!this.isUpdatingArticleData && this.powderTreatmentValid && this.isPowderCoatingAvailable) {
			this.setUpdating(true);
			this.articles.forEach((article:CustomerArticleDto, index, array) => {
				let treatmentDto: PowderCoatingTreatmentDto = {
					articleId: article.articleId,
					powder: this.selectedPowder ?? new PowderDto(),
					treatmentFace: this.selectedPowderFace,
					hasValue: true,
				}
	
				httpClient().post(`article/AddOrUpdateTreatmentOfArticle`, treatmentDto)
					.then(() => {
						this.$store.commit('addOrUpdateTreatmentForArticle', treatmentDto);
					})
					.catch(() => this.$store.dispatch(
						'setSnackbarErrorText', 
						'Die Oberflächenbehandlung von Artikel ' + article.articleId + ' konnte nicht gespeichert werden.'))
	
				this.$store.commit('setArticleStateToCalculating', treatmentDto.articleId);
			})
			this.$store.dispatch('setSnackbarText', 'Die Oberflächenbehandlung wurde gespeichert.');
			this.setUpdating(false);
			this.closeDialog();
        }
    }

    private async deleteTreatments() {
		if (!this.isUpdatingArticleData) {
			this.setUpdating(true);

			this.articles.forEach((article:CustomerArticleDto, index, array) => {
				// Don't do anything if article already has no powder coating.
				if (article.powderCoatingTreatment == null) return;
				let data = {
					value: article.articleId
				}

				httpClient().post(`article/DeleteTreatmentOfArticle`, data)
					.then(() => {
						this.$store.commit('deleteTreatmentForArticle', article.articleId);
					})
					.catch(() => this.$store.dispatch(
						'setSnackbarErrorText', 
						'Die Oberflächenbehandlung von Artikel ' + article.articleId + ' konnte nicht gelöscht werden.'))
				
				this.$store.commit('setArticleStateToCalculating', article.articleId);
			})
			this.$store.dispatch('setSnackbarText', 'Die Oberflächenbehandlung wurde gelöscht.');
			this.setUpdating(false);
			this.closeDialog();
		}
    }

	private setUpdating(value: boolean) {
		this.$emit('isUpdating', value);
	}

	private closeDialog() {
		this.$emit('closeDialog');
	}
}

