var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h2", [_vm._v("Stücklisten")]),
      !_vm.savedCartsExist
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  " Noch keine Stücklisten gespeichert. Legen Sie Teile in den Warenkorb, um aus dem Warenkorb eine neue Stückliste zu erstellen. "
                ),
              ]),
            ],
            1
          )
        : _c(
            "v-row",
            { attrs: { wrap: "", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "8", xl: "10" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.sortedSavedCarts,
                      filter: _vm.savedCartFilter,
                      "item-text": "name",
                      "item-value": "id",
                      placeholder: "Liste auswählen...",
                      color: "accent",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-row",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  wrap: "",
                                  justify: "start",
                                  align: "end",
                                },
                              },
                              [
                                _c("div", { staticClass: "mr-3" }, [
                                  _vm._v(_vm._s(data.item.name) + ":"),
                                ]),
                                _vm._l(
                                  data.item.positions,
                                  function (article, index) {
                                    return _c(
                                      "div",
                                      { key: article.articleId },
                                      [
                                        index < 5
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "rgba(0, 0, 0, 1)",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-img",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "py-1",
                                                                attrs: {
                                                                  contain: "",
                                                                  width: "45px",
                                                                  height:
                                                                    "45px",
                                                                  src: _vm.getThumbnailUrl(
                                                                    article.articleId
                                                                  ),
                                                                },
                                                              },
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      article.customerArticleName
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " Anzahl: " +
                                                    _vm._s(article.quantity) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : index === 6
                                          ? _c("div", { staticClass: "ml-3" }, [
                                              _vm._v("..."),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedCartId,
                      callback: function ($$v) {
                        _vm.selectedCartId = $$v
                      },
                      expression: "selectedCartId",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedCartId <= 0,
                        title: "Stückliste löschen.",
                      },
                      on: {
                        click: function ($event) {
                          _vm.deleteSavedCartDialog = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-delete"),
                      ]),
                      _vm._v("Löschen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.selectedCartId > 0
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.requiredIntegerRule,
                      label: "Anzahl",
                      color: "accent",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.sentToCartAmount =
                          _vm.sentToCartAmount > 1 ? _vm.sentToCartAmount : 1
                      },
                    },
                    model: {
                      value: _vm.sentToCartAmount,
                      callback: function ($$v) {
                        _vm.sentToCartAmount = $$v
                      },
                      expression: "sentToCartAmount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: {
                        disabled: _vm.selectedCartId <= 0,
                        title:
                          "Alle Artikel der Stückliste in den Warenkorb legen. Bei vorhandenen Artikeln wird die Menge erhöht.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.loadArticlesFromSavedCart()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cart-arrow-right"),
                      ]),
                      _vm.sentToCartAmount > 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.sentToCartAmount) +
                                " x zum Warenkorb hinzufügen"
                            ),
                          ])
                        : _c("span", [_vm._v("Zu Warenkorb hinzufügen")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedCartId > 0
        ? _c(
            "v-row",
            { attrs: { wrap: "" } },
            _vm._l(_vm.currentlySelectedCart.positions, function (article) {
              return _c(
                "v-col",
                {
                  key: article.articleId,
                  attrs: { cols: "12", xs: "12", md: "6", lg: "4", xl: "3" },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pt-2" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          height: "90px",
                          src: _vm.getThumbnailUrl(article.articleId),
                        },
                      }),
                      _c(
                        "v-card-text",
                        [
                          _c("strong", [
                            _vm._v(_vm._s(article.customerArticleName)),
                          ]),
                          _c("br"),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v(" " + _vm._s(article.articleId) + " "),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Stk.",
                                      label: "Anzahl",
                                      rules: _vm.allowedCartAmountRule,
                                      type: "number",
                                      color: "accent",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.setNewSavedCartArticleQuantity(
                                          article.articleId,
                                          article.quantity
                                        )
                                      },
                                    },
                                    model: {
                                      value: article.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(article, "quantity", $$v)
                                      },
                                      expression: "article.quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    small: "",
                                    title: "Aus Stückliste entfernen",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeArticleFromSavedCart(
                                        article.articleId
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-delete")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "blexon-button",
                                  attrs: {
                                    small: "",
                                    title: "Zum Warenkorb hinzufügen",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addArticleFromSavedCartToCurrentCart(
                                        article.articleId,
                                        article.quantity
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-cart-arrow-right"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.deleteSavedCartDialog,
                callback: function ($$v) {
                  _vm.deleteSavedCartDialog = $$v
                },
                expression: "deleteSavedCartDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Stückliste löschen")]),
                  _c("v-card-text", [
                    _vm._v(
                      " Sind Sie sicher, dass Sie die Stückliste löschen wollen?"
                    ),
                    _c("br"),
                    _vm._v(
                      " Die Artikel, welche in der Liste enthalten sind, werden nicht gelöscht. "
                    ),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.deleteSavedCartDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            text: "",
                            title: "Löscht die Stückliste endgültig.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteSavedCart()
                            },
                          },
                        },
                        [_vm._v(" Löschen ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }