var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          " Bitte wählen Sie alle Positionen aus, welche von dieser Meldung betroffen sind. "
        ),
      ]),
      _c("v-simple-table", {
        staticClass: "elevation-1",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticStyle: { width: "20px" } }),
                    _c(
                      "th",
                      {
                        staticClass: "text-right",
                        staticStyle: { "max-width": "50px" },
                      },
                      [_vm._v("Position")]
                    ),
                    _c("th", { staticStyle: { "max-width": "100px" } }, [
                      _vm._v("Bild"),
                    ]),
                    _c("th", [_vm._v("Beschreibung")]),
                    _c("th", { staticClass: "text-right" }, [_vm._v("Menge")]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Stückpreis"),
                    ]),
                    _c("th", { staticClass: "text-right" }, [_vm._v("Total")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.positions, function (position, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        { staticStyle: { width: "20px" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "hide-details": "",
                              disabled: _vm.ticketClosed,
                              color: "accent",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("changed")
                              },
                            },
                            model: {
                              value: position.part.selectedForComplaint,
                              callback: function ($$v) {
                                _vm.$set(
                                  position.part,
                                  "selectedForComplaint",
                                  $$v
                                )
                              },
                              expression: "position.part.selectedForComplaint",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { "max-width": "50px" },
                        },
                        [_vm._v(" " + _vm._s(position.position) + " ")]
                      ),
                      _c(
                        "td",
                        { staticStyle: { "max-width": "100px" } },
                        [
                          _c("v-img", {
                            staticStyle: { margin: "5px" },
                            attrs: {
                              contain: "",
                              "max-height": "80px",
                              src: _vm.getThumbnailUrl(position.part.partId),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [
                        _c("b", [_vm._v(_vm._s(position.part.description))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          " PartId: " + _vm._s(position.part.partId) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          " Material: " +
                            _vm._s(position.part.materialBezeichnung) +
                            " "
                        ),
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { "max-width": "100px" },
                        },
                        [_vm._v(" " + _vm._s(position.part.menge) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { "max-width": "100px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPrice(
                                  position.part.customerPricePerPart
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { "max-width": "100px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPrice(
                                  position.part.customerPricePerPart *
                                    position.part.menge
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }