var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-container",
              {
                staticClass: "articleTile",
                class: { "elevation-10": hover },
                staticStyle: { position: "relative" },
              },
              [
                _c(
                  "v-overlay",
                  {
                    attrs: { absolute: true },
                    model: {
                      value: _vm.deleteOverlay,
                      callback: function ($$v) {
                        _vm.deleteOverlay = $$v
                      },
                      expression: "deleteOverlay",
                    },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "accent" },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "ma-0", attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0",
                        attrs: {
                          cols: _vm.isMultiSelectionMode || hover ? 10 : 11,
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "subtitle-1 ma-0 pa-0 truncate" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass: "pointerElement",
                                                on: {
                                                  click:
                                                    _vm.openArticleEditDialog,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.article
                                                      .customerArticleName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.article.customerArticleName) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    hover || _vm.isMultiSelectionMode
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "1" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "50" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  disabled: _vm.article.locked,
                                                  color: "accent",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.isArticleSelected,
                                                  callback: function ($$v) {
                                                    _vm.isArticleSelected = $$v
                                                  },
                                                  expression:
                                                    "isArticleSelected",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(
                                  " Auswählen für Mehrfachaktion. Mögliche Aktionen werden oben angezeigt. "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "1" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                title:
                                                  "Öffnet das Kontextmenü zum Artikel.",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.isArticleLockedAndNotAdmin ||
                                        _vm.isCalculating,
                                    },
                                    on: { click: _vm.openArticleEditDialog },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "accent" } },
                                          [_vm._v("mdi-pencil")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Bearbeiten "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.isArticleLockedAndNotAdmin ||
                                        _vm.isCalculating,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkDeleteArticle()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "accent" } },
                                          [_vm._v("mdi-delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Löschen "),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.isArticleLockedAndAdmin
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: { disabled: _vm.isCalculating },
                                        on: {
                                          click: function ($event) {
                                            return _vm.unlockArticle()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "accent" } },
                                              [_vm._v("mdi-lock-open")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(" Unlock "),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isArticleUnlockedAndAdmin
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: { disabled: _vm.isCalculating },
                                        on: {
                                          click: function ($event) {
                                            return _vm.lockArticle()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "accent" } },
                                              [_vm._v("mdi-lock")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(" Lock "),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0",
                    staticStyle: { "min-height": "200px" },
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-2", attrs: { cols: "6" } },
                      [
                        _c("v-img", {
                          staticClass: "previewImage",
                          style: {
                            cursor:
                              _vm.article.calculating || _vm.article.migrated
                                ? "not-allowed"
                                : "pointer",
                          },
                          attrs: {
                            contain: "",
                            width: "160px",
                            height: "160px",
                            title:
                              "Öffnet das CAD Modell im Browser. Im integrierten CAD Editor von Blexon können Die Gewinde, Senkungen, Gravuren, Schweissnähte usw. einfügen / bearbeiten. Ausserdem Maße kontrollieren, Meldungen anzeigen und Fehlerursachen beheben.",
                            src: _vm.getThumbnailUrl(_vm.article.articleId),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openCadDialog()
                            },
                          },
                        }),
                        _vm.hasFeatures
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "accent" } },
                                                [_vm._v("mdi-cogs")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-2",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("Bearbeitungen")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("div", [
                                  _vm.article.powderCoatingTreatment != null
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "table-cell",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            style: {
                                              display: "inline-block",
                                              width: "20px",
                                              height: "20px",
                                              background:
                                                _vm.article
                                                  .powderCoatingTreatment.powder
                                                  .surfaceColor.hexCode + "ff",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "vertical-align": "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "RAL " +
                                                  _vm._s(
                                                    _vm.article
                                                      .powderCoatingTreatment
                                                      .powder.surfaceColor
                                                      .ralCode
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.article
                                                      .powderCoatingTreatment
                                                      .powder.surfaceColor.name
                                                  ) +
                                                  ") - " +
                                                  _vm._s(
                                                    _vm.getStructureText(
                                                      _vm.article
                                                        .powderCoatingTreatment
                                                        .powder.structure
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.getFinishText(
                                                      _vm.article
                                                        .powderCoatingTreatment
                                                        .powder.finish
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.article.featureDtos != null &&
                                  _vm.article.featureDtos.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.article.featureDtos,
                                          function (feature, index) {
                                            return _c("div", { key: index }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(feature.count) +
                                                  " x " +
                                                  _vm._s(feature.friendlyName) +
                                                  " "
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm.article.hasEngravings
                                    ? _c("div", [_vm._v(" Gravur ")])
                                    : _vm._e(),
                                  _vm.article.weldlines != null &&
                                  _vm.article.weldlines.length > 0
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getWeldlineString()) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption pa-0",
                        attrs: { cols: "6" },
                      },
                      [
                        _vm.article.customerArticleId !== ""
                          ? _c("p", { staticClass: "ma-0 pa-0" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "pointerElement",
                                  on: { click: _vm.openArticleEditDialog },
                                },
                                [
                                  _c("strong", [_vm._v("Artikel Nr:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.article.customerArticleId) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "ma-0 pa-0" }, [
                          _c("strong", [_vm._v("Blexon Nr:")]),
                          _vm._v(" " + _vm._s(_vm.article.articleId) + " "),
                        ]),
                        _vm.article.materialId > 0
                          ? _c("p", { staticClass: "ma-0 pa-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.article.materialname) + " "
                              ),
                            ])
                          : _c("div", [
                              _vm.article.werkstoffId > 0
                                ? _c("p", { staticClass: "ma-0 pa-0" }, [
                                    _c("strong", [_vm._v("Werkstoff:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.article.werkstoffname) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.article.thickness > 0
                                ? _c("p", { staticClass: "ma-0 pa-0" }, [
                                    _c("strong", [_vm._v("Dicke:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.article.thickness) +
                                        "mm "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            "margin-right": "4px",
                            height: "10px",
                            width: "10px",
                            "background-color": "rgb(69, 160, 205)",
                          },
                        }),
                        _vm._v(
                          " Oberseite" +
                            _vm._s(_vm.getZusatzbezeichnungOberseite())
                        ),
                        _c("br"),
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            "margin-right": "4px",
                            height: "10px",
                            width: "10px",
                            "background-color": "rgb(140, 160, 170)",
                          },
                        }),
                        _vm._v(
                          " Unterseite" +
                            _vm._s(_vm.getZusatzbezeichnungUnterseite())
                        ),
                        _c("br"),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.article.length > 0,
                                expression: "article.length > 0",
                              },
                            ],
                            staticClass: "ma-0 pa-0",
                          },
                          [
                            _c("strong", [_vm._v("LxBxH:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("round2Decimal")(_vm.article.length)
                                ) +
                                " x " +
                                _vm._s(
                                  _vm._f("round2Decimal")(_vm.article.width)
                                ) +
                                " x " +
                                _vm._s(
                                  _vm._f("round2Decimal")(_vm.article.height)
                                ) +
                                "mm "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.article.length > 0,
                                expression: "article.length > 0",
                              },
                            ],
                            staticClass: "ma-0 pa-0",
                          },
                          [
                            _c("strong", [_vm._v("Gewicht:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("round3Decimal")(_vm.article.weight)
                                ) +
                                "kg "
                            ),
                          ]
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ma-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 pointerElement",
                                attrs: { cols: "auto" },
                                on: { click: _vm.openArticleEditDialog },
                              },
                              [
                                _c("strong", [_vm._v("Entgraten: ")]),
                                _c("a", { staticClass: "articleTileLink" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getEntgratartText(
                                        _vm.article.entgratart
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.isPowderCoatingAvailable
                          ? _c(
                              "v-row",
                              {
                                staticClass: "ma-0",
                                class: _vm.canChangePowderCoating
                                  ? "pointerElement"
                                  : "",
                                style: _vm.canChangePowderCoating
                                  ? ""
                                  : "margin-top: -1px !important",
                                attrs: { align: "end" },
                                on: {
                                  click: function ($event) {
                                    if (_vm.canChangePowderCoating)
                                      _vm.articleTreatmentEditDialog = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: {
                                      cols: _vm.canChangePowderCoating
                                        ? 12
                                        : 11,
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v("Pulverbeschichten: "),
                                    ]),
                                    _vm.canChangePowderCoating
                                      ? _c(
                                          "a",
                                          { staticClass: "articleTileLink" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.powderCoatingText)
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.powderCoatingText)),
                                        ]),
                                  ]
                                ),
                                !_vm.canChangePowderCoating
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { cols: "1" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "blexon-button",
                                            staticStyle: {
                                              width: "16px",
                                              height: "16px",
                                              "margin-bottom": "1px",
                                            },
                                            attrs: {
                                              title:
                                                "Warum ist Pulverbeschichtung nicht auswählbar?",
                                              icon: "",
                                              "x-small": "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                _vm.powderCoatingNotEditableDialog = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("mdi-help")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.certificates.length > 0
                          ? _c(
                              "v-row",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "11" },
                                  },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [_vm._v("Zertifikat:")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.certificate,
                                          expression: "certificate",
                                        },
                                      ],
                                      staticStyle: {
                                        cursor: "pointer",
                                        "accent-color": "#efbb20",
                                        "vertical-align": "text-bottom",
                                        "margin-right": "10px",
                                        position: "relative",
                                        top: "-1px",
                                      },
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.certificate)
                                          ? _vm._i(_vm.certificate, null) > -1
                                          : _vm.certificate,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.certificate,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.certificate = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.certificate = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.certificate = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.certificates[0])),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "blexon-button",
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px",
                                          "margin-bottom": "1px",
                                        },
                                        attrs: {
                                          title:
                                            "Weitere Informationen über Zertifikat",
                                          icon: "",
                                          "x-small": "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.certificateHelperDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("mdi-help")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.article.calculating
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: "26",
                                indeterminate: "",
                                color: "accent",
                              },
                            }),
                            _c("p", { staticClass: "text-caption" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.article.calculatingMessage) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.article.calculating
                  ? _c(
                      "v-row",
                      { staticClass: "mx-0 mt-2 align-center" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  disabled:
                                    _vm.article.camMessages.filter(
                                      (m) => m.state == 0
                                    ).length <= 0,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.isStatusChipLinkToCad
                                            ? _c(
                                                "v-chip",
                                                _vm._g(
                                                  {
                                                    staticClass: "text-caption",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    attrs: {
                                                      color:
                                                        _vm.articleStateColor,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openCadDialog()
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.articleStateIcon
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.articleStateText
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-chip",
                                                _vm._g(
                                                  {
                                                    staticClass: "text-caption",
                                                    attrs: {
                                                      color:
                                                        _vm.articleStateColor,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.articleStateIcon
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.articleStateText
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._l(
                                  _vm.article.camMessages.filter(
                                    (m) => m.state == 0
                                  ),
                                  function (message, index) {
                                    return _c(
                                      "p",
                                      { key: index, staticClass: "ma-0" },
                                      [
                                        _c("span", [
                                          _vm._v("- " + _vm._s(message.title)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                _vm.article.state == 2
                                  ? _c("p", [
                                      _vm._v(
                                        " Öffnen Sie den Artikel im CAD und überprüfen Sie den Fehler. Sie können den Artikel zur kostenlosen Abklärung an unser Helpdesk senden. "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.article.state == 6
                                  ? _c("p", [
                                      _vm._v(
                                        " Bitte öffnen Sie den Artikel im CAD und definieren Sie, was mit den erkannten Bearbeitungen gemacht werden soll. "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            !_vm.article.calculating &&
                            (_vm.article.state == 0 ||
                              _vm.article.state == 1) &&
                            !_vm.article.migrated &&
                            !_vm.article.locked
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "pa-0 article-button",
                                                          attrs: {
                                                            small: "",
                                                            color:
                                                              _vm.priceStateColor,
                                                            block: "",
                                                            disabled:
                                                              _vm.article
                                                                .priceState ==
                                                              4,
                                                            title:
                                                              "Öffnet die Preisliste des Artikels.",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm.article.priceState ==
                                                      4
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                size: "15",
                                                                indeterminate:
                                                                  "",
                                                              },
                                                            }
                                                          )
                                                        : _vm.showPriceError
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" Preisliste "),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm.article.priceState == 0
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "background-color": "white",
                                                  padding: "5px",
                                                  "max-width": "200px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Die Preise für diesen Artikel müssen noch berechnet werden."
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " Falls die Berechnung nicht automatisch startet, kontaktieren Sie unser Helpdesk. "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.article.priceState == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "background-color": "white",
                                                  padding: "5px",
                                                  "max-width": "200px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Materialpreise unterliegen aktuell starken Marktschwankungen. Deswegen sind unsere Preise nur eine beschränkte Zeit gültig. Die Preise für diesen Artikel müssen neu berechnet werden."
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "blexon-article-button",
                                                    attrs: {
                                                      small: "",
                                                      block: "",
                                                      title:
                                                        "Berechnet und aktualisiert die Preise für den Artikel.",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.recalculatePrice()
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Neu Berechnen ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "speech-bubble-float",
                                          {
                                            attrs: {
                                              hintId: _vm.priceListHintId,
                                              message: _vm.priceListHintText,
                                              title: _vm.priceListHintTitle,
                                            },
                                          },
                                          [
                                            _vm.article.priceState == 2
                                              ? _c(
                                                  "v-simple-table",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c("thead", [
                                                      _c(
                                                        "tr",
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "center",
                                                              },
                                                              attrs: {
                                                                colspan:
                                                                  _vm
                                                                    .availablePriceModels
                                                                    .length + 2,
                                                                title:
                                                                  "Erfahren Sie hier, wie Sie Ihre Artikel noch günstiger bekommen.",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "blexon-button mr-2",
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.preisInformationenDialog = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-help"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(
                                                                " Preishinweis "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.availablePriceModels
                                                        .length > 1
                                                        ? _c("tr", [
                                                            _c("th"),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                                attrs: {
                                                                  colspan:
                                                                    _vm
                                                                      .availablePriceModels
                                                                      .length,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Preis / Stk. [" +
                                                                    _vm._s(
                                                                      _vm.waehrung
                                                                    ) +
                                                                    "] "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("th"),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.availablePriceModels
                                                        .length > 1
                                                        ? _c(
                                                            "tr",
                                                            [
                                                              _c("th", [
                                                                _vm._v(
                                                                  " Menge "
                                                                ),
                                                              ]),
                                                              _vm._l(
                                                                _vm.availablePriceModels,
                                                                function (
                                                                  model
                                                                ) {
                                                                  return _c(
                                                                    "th",
                                                                    {
                                                                      key: model.value,
                                                                      staticClass:
                                                                        "text-center",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            model.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c("th"),
                                                            ],
                                                            2
                                                          )
                                                        : _c("tr", [
                                                            _c("th", [
                                                              _vm._v(" Menge "),
                                                            ]),
                                                            _c(
                                                              "th",
                                                              {
                                                                staticClass:
                                                                  "text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Preis/Stk. [" +
                                                                    _vm._s(
                                                                      _vm.waehrung
                                                                    ) +
                                                                    "] "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("th"),
                                                          ]),
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.sortedPrices,
                                                        function (
                                                          price,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "text-caption",
                                                            },
                                                            [
                                                              price.quantity ==
                                                              1000
                                                                ? _c("td", [
                                                                    _vm._v(
                                                                      "ab " +
                                                                        _vm._s(
                                                                          price.quantity
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        price.quantity
                                                                      )
                                                                    ),
                                                                  ]),
                                                              _vm._l(
                                                                _vm.availablePriceModels,
                                                                function (
                                                                  model
                                                                ) {
                                                                  return _c(
                                                                    "td",
                                                                    {
                                                                      key: model.value,
                                                                      staticClass:
                                                                        "text-right",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getModelPrice(
                                                                              price.quantity,
                                                                              model.value
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-right":
                                                                      "5px",
                                                                    "padding-left":
                                                                      "3px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        color:
                                                                          "accent",
                                                                        small:
                                                                          "",
                                                                        disabled:
                                                                          _vm.isCartButtonDisabled,
                                                                        title:
                                                                          price.quantity +
                                                                          " Stück in den Warenkorb",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.amountToAddToCart =
                                                                              price.quantity
                                                                            _vm.addToCart()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-cart-arrow-down "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.article.priceState == 3
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "background-color": "white",
                                                  padding: "5px",
                                                  "max-width": "200px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Für diesen Artikel konnten die Preise nicht automatisch berechnet werden."
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " Bitte senden Sie den Artikel für eine kostenlose Abklärungen an unser Helpdesk."
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isCartButtonRowVisible
                  ? _c(
                      "v-row",
                      { staticClass: "mx-0 my-0 mt-2 align-center" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 pr-2", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-form",
                              {
                                model: {
                                  value: _vm.isValidAmount,
                                  callback: function ($$v) {
                                    _vm.isValidAmount = $$v
                                  },
                                  expression: "isValidAmount",
                                },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "Menge",
                                    type: "number",
                                    rules: _vm.allowedCartAmountRule,
                                    color: "accent",
                                    dense: "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.amountToAddToCart,
                                    callback: function ($$v) {
                                      _vm.amountToAddToCart = _vm._n($$v)
                                    },
                                    expression: "amountToAddToCart",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "pa-0 blexon-article-button",
                                                  attrs: {
                                                    small: "",
                                                    block: "",
                                                    disabled:
                                                      _vm.isCartButtonDisabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.addToCart()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-cart-arrow-down"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" In Warenkorb "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm.isCartButtonDisabled &&
                                _vm.article.hasEngravings &&
                                _vm.article.powderCoatingTreatment != null
                                  ? _c("span", [
                                      _vm._v(
                                        " Artikel mit Pulverbeschichtung und Gravur können nicht bestellt werden."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Die Gravur würde von der Pulverbeschichtung vollständig überdeckt."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Entfernen Sie entweder die Gravur oder die Pulverbeschichtung, um den Artikel bestellen zu können. "
                                      ),
                                    ])
                                  : !_vm.article.canBeProduced
                                  ? _c("span", [
                                      _vm._v(
                                        " Artikel kann aktuell in " +
                                          _vm._s(_vm.region) +
                                          " nicht bestellt werden. "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " Fügt den Artikel zum Warenkorb hinzu. "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "mx-0 my-0" },
                  [
                    _vm.isHelpdeskButtonVisible
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button my-0",
                                                attrs: {
                                                  small: "",
                                                  block: "",
                                                  disabled:
                                                    _vm.isHelpdeskButtonDisabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.reasonToSendArticleToHelpdesk = 0
                                                    _vm.showHelpdeskDialogue = true
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" An Helpdesk senden ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Artikel wird an unser Helpdesk gesendet und kostenlos analysiert."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Sie werden informiert, sobald wir Ihren Artikel bearbeitet haben. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isCadButtonVisible && !_vm.article.migrated
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button",
                                                attrs: {
                                                  small: "",
                                                  block: "",
                                                  disabled:
                                                    _vm.isCadButtonDisabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openCadDialog()
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " CAD Modell anzeigen / bearbeiten "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(" Öffnet das CAD Modell im Browser."),
                                  _c("br"),
                                  _vm._v(
                                    " Im integrierten CAD Editor von Blexon können Sie Gewinde, Senkungen, Gravuren, Schweissnähte usw. einfügen / bearbeiten."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Ausserdem Maße kontrollieren, Meldungen anzeigen und Fehlerursachen beheben. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.article.migrated
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button",
                                                attrs: {
                                                  disabled:
                                                    _vm.article.calculating,
                                                  small: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.revalidate()
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" Artikel blexonisieren ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Der Artikel wurde noch nicht mit der neuesten Version blexonisiert."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Um den Artikel zu bestellen, im CAD zu überprüfen oder zu ändern, klicken Sie bitte hier. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "content-class": "specialSize", persistent: "" },
                    model: {
                      value: _vm.cadDialog,
                      callback: function ($$v) {
                        _vm.cadDialog = $$v
                      },
                      expression: "cadDialog",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "#f3f3f4",
                          padding: "0px",
                        },
                      },
                      [
                        _vm.cadDialog
                          ? _c("iframe", {
                              staticStyle: {
                                padding: "0px",
                                margin: "0px",
                                border: "0px",
                                width: "100%",
                              },
                              attrs: {
                                loading: "lazy",
                                height: _vm.currentWindowHeight,
                                src: _vm.articleCadViewUrl,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c("article-price-info-dialog", {
                  attrs: {
                    preisInformationenDialog: _vm.preisInformationenDialog,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.preisInformationenDialog = false
                    },
                  },
                }),
                _c("article-edit-dialog", {
                  attrs: {
                    article: _vm.article,
                    articleEditDialog: _vm.articleEditDialog,
                    isUpdatingArticleData: _vm.isUpdatingArticleData,
                  },
                  on: {
                    isUpdating: _vm.setUpdating,
                    closeDialog: function ($event) {
                      _vm.articleEditDialog = false
                    },
                  },
                }),
                _c("article-delete-warning-dialog", {
                  attrs: {
                    articleDeleteWarningDialog: _vm.articleDeleteWarningDialog,
                    articleCartNames: _vm.articleCartNames,
                    articleId: _vm.articleId,
                  },
                  on: {
                    setDeleteOverlay: _vm.setDeleteOverlay,
                    closeDialog: function ($event) {
                      _vm.articleDeleteWarningDialog = false
                    },
                  },
                }),
                _c("send-to-helpdesk-dialog", {
                  attrs: {
                    showHelpdeskDialogue: _vm.showHelpdeskDialogue,
                    articleId: _vm.articleId,
                    reason: _vm.reasonToSendArticleToHelpdesk,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.showHelpdeskDialogue = false
                    },
                  },
                }),
                _c("too-many-calculating-dialog", {
                  attrs: {
                    tooManyCalculatingDialog: _vm.tooManyCalculatingDialog,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.tooManyCalculatingDialog = false
                    },
                  },
                }),
                _c("article-treatment-edit-dialog", {
                  attrs: {
                    articleTreatmentEditDialog: _vm.articleTreatmentEditDialog,
                    articles: [_vm.article],
                    isUpdatingArticleData: _vm.isUpdatingArticleData,
                  },
                  on: {
                    isUpdating: _vm.setUpdating,
                    closeDialog: function ($event) {
                      _vm.articleTreatmentEditDialog = false
                    },
                  },
                }),
                _c("powder-coating-not-editable-dialog", {
                  attrs: {
                    powderCoatingNotEditableDialog:
                      _vm.powderCoatingNotEditableDialog,
                    hasEngravings: _vm.article.hasEngravings,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.powderCoatingNotEditableDialog = false
                    },
                    helpdeskSendenClicked: function ($event) {
                      _vm.powderCoatingNotEditableDialog = false
                      _vm.reasonToSendArticleToHelpdesk = 1
                      _vm.showHelpdeskDialogue = true
                    },
                  },
                }),
                _vm.certificateHelperDialog
                  ? _c("certificate-helper-dialog", {
                      attrs: {
                        certificateHelperDialog: _vm.certificateHelperDialog,
                      },
                      on: {
                        closeDialog: function ($event) {
                          _vm.certificateHelperDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }