import { PriceModel } from '@/dtos/priceModel';
import { AddressDto } from './addressDto';

export class OrderRequestDto{
    public customerId: number = 0;
    public deliveryAddress: AddressDto = new AddressDto();
    public invoiceAddress: AddressDto = new AddressDto();
    public calculationId: number = 0;
    public selectedManufacturerId: number = 0;
    public rampenabholung: boolean = false;
    public dueDate: Date = new Date();
    public kommission: string = '';
    public priceModel: PriceModel = PriceModel.Standard;
    public additionalOrderConfirmationEmail: string = '';
}