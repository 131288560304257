var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _c("v-col", { attrs: { cols: "9", sm: "7" } }, [
        _c("span", [
          _vm._v(
            "Offene Bestellung vom " +
              _vm._s(_vm._f("formatDate")(_vm.pendingOrder.requestedAt)) +
              " von " +
              _vm._s(_vm.pendingOrder.requestedByFirstname) +
              " " +
              _vm._s(_vm.pendingOrder.requestedByLastname) +
              " (" +
              _vm._s(_vm.pendingOrder.positions.length) +
              " Positionen) "
          ),
        ]),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "2", sm: "4" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "blexon-button",
              attrs: {
                block: "",
                title: "Fügt die Bestellung dem Warenkorb hinzu.",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addToCart()
                },
              },
            },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v("mdi-cart-arrow-down"),
              ]),
              _vm._v(" In Warenkorb "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "1", sm: "1" } },
        [
          _c(
            "v-btn",
            {
              attrs: { block: "", title: "Löscht die Bestellung." },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.deleteOrderDialog = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-delete")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.deleteOrderDialog,
            callback: function ($$v) {
              _vm.deleteOrderDialog = $$v
            },
            expression: "deleteOrderDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Sind Sie sicher?")]),
              _c("v-card-text", [
                _vm._v(
                  " Die Bestellung von " +
                    _vm._s(_vm.pendingOrder.requestedByFirstname) +
                    " " +
                    _vm._s(_vm.pendingOrder.requestedByLastname) +
                    " mit " +
                    _vm._s(_vm.pendingOrder.positions.length) +
                    " Positionen soll gelöscht werden? Dieser Vorgang kann nicht mehr rückgängig gemacht werden. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.deleteOrderDialog = false
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.deletePendingOrder()
                        },
                      },
                    },
                    [_vm._v(" Löschen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }