var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.isValid,
            callback: function ($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid",
          },
        },
        [
          _c(
            "v-card",
            [
              _vm.newAddress
                ? _c("v-card-title", [_vm._v(" Neue Adresse erstellen ")])
                : _c("v-card-title", [_vm._v(" Adresse ändern ")]),
              _c(
                "v-card-text",
                [
                  _vm.address.type == _vm.invoiceType
                    ? _c("v-text-field", {
                        attrs: {
                          label: "Vorname",
                          rules: _vm.addressOptionalStringMax35Rule,
                          color: "accent",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.address.givenName = _vm.address.givenName.trim()
                          },
                        },
                        model: {
                          value: _vm.address.givenName,
                          callback: function ($$v) {
                            _vm.$set(_vm.address, "givenName", $$v)
                          },
                          expression: "address.givenName",
                        },
                      })
                    : _c("v-text-field", {
                        attrs: {
                          label: "Vorname",
                          rules: _vm.addressRequiredStringMax35Rule,
                          color: "accent",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.address.givenName = _vm.address.givenName.trim()
                          },
                        },
                        model: {
                          value: _vm.address.givenName,
                          callback: function ($$v) {
                            _vm.$set(_vm.address, "givenName", $$v)
                          },
                          expression: "address.givenName",
                        },
                      }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Nachname",
                      rules: _vm.addressRequiredStringMax35Rule,
                      color: "accent",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.address.surname = _vm.address.surname.trim()
                      },
                    },
                    model: {
                      value: _vm.address.surname,
                      callback: function ($$v) {
                        _vm.$set(_vm.address, "surname", $$v)
                      },
                      expression: "address.surname",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Firma",
                      rules: _vm.addressOptionalStringMax35Rule,
                      color: "accent",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.address.companyName = _vm.address.companyName.trim()
                      },
                    },
                    model: {
                      value: _vm.address.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.address, "companyName", $$v)
                      },
                      expression: "address.companyName",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Strasse / Nr.",
                      rules: _vm.addressRequiredStringMax35Rule,
                      color: "accent",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.address.street = _vm.address.street.trim()
                      },
                    },
                    model: {
                      value: _vm.address.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.address, "street", $$v)
                      },
                      expression: "address.street",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "PLZ",
                              rules: _vm.correctPlz,
                              color: "accent",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.address.zip = _vm.address.zip.trim()
                              },
                            },
                            model: {
                              value: _vm.address.zip,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "zip", $$v)
                              },
                              expression: "address.zip",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Ort",
                              rules: _vm.addressRequiredStringMax35Rule,
                              color: "accent",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.address.town = _vm.address.town.trim()
                              },
                            },
                            model: {
                              value: _vm.address.town,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "town", $$v)
                              },
                              expression: "address.town",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      readonly: "",
                      color: "accent",
                      filled: "",
                      hint: "Das Land kann nicht geändert werden. Bitte kontaktieren Sie uns auf info@blexon.com",
                    },
                    model: {
                      value: _vm.address.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.address, "country", $$v)
                      },
                      expression: "address.country",
                    },
                  }),
                  _vm.address.type == _vm.deliveryType
                    ? _c("v-text-field", {
                        attrs: {
                          label: "Telefonnummer für Lieferungsavisierung",
                          rules: _vm.requiredStringMax35Rule,
                          color: "accent",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.address.phone = _vm.address.phone.trim()
                          },
                        },
                        model: {
                          value: _vm.address.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.address, "phone", $$v)
                          },
                          expression: "address.phone",
                        },
                      })
                    : _vm._e(),
                  _vm.address.type == _vm.invoiceType
                    ? _c(
                        "div",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "E-Mail Adresse für Rechnungsversand",
                              rules: _vm.emailRule,
                              color: "accent",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.address.eMail = _vm.address.eMail.trim()
                              },
                            },
                            model: {
                              value: _vm.address.eMail,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "eMail", $$v)
                              },
                              expression: "address.eMail",
                            },
                          }),
                          _vm.rechnungsversandPerPostMöglich
                            ? _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    "hide-details": "",
                                    disabled:
                                      _vm.addressType ==
                                        _vm.completedOrderInvoiceType ||
                                      _vm.addressType ==
                                        _vm.completedOrderDeliveryType,
                                  },
                                  model: {
                                    value: _vm.invoiceVersandart,
                                    callback: function ($$v) {
                                      _vm.invoiceVersandart = $$v
                                    },
                                    expression: "invoiceVersandart",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Rechnung per E-Mail",
                                      value: "email",
                                      color: "accent",
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Rechnung per Post",
                                      value: "post",
                                      color: "accent",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.addressType == _vm.completedOrderInvoiceType ||
                          _vm.addressType == _vm.completedOrderDeliveryType
                            ? _c("span", { attrs: { small: "" } }, [
                                _vm._v(
                                  " Die Versandart kann für diese Bestellung nicht mehr verändert werden. "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.isSaving },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog(false)
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: {
                        text: "",
                        disabled: !_vm.isValid || _vm.isSaving,
                        title: "Speichert die Änderungen.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveAddress()
                        },
                      },
                    },
                    [
                      _vm.isSaving
                        ? _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _vm._v(" Speichern "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }