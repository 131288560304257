
export enum PriceModel {
    Standard = 0,
    Slow = 1,
    Express = 2
}

export default {
    allPriceModels: [
		{
			value: PriceModel.Standard,
			name: 'Schnell',
		},
		{
			value: PriceModel.Slow,
			name: 'Preiswert',
		},
		{
			value: PriceModel.Express,
			name: 'Express',
		}
    ]
}