var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        "offset-y": "",
        "max-height": "500",
        "nudge-top": "5",
        "open-on-hover": "",
        "allow-overflow": false,
        "close-on-click": false,
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-tab",
                _vm._g(
                  _vm._b({ attrs: { to: "/Checkout" } }, "v-tab", attrs, false),
                  on
                ),
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        inline: "",
                        color: "accent",
                        content:
                          _vm.cartItems.length > 0 ? _vm.cartItems.length : "0",
                      },
                    },
                    [_vm._v(" Warenkorb ")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      !_vm.hasValidOffer && _vm.cartItems.length > 0
        ? _c(
            "v-container",
            { staticClass: "whiteBackground pa-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-0 ma-0",
                  staticStyle: { "max-height": "450px", "overflow-y": "auto" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-0" },
                        _vm._l(_vm.cartItems, function (item, index) {
                          return _c(
                            "v-list-item",
                            { key: index },
                            [
                              _c("cart-tab-menu-item", {
                                attrs: { cartItem: item },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                { staticClass: "ma-0 pa-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "py-2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            title: "Entfernt alle Artikel aus dem Warenkorb.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.emptyCart()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-close"),
                          ]),
                          _vm._v("Leeren "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }