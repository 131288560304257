
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CartItem } from '@/dtos/cartItem';
import CartTabMenuItem from '@/views/Cart/CartTabMenuItem.vue'

@Component({
    components: {
        CartTabMenuItem,
    }
})
export default class CartTabMenu extends Vue {
    private get cartItems() {
        if (this.$store.state.currentCart === null) {
            return new Array<CartItem>();
        }
        return this.$store.state.currentCart.cartItems;
    }

    private get hasValidOffer() {
        const result = this.$store.state.offer !== null && 
            !this.$store.state.currentCart.hasChanged && 
            !this.$store.state.offerInvalidForCartUpdate &&
            !this.$store.state.currentOfferCalculating;
        return result;
    }

    private emptyCart() {
        this.$store.dispatch('emptyCart');
    }

}
