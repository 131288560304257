
import { Component, Prop, Vue } from 'vue-property-decorator';
import httpClient from "@/store/httpClient";
import rules from '@/store/rules';
import { ArticleState } from '@/dtos/articleState';
import { PriceState } from '@/dtos/priceState';
import { CartItem } from '@/dtos/cartItem';

@Component({})
export default class CartPosition extends Vue {
	@Prop()
	private position!: CartItem;
	private allowedCartAmountRule = rules.allowedCartAmountRule;

    private get article() {
        return this.$store.getters.articleById(this.position.articleId);
    }

    private get articleQuantity() {        
        return this.position.quantity;
    }
    private set articleQuantity(quantity: any) {
        const parsed: number = parseInt(quantity);
        if (quantity !== undefined && !isNaN(parsed) && parsed > 0) {
            this.$store.dispatch('setAmountOfCartItem', {
                articleId: this.position.articleId,
                quantity: parsed
            });
        }
    }

	private get selectedSavedCartId(): number {
		return this.$store.state.currentSavedCartId;
	}

	private async recalculatePrice(){
        try{
            this.$store.commit('updatePriceStateOfArticle', { articleId: this.position.articleId, priceState: PriceState.Calculating });
            await httpClient().get(`article/UpdatePriceOfArticle?articleId=${this.position.articleId}`);
        } catch(err) {
            console.error(`Failed to update prices of article ${this.position.articleId}. Error: ${err}`);
            this.$store.commit('updatePriceStateOfArticle', { articleId: this.position.articleId, priceState: PriceState.Failed });
        }
    }

    private removeFromCart() {
        this.$store.dispatch('removeArticleFromCart', this.position.articleId);
    }

	private addToSavedCart() {
		this.$store.dispatch("addArticleToSavedCart", {
			amount: this.articleQuantity,
			articleId: this.position.articleId,
            customerArticleName: this.article.customerArticleName,
		});
	}

    private get thumbnailUrl() {
        return this.$store.getters.getThumbnailUrl(this.position.articleId, undefined);
    }

    private articleStateIcon() {
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'mdi-check-circle';
            } 
            case ArticleState.Warning: { 
                return 'mdi-alert-circle';
            } 
            default: { 
                return 'mdi-close-circle';
            } 
        }     
    }

    private articleStateColor() {
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return '#97b498';
            } 
            case ArticleState.Warning: { 
                return '#cbc693';
            } 
            default: { 
                return '#cb9ca1';
            } 
        }     
    }

}

