var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h2", { staticClass: "text-h5" }, [_vm._v("Warenkorb")]),
      _vm.hasItemsInCart
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        title: "Entfernt alle Artikel aus dem Warenkorb.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.emptyCart()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                      _vm._v("Leeren "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: {
                        title: "Aus dem Warenkorb eine Stückliste erstellen.",
                      },
                      on: {
                        click: function ($event) {
                          _vm.saveAsNewCartDialog = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v("Stückliste erstellen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hasItemsInCart
        ? _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c("v-simple-table", {
                staticStyle: { width: "100%" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Status")]),
                              _c("th", [_vm._v("Bild")]),
                              _c("th", [_vm._v("Artikel")]),
                              _c("th", [_vm._v("Stk.")]),
                              _c("th"),
                            ]),
                          ]),
                          _vm.cartPositions.length > 0
                            ? _c(
                                "tbody",
                                _vm._l(_vm.cartPositions, function (position) {
                                  return _c("cart-position", {
                                    key: position.articleId,
                                    attrs: { position: position },
                                  })
                                }),
                                1
                              )
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-h5",
                                      attrs: { colspan: "6" },
                                    },
                                    [_vm._v("Keine Artikel im Warenkorb")]
                                  ),
                                ]),
                              ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3911641854
                ),
              }),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c("v-col", [
                _vm._v(" Der Warenkorb ist leer. Gehen Sie zu "),
                _c("a", { attrs: { href: "/#/Catalogue" } }, [
                  _vm._v("'Artikel'"),
                ]),
                _vm._v(" um neue Artikel in den Warenkorb zu legen. "),
              ]),
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              on: {
                keydown: _vm.checkEscOrEnter,
                "click:outside": function ($event) {
                  _vm.saveAsNewCartDialog = false
                },
              },
              model: {
                value: _vm.saveAsNewCartDialog,
                callback: function ($$v) {
                  _vm.saveAsNewCartDialog = $$v
                },
                expression: "saveAsNewCartDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Neue Stückliste")]),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " Der Warenkorb wird als Stückliste unter folgendem Namen gespeichert: "
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "Name der neuen Stückliste",
                          color: "accent",
                          rules: [_vm.checkNewCartName],
                        },
                        on: {
                          blur: function ($event) {
                            _vm.newCartName = _vm.newCartName.trim()
                          },
                        },
                        model: {
                          value: _vm.newCartName,
                          callback: function ($$v) {
                            _vm.newCartName = $$v
                          },
                          expression: "newCartName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.saveAsNewCartDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            text: "",
                            disabled: !_vm.saveCartValid,
                            title: "Speichert den Warenkorb als Stückliste.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveNewCart()
                            },
                          },
                        },
                        [_vm._v(" Speichern ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }