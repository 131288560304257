
import { Component, Vue, } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import { CustomerOrderDto } from '@/dtos/customerOrderDto';
import { OrderTrackingState } from '@/dtos/orderTrackingState';
import { TicketStateDto } from '@/dtos/ticket/ticketStateDto';
import TicketComponent from '@/views/OrderHistory/TicketComponent.vue';
import OrderDetail from '@/views/OrderHistory/OrderDetail.vue';
import rules from '@/store/rules'
import { AddressDto } from '@/dtos/addressDto';
import { AddressType } from '@/dtos/addressType';
import ChangeAddress from '@/views/Settings/ChangeAddress.vue'

@Component({
    components: {
        TicketComponent,
        OrderDetail,
        ChangeAddress,
    }
})
export default class Orders extends Vue {
    private ticketStates: TicketStateDto[] = new Array<TicketStateDto>();
    private loadBatchSize: number = 10;
    private hasMoreToLoad: boolean = false;
    private isLoadingPdf: boolean = false;
    private isLoadingOrderPdf: boolean = false;
    private isLoadingCancellation: boolean = false;
    private loadingTicketStates: boolean = true;
    private optionalStringMax35Rule = rules.optionalStringMax35Rule
    private showOrderDetail: boolean = true;
    private orderId: number = 0;
    private orderState: OrderTrackingState = OrderTrackingState.Unknown;
    private showCancellationConfirmationDialog: boolean = false;
    private orderIdToCancel: number = 0;
    private currentEditAddress: AddressDto = new AddressDto();
    private currentEditAddressType: AddressType = AddressType.CompletedOrderDelivery;
    private currentEditAddressOrderId: number = 0;
    private editAddressDialog: boolean = false;

    beforeRouteEnter(to: any, from: any, next: any) {
        next((vm: any) => {
            window.addEventListener('scroll', vm.checkOrdersBottom);
        });
    }

    beforeRouteLeave(to: any, from: any, next: any) {
        window.removeEventListener('scroll', this.checkOrdersBottom);
        next();
    }

    private mounted() {
        this.loadOrders(true);
    }

    private get orders() {
        return (this.$store.state.orders as CustomerOrderDto[]);
    }
    
    private get orderCancellationRequests() {
        return this.$store.state.orderCancellations;
    }

    private get isLoading() {
        return (this.$store.state.loading as boolean);
    }

    private async loadOrders(reload: boolean) {
        try {
            this.$store.commit('setLoading', true);
            var from = this.orders.length;
            if (reload) {
                from = 0;
            }
            const response = await httpClient().get(`order/getOrdersOfCustomer?` +
                `from=${from}` +
                `&take=${this.loadBatchSize}`);
            if (!reload && from > 0) {
                this.$store.commit('setOrders', {
                    reload: false,
                    orders: response.data
                });
            } else {
                this.$store.commit('setOrders', {
                    reload: true,
                    orders: response.data
                });
            }
            if (response.data.length === this.loadBatchSize) {
                // wenn alle geladen wurden, gibt es wahrscheinlich noch mehr
                this.hasMoreToLoad = true;
            } else {
                this.hasMoreToLoad = false;
            }
        } catch(err) {
            console.log(`Fehler beim Laden der Bestellungen: ${err}`);
        } finally {
            this.$store.commit('setLoading', false);
        }
        // Load cancellations after order have been loaded
        this.loadOrderCancellationRequests();
        // Load Ticket States after orders have been loaded
        this.getTicketStates();
    }

    private async loadOrderCancellationRequests() {
        try {
            const response = await httpClient().post('order/GetOrderCancellationRequestsForOrders', 
                this.orders.map((o: CustomerOrderDto) => o.orderId));
            this.$store.commit('setOrderCancellations', response.data);
        } catch (err) {
            console.log(`Fehler beim Laden der Stornierungsanfragen: ${err}`);
        }
    }

    private async getTicketStates () {
        try {
            this.loadingTicketStates = true;
            const response = await httpClient().get('Order/GetTicketStates' );
            if (response.data !== '') {
                if (response.data.length === 0) {
                    this.ticketStates = new Array<TicketStateDto>();
                } else {
                    this.ticketStates = response.data;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingTicketStates = false;
        }
    }

    private getTicketStateFromOrderId (order: CustomerOrderDto) {
        var returnStates = this.ticketStates.filter(state => {
            return state.orderId === order.orderId.toString();
        })
        if (returnStates.length > 0) {
            return returnStates[0]
        } else {
            return undefined
        }
    }

    private hasCancellationRequest(orderId: number) {
        return this.orderCancellationRequests !== null && 
            this.orderCancellationRequests.some((o: any) => o.blexonOrderId == orderId);
    }

    private hasWaitingCancellationRequest(orderId: number) {
        if (this.hasCancellationRequest(orderId)) {
            return this.orderCancellationRequests.filter((o: any) => o.blexonOrderId == orderId)[0].state == 0;
        } else {
            return false;
        }
    }

    private hasOpenCancellationRequest(orderId: number) {
        if (this.hasCancellationRequest(orderId)) {
            return this.orderCancellationRequests.filter((o: any) => o.blexonOrderId == orderId)[0].state == 1;
        } else {
            return false;
        }
    }

    private hasClosedCancellationRequest(orderId: number) {
        if (this.hasCancellationRequest(orderId)) {
            let state = this.orderCancellationRequests.filter((o: any) => o.blexonOrderId == orderId)[0].state;
            return state == 2 || state == 3;
        } else {
            return false;
        }
    }

    private hasBusyCancellationRequest(orderId: number) {
        if (this.hasCancellationRequest(orderId)) {
            let state = this.orderCancellationRequests.filter((o: any) => o.blexonOrderId == orderId)[0].state;
            return state == 4;
        } else {
            return false;
        }
    }

    private canChangeKommissionsnummer (order: CustomerOrderDto) {
        return (order.state == OrderTrackingState.Ordered ||
            order.state == OrderTrackingState.InProduction ||
            order.state == OrderTrackingState.Produced);
    }

    private canChangeDeliveryAddress(order: CustomerOrderDto) {
        return (order.state == OrderTrackingState.Ordered || order.state == OrderTrackingState.InProduction)
            && ["Schweizer Post", "DHL"].some((t:string) => t === order.translatedZustellungArt);
    }
    private canChangeInvoiceAddress(order: CustomerOrderDto) {
        return order.state == OrderTrackingState.Ordered 
            || order.state == OrderTrackingState.InProduction
            || order.state == OrderTrackingState.Delivered
            || order.state == OrderTrackingState.Produced;
    }

    private async updateKommissionsnummer (order: CustomerOrderDto) {
        if (order.kommission === null || order.kommission === '') {
            return;
        }
        try {
            await httpClient().post('order/updateKommissionsnummer?' +
                `newKommissionsnummer=${encodeURI(order.kommission)}` +
                `&customerOrderId=${order.orderId}`)
            this.$store.dispatch('setSnackbarText', 'Auftragsnummer wurde gespeichert.');
        } catch (err) {
            console.log(`Fehler beim Aktualisieren der Auftragsnummer: ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Speichern der Auftragsnummer.');
        }
    }

    private async getInvoicePdf (orderId: number) {
        try {
            this.isLoadingPdf = true
            const response = await httpClient().get(`order/getInvoicePdf?customerOrderId=${orderId}`,
                {
                    responseType: 'blob'
                })
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.download = 'Rechnung zum Auftrag ' + orderId + '.pdf'
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
        } catch (err) {
            console.log(`Get invoice failed. ${err}`)
        } finally {
            this.isLoadingPdf = false
        }
    }

    private async getOrderConfirmationPdf (orderId: number) {
        try {
            this.isLoadingOrderPdf = true
            const response = await httpClient().get(`order/getOrderConfirmationPdfFile?orderId=${orderId}`,
                {
                    responseType: 'blob'
                })
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.download = 'Auftragsbestätigung ' + orderId + '.pdf'
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
        } catch (err) {
            console.error(`Get order confirmation failed. ${err}`)
        } finally {
            this.isLoadingOrderPdf = false
        }
    }
    
    private navigateToTicket (orderId: number) {
        this.$router.push({ 
            name: 'CreateOrEditTicket', 
            params: { orderId: orderId.toString() } 
        });
    }

    private checkOrdersBottom() {
        let bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2
        if (bottomOfWindow && this.hasMoreToLoad && !this.isLoading) {
            this.loadOrders(false);
        }
    }

    private manuallyLoadMoreArticles() {
        if (this.hasMoreToLoad && !this.isLoading) {
            this.loadOrders(false);
        }
    }

    private async cancellationButton(orderId: number) {
        if (this.hasOpenCancellationRequest(orderId)) {
            this.$router.push({ 
                name: 'CancellationConfirmation', 
                params: { orderId: orderId.toString() } 
            });
        } else {
            await this.checkCancellation(orderId);
        }
    }

    private async checkCancellation(orderId: number) {
        try {
            this.isLoadingCancellation = true;
            this.orderIdToCancel = orderId;
            const response = await httpClient().get(`Order/IsOrderTransmitted?orderId=${orderId}`);
            if (response.data) {
        		this.$router.push({ 
                    name: 'RequestCancellation', 
                    params: { 
                        orderId: orderId.toString(), 
                        orderProcessedInMeantime: 'false'
                    } 
                });
            } else {
                this.showCancellationConfirmationDialog = true;
            }
        } catch(err) {
            console.error(`Failed to check cancellation state. ${err}`)
        } finally {
            this.isLoadingCancellation = false;
        }
    }

    private async cancelOrder() {
        try {
            this.isLoadingCancellation = true;
            const response = await httpClient().post(`Order/CancelOrder?orderId=${this.orderIdToCancel}`);
            // FALSE if the order has started processing in the meantime
            if(response.data) {
                this.$store.dispatch('setSnackbarText', 'Der Auftrag wurde erfolgreich storniert.');
                this.loadOrders(true); // Reload orders
            } else {
                this.$store.dispatch('setSnackbarErrorText', 'Der Auftrag wurde inzwischen verarbeitet.');
                this.$router.push({ 
                    name: 'RequestCancellation', 
                    params: { 
                        orderId: this.orderIdToCancel.toString(), 
                        orderProcessedInMeantime: 'true'
                    } 
                });
            }
        } catch(err) {
            this.$store.dispatch('setSnackbarErrorText', 'Auftrag konnte nicht storniert werden.');
        } finally {
            this.isLoadingCancellation = false;
            this.showCancellationConfirmationDialog = false;
        }
    }

    private async editAddress(type: string, order: CustomerOrderDto){
        try {
            let call = '';
            if (type === 'invoice') {
                call = `Order/GetOrderInvoiceAddress?orderId=${order.orderId}`
                this.currentEditAddressType = AddressType.CompletedOrderInvoice;
            } else if (type === 'delivery') {
                call = `Order/GetOrderDeliveryAddress?orderId=${order.orderId}`
                this.currentEditAddressType = AddressType.CompletedOrderDelivery;
            } else {
                throw 'Invalid address type';
            }
            const response = await httpClient().get(call);
            let responseAddress: AddressDto = JSON.parse(JSON.stringify(response.data));
            if (type === 'invoice' && responseAddress.eMail) {
                responseAddress.isBilledDigitally = responseAddress.eMail.length > 0
            }
            this.currentEditAddress = responseAddress;
            this.currentEditAddressOrderId = order.orderId;
            this.editAddressDialog = true;
        } catch(err) {
            console.log(`Adresse konnte nicht geladen werden. ${err}`);
        }
    }

    private getIcon(state: OrderTrackingState){
        switch(state) {
            case OrderTrackingState.Unknown: {
                return 'mdi-close'
            }
            case OrderTrackingState.Ordered: {
                return 'mdi-clock'
            }
            case OrderTrackingState.Delivered: {
                return 'mdi-truck'
            }
            case OrderTrackingState.Paid: {
                return 'mdi-check'
            }
            case OrderTrackingState.InProduction: {
                return 'mdi-cogs'
            }
            case OrderTrackingState.Produced: {
                return 'mdi-dropbox'
            }
            case OrderTrackingState.Fakturiert: {
                return 'mdi-cash'
            }
            case OrderTrackingState.Cancelled: {
                return 'mdi-cancel'
            }
            case OrderTrackingState.CreditOverdrawn: {
                return 'mdi-exclamation'
            }
            default: {
                return 'mdi-help-circle'
            }
        }
    }
}
