import { ComplaintExpectationType } from './complaintExpectationType';

export interface IComplaintExpectationType {
    text: string;
    value: ComplaintExpectationType;
}

const values: IComplaintExpectationType[] = [
    {
        text: 'Ersatzlieferung',
        value: ComplaintExpectationType.Ersatzlieferung,
    },
    {
        text: 'Andere Erwartung...',
        value: ComplaintExpectationType.Other,
    },
];

export default values;

