var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading && !_vm.cancellation.blexonOrderId
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "accent" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isLoading && !_vm.cancellation.blexonOrderId
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " Der Stornierungsantrag konnte nicht gefunden werden. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isLoading && _vm.cancellation.state != 1
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _vm._v(
                        " Dieser Stornierungsantrag wurde bereits bearbeitet. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-container",
            [
              _c("h2", [
                _vm._v(
                  "Stornierung für Auftrag #" +
                    _vm._s(_vm.cancellation.blexonOrderId) +
                    " bestätigen"
                ),
              ]),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " Sie haben beantragt, gewisse Positionen dieser Bestellung zu stornieren."
                      ),
                      _c("br"),
                      _vm._v(
                        " Die Kosten für die Stornierung sind unten pro Position aufgelistet. Sie können die Positionen auswählen, die sie stornieren möchten. Die Kosten werden entsprechend angepasst. Nicht stornierte Positionen werden normal weiterverarbeitet. Die Stornierungspauschale für die getätigten Abklärungen im Zusammenhang mit der Stornierung ist in jedem Fall fällig und ist in den Kosten enthalten. "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-simple-table", {
                staticStyle: { width: "100%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "accent",
                                    "hide-details": "",
                                  },
                                  on: { change: _vm.toggleAllPositions },
                                  model: {
                                    value: _vm.toggleAll,
                                    callback: function ($$v) {
                                      _vm.toggleAll = $$v
                                    },
                                    expression: "toggleAll",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("th", [_vm._v("Artikel Id")]),
                            _c("th", [_vm._v("Position")]),
                            _c("th", [_vm._v("Vorschau")]),
                            _c("th", [_vm._v("Beschreibung")]),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v("Stornierungskosten"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.cancellation.positions, function (pos) {
                              return _c("tr", { key: pos.ArticleId }, [
                                _c(
                                  "td",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        color: "accent",
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: pos.selectedForCancellation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            pos,
                                            "selectedForCancellation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "pos.selectedForCancellation",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(" " + _vm._s(pos.articleId) + " "),
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(pos.position) + " "),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "pa-1" },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        contain: "",
                                        width: "90px",
                                        height: "90px",
                                        src: _vm.getThumbnailUrl(pos.articleId),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getArticleName(pos.position)) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getShownCancellationCost(
                                            pos.selectedForCancellation,
                                            pos.cancellationCost
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            _c("tr", [
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td", [_vm._v("Stornierungspauschale")]),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("round2Decimal")(
                                          _vm.cancellation.fixCosts
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td"),
                              _c("td", [_c("b", [_vm._v("Total")])]),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.orderData.currency) +
                                        " " +
                                        _vm._s(
                                          _vm._f("round2Decimal")(
                                            _vm.totalCostOfCancellation
                                          )
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "v-row",
                { staticClass: "pa-0 mx-0 my-3" },
                [
                  _c("v-btn", { on: { click: _vm.goToOrders } }, [
                    _vm._v(" Zurück "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      on: {
                        click: function ($event) {
                          return _vm.confirmCancellation(false)
                        },
                      },
                    },
                    [
                      _vm.isLoading
                        ? _c("v-progress-circular", {
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _c("span", [_vm._v("Nicht stornieren")]),
                    ],
                    1
                  ),
                  _vm.selectedSomePositions
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 blexon-button",
                          on: {
                            click: function ($event) {
                              return _vm.confirmCancellation(true)
                            },
                          },
                        },
                        [
                          _vm.isLoading
                            ? _c("v-progress-circular", {
                                attrs: { size: "15", indeterminate: "" },
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v("Ausgewählte Positionen stornieren"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }