var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.certificateHelperDialog,
        callback: function ($$v) {
          _vm.certificateHelperDialog = $$v
        },
        expression: "certificateHelperDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Zertifikat / Zeugnisse")]),
          _c("v-card-text", [
            _vm._v(
              " Nach der Auslieferung der Teile können Sie ein Abnahmeprüfzeugnis 3.1 nach EN 10204:2004 in Ihrem Bestellarchiv herunterladen."
            ),
            _c("br"),
            _vm._v(
              " Das Zeugnis stammt vom Hersteller des Rohmaterials und beinhaltet keine Umstempelungsbescheinigung durch den Blechverarbeiter. "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Schliessen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }