import { MwstDto } from './mwstDto';
import { UserInfoDto } from './userInfoDto';
import { AddressDto } from '@/dtos/addressDto'

export class CustomerInfoDto {
    public customerId: number = 0;
    public companyName: string = '';
    public street: string = '';
    public plz: string = '';
    public town: string = '';
    public country: string = '';
    public generalEmail: string = '';
    public generalPhone: string = '';
    public currency: string = '';
    public mwstRates: MwstDto[] = new Array<MwstDto>();
    public deliveryAddresses: AddressDto[] = new Array<AddressDto>();
    public invoiceAddresses: AddressDto[] = new Array<AddressDto>();
    public regionId: number = 0;
    public regionName: string = '';
    public isCountryValidForDelivery: boolean = false;
    public defaultAdditionalOrderConfirmationEmail: string = '';
    public customerMwstNumber: string = '';
    public user: UserInfoDto = new UserInfoDto();
}