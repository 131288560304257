var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "importArticleTile", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _c("strong", [_vm._v("Blexon Nr:")]),
                    _vm._v(" " + _vm._s(_vm.article.articleId)),
                  ]),
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.articleDataValid,
                        callback: function ($$v) {
                          _vm.articleDataValid = $$v
                        },
                        expression: "articleDataValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelname",
                          rules: _vm.requiredStringMax50Rule,
                          disabled: !_vm.articleInitialized,
                          color: "accent",
                        },
                        on: {
                          blur: () => {
                            _vm.articleName = _vm.articleName.trim()
                            _vm.saveArticleData()
                          },
                        },
                        model: {
                          value: _vm.articleName,
                          callback: function ($$v) {
                            _vm.articleName = $$v
                          },
                          expression: "articleName",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelnummer (optional)",
                          placeholder: "Ihre Artikelnummer",
                          disabled: !_vm.articleInitialized,
                          rules: _vm.optionalStringMax50Rule,
                          color: "accent",
                        },
                        on: {
                          blur: () => {
                            _vm.articleNumber = _vm.articleNumber.trim()
                            _vm.saveArticleData()
                          },
                        },
                        model: {
                          value: _vm.articleNumber,
                          callback: function ($$v) {
                            _vm.articleNumber = $$v
                          },
                          expression: "articleNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6", "align-self": "center" } },
                [
                  !_vm.articleInitialized
                    ? _c("div", [
                        _c("div", { staticClass: "text-center text-caption" }, [
                          _vm._v(" Wird hochgeladen... "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("v-progress-linear", {
                              attrs: { color: "accent" },
                              model: {
                                value: _vm.article.uploadProgress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.article, "uploadProgress", $$v)
                                },
                                expression: "article.uploadProgress",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.article.calculating
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                color: "accent",
                                size: "32",
                                indeterminate: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text-center text-caption" }, [
                          _vm._v(
                            " " + _vm._s(_vm.article.calculatingMessage) + " "
                          ),
                        ]),
                      ])
                    : _c("v-img", {
                        attrs: {
                          contain: "",
                          "max-height": "130",
                          src: _vm.getThumbnailUrl(_vm.article.articleId),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                !_vm.article.calculating
                  ? _c(
                      "div",
                      [
                        _vm.article.quantityInAssembly > 0
                          ? _c("span", { staticStyle: { display: "block" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.article.quantityInAssembly) +
                                  " Stück "
                              ),
                            ])
                          : _vm._e(),
                        _vm.article.thickness > 0
                          ? _c("span", [
                              _vm._v(
                                " Dicke: " +
                                  _vm._s(
                                    Math.round(_vm.article.thickness * 100) /
                                      100
                                  ) +
                                  "mm "
                              ),
                            ])
                          : _vm._e(),
                        _vm.article.thickness > 0 &&
                        _vm.availableWerkstoffe.length <= 0
                          ? _c("span", [
                              _c("br"),
                              _c("small", [
                                _vm._v(
                                  "Wir haben kein Material mit der erkannten Dicke gefunden. Bitte wählen Sie den gewünschten Werkstoff aus der Liste und blexonisieren sie den Artikel. Sollte dabei ein Fehler auftreten, können Sie den Artikel an unser Helpdesk zur Überprüfung senden."
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("v-select", {
                          attrs: {
                            dense: "",
                            color: "accent",
                            disabled: _vm.article.calculating,
                            placeholder: "Bitte Werkstoff wählen...",
                            "item-text": "bezeichnung",
                            "item-value": "blexonId",
                            items: _vm.selectableWerkstoffe,
                          },
                          model: {
                            value: _vm.article.werkstoffId,
                            callback: function ($$v) {
                              _vm.$set(_vm.article, "werkstoffId", $$v)
                            },
                            expression: "article.werkstoffId",
                          },
                        }),
                        _vm.article.thickness <= 0 &&
                        _vm.article.werkstoffId > 0
                          ? _c("v-select", {
                              attrs: {
                                dense: "",
                                color: "accent",
                                placeholder: "Bitte Dicke wählen...",
                                items: _vm.availableThicknesses,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(data.item) + " mm "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "selection",
                                    fn: function (data) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(data.item) + " mm "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2031382940
                              ),
                              model: {
                                value: _vm.selectedThickness,
                                callback: function ($$v) {
                                  _vm.selectedThickness = $$v
                                },
                                expression: "selectedThickness",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-col", { attrs: { cols: "6", "align-self": "end" } }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.deleting,
                          disabled: _vm.deleting,
                          title:
                            "Bricht den Import-Prozess ab und löscht den Artikel aus der Liste.",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteImportingArticle()
                          },
                        },
                      },
                      [
                        !_vm.article.calculating
                          ? _c("v-icon", [_vm._v("mdi-delete")])
                          : _c("span", [_vm._v("Abbrechen")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-right mt-2" },
                  [
                    !_vm.deleting && !_vm.isSubArticle
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "blexon-button",
                            attrs: {
                              disabled:
                                _vm.article.werkstoffId <= 0 ||
                                (_vm.article.thickness <= 0 &&
                                  _vm.selectedThickness <= 0) ||
                                _vm.article.calculating ||
                                !_vm.articleDataValid,
                              title:
                                "Analysiert den Artikel und erkennt Bearbeitungen automatisch. Preisberechnung wird gestartet.",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.assignWerkstoffAndStartDesign()
                              },
                            },
                          },
                          [_vm._v(" Blexonisieren ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }