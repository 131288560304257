
import { Component, Prop, Vue, } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';

@Component
export default class ArticleDeleteWarningDialog extends Vue {
	@Prop()
	private articleDeleteWarningDialog!: boolean;
	@Prop()
	private articleId!: number;
	@Prop()
	private articleCartNames!: string[];

	private async deleteArticle() {
        this.$emit('setDeleteOverlay', true);
        try {
            const response = await httpClient().post(`article/deleteArticle?articleId=${this.articleId}&force=true`);
            Vue.nextTick(() => {                        
                this.$emit('setDeleteOverlay', false);
				let data = {
					articleId: this.articleId,
					refreshCart: true,
				}
                this.$store.dispatch('deleteArticle', data);
                console.info(`Artikel ${this.articleId} wurde gelöscht`);
            });
        } catch(err) {
            console.error(`Fehler beim Löschen des Artikels. ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Artikel konnte nicht gelöscht werden.');
        } finally {
			this.closeDialog();
		}
    }

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}
}

