var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _vm.isLoading && _vm.orders.length == 0
            ? _c("div", { staticClass: "d-flex justify-center mt-5" }, [
                _c("h2", { staticClass: "font-weight-light" }, [
                  _vm._v("Bestellungen werden geladen..."),
                ]),
              ])
            : _vm.orders.length == 0
            ? _c("div", { staticClass: "d-flex justify-center mt-5" }, [
                _c("h2", { staticClass: "font-weight-light" }, [
                  _vm._v("Noch keine Bestellungen vorhanden"),
                ]),
              ])
            : _c(
                "v-timeline",
                {
                  attrs: {
                    "align-start": "",
                    dense: _vm.$vuetify.breakpoint.smAndDown,
                  },
                },
                _vm._l(_vm.orders, function (order) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: order.orderId,
                      attrs: {
                        icon: _vm.getIcon(order.state),
                        color: "accent",
                        "fill-dot": "",
                      },
                    },
                    [
                      order.state === 1
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [
                              _vm._v(" Bestellt"),
                              _c("br"),
                              _c("small", [
                                _vm._v(
                                  "Lieferdatum (ab Werk): " +
                                    _vm._s(_vm._f("formatDate")(order.dueDate))
                                ),
                              ]),
                            ]
                          )
                        : order.state === 2
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [_vm._v("Ausgeliefert")]
                          )
                        : order.state === 3
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [_vm._v("Abgeschlossen")]
                          )
                        : order.state === 4
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [
                              _vm._v(" In Produktion"),
                              _c("br"),
                              _c("small", [
                                _vm._v(
                                  "Lieferdatum (ab Werk): " +
                                    _vm._s(_vm._f("formatDate")(order.dueDate))
                                ),
                              ]),
                            ]
                          )
                        : order.state === 5
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [
                              _vm._v(" In Spedition"),
                              _c("br"),
                              _c("small", [
                                _vm._v(
                                  "Lieferdatum (ab Werk): " +
                                    _vm._s(_vm._f("formatDate")(order.dueDate))
                                ),
                              ]),
                            ]
                          )
                        : order.state === 6
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [_vm._v("Rechnung versendet")]
                          )
                        : order.state === 7
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [_vm._v("Storniert")]
                          )
                        : order.state === 8
                        ? _c(
                            "span",
                            { attrs: { slot: "opposite" }, slot: "opposite" },
                            [_vm._v("Kreditlimit überschritten")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        { attrs: { color: "accent" } },
                        [
                          _c("v-card-title", { staticClass: "title pa-2" }, [
                            _vm._v(_vm._s(order.translatedOrderSummary)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "white pa-2" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "py-0" },
                                    [
                                      _vm.canChangeKommissionsnummer(order)
                                        ? _c("v-text-field", {
                                            attrs: {
                                              disabled:
                                                _vm.hasBusyCancellationRequest(
                                                  order.orderId
                                                ),
                                              placeholder:
                                                "Hier können Sie Ihre Auftragsnummer eintragen",
                                              label:
                                                order.kommission != null &&
                                                order.kommission != ""
                                                  ? "Auftragsnummer"
                                                  : "",
                                              rules:
                                                _vm.optionalStringMax35Rule,
                                              color: "accent",
                                            },
                                            on: {
                                              blur: () => {
                                                order.kommission =
                                                  order.kommission.trim()
                                                _vm.updateKommissionsnummer(
                                                  order
                                                )
                                              },
                                            },
                                            model: {
                                              value: order.kommission,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  order,
                                                  "kommission",
                                                  $$v
                                                )
                                              },
                                              expression: "order.kommission",
                                            },
                                          })
                                        : order.kommission != null &&
                                          order.kommission != ""
                                        ? _c("small", [
                                            _vm._v(
                                              " Ihre Auftragsnummer: " +
                                                _vm._s(order.kommission) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "py-0" }, [
                                    _c("small", [
                                      _vm._v(
                                        " Blexonauftrag: " +
                                          _vm._s(order.orderId) +
                                          " "
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("small", [
                                      _vm._v(
                                        " Transporteur: " +
                                          _vm._s(
                                            order.translatedZustellungArt
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("br"),
                                    order.trackingcode !== null &&
                                    order.trackingcode !== undefined &&
                                    order.trackingcode !== ""
                                      ? _c("small", [
                                          _vm._v(" Sendungsverfolgung: "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                target: "_blank",
                                                href: order.translatedTrackingLink,
                                              },
                                            },
                                            [_vm._v(_vm._s(order.trackingcode))]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                              order.state !== 7
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "py-0" },
                                        [
                                          _vm.isLoadingOrderPdf
                                            ? _c("v-progress-circular", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  indeterminate: "",
                                                  size: "15",
                                                },
                                              })
                                            : _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "accent",
                                                        disabled:
                                                          _vm.hasBusyCancellationRequest(
                                                            order.orderId
                                                          ),
                                                        title:
                                                          "Auftragsbestätigung als Pdf Dokument runterladen",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getOrderConfirmationPdf(
                                                            order.orderId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" mdi-printer ")]
                                                  ),
                                                  _c("small", [
                                                    _vm._v(
                                                      "Auftragsbestätigung"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              order.state === 6 || order.state === 3
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "py-0" },
                                        [
                                          _vm.isLoadingPdf
                                            ? _c("v-progress-circular", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  indeterminate: "",
                                                  size: "15",
                                                },
                                              })
                                            : _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "accent",
                                                        disabled:
                                                          _vm.hasBusyCancellationRequest(
                                                            order.orderId
                                                          ),
                                                        title:
                                                          "Rechnung als Pdf Dokument runterladen",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getInvoicePdf(
                                                            order.orderId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" mdi-printer ")]
                                                  ),
                                                  _c("small", [
                                                    _vm._v("Rechnung"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              order.state != 7
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-0 mt-2",
                                      attrs: { wrap: "" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            disabled:
                                              _vm.hasBusyCancellationRequest(
                                                order.orderId
                                              ),
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.orderId = order.orderId
                                              _vm.orderState = order.state
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Details " +
                                              _vm._s(
                                                order.hasCertificatesRequested &&
                                                  (order.state === 2 ||
                                                    order.state === 3 ||
                                                    order.state === 6)
                                                  ? "/ Zertifikate"
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      (order.state === 2 ||
                                        order.state === 3 ||
                                        order.state === 6) &&
                                      !_vm.hasBusyCancellationRequest(
                                        order.orderId
                                      )
                                        ? _c("ticket-component", {
                                            attrs: {
                                              orderId: order.orderId,
                                              ticketState:
                                                _vm.getTicketStateFromOrderId(
                                                  order
                                                ),
                                              orderState: order.state,
                                              orderDate: order.dueDate,
                                              loadingTicketStates:
                                                _vm.loadingTicketStates,
                                            },
                                            on: {
                                              createOrEdit: function ($event) {
                                                return _vm.navigateToTicket(
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : order.state === 1 ||
                                          order.state === 4 ||
                                          order.state === 8
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled:
                                                  _vm.isLoadingCancellation ||
                                                  _vm.hasWaitingCancellationRequest(
                                                    order.orderId
                                                  ) ||
                                                  _vm.hasClosedCancellationRequest(
                                                    order.orderId
                                                  ) ||
                                                  _vm.hasBusyCancellationRequest(
                                                    order.orderId
                                                  ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancellationButton(
                                                    order.orderId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.isLoadingCancellation
                                                ? _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                      size: "15",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.hasWaitingCancellationRequest(
                                                order.orderId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Stornierung in Abklärung"
                                                    ),
                                                  ])
                                                : _vm.hasOpenCancellationRequest(
                                                    order.orderId
                                                  )
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Stornierung anzeigen"
                                                    ),
                                                  ])
                                                : _vm.hasClosedCancellationRequest(
                                                    order.orderId
                                                  )
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Stornierung abgeschlossen"
                                                    ),
                                                  ])
                                                : _vm.hasBusyCancellationRequest(
                                                    order.orderId
                                                  )
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Stornierung wird verarbeitet"
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Stornieren"),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              order.state != 7 &&
                              order.state != 8 &&
                              (_vm.canChangeDeliveryAddress(order) ||
                                _vm.canChangeInvoiceAddress(order))
                                ? _c(
                                    "v-row",
                                    { staticClass: "ma-0 mt-2" },
                                    [
                                      _vm.canChangeDeliveryAddress(order)
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-3",
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editAddress(
                                                    "delivery",
                                                    order
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Lieferadresse ändern ")]
                                          )
                                        : _vm._e(),
                                      _vm.canChangeInvoiceAddress(order)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editAddress(
                                                    "invoice",
                                                    order
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Rechnungsadresse ändern "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
          _vm.hasMoreToLoad
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center pa-0", attrs: { cols: "12" } },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "blexon-button",
                              attrs: { title: "Lädt weitere Bestellungen." },
                              on: {
                                click: function ($event) {
                                  return _vm.manuallyLoadMoreArticles()
                                },
                              },
                            },
                            [
                              _vm._v(" Mehr laden "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-chevron-down"),
                              ]),
                            ],
                            1
                          )
                        : _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "accent" },
                          }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.orderId != 0
        ? _c(
            "v-dialog",
            {
              attrs: { width: "90%", "max-width": "1000px", persistent: "" },
              model: {
                value: _vm.showOrderDetail,
                callback: function ($$v) {
                  _vm.showOrderDetail = $$v
                },
                expression: "showOrderDetail",
              },
            },
            [
              _c("order-detail", {
                attrs: { orderId: _vm.orderId, state: _vm.orderState },
                on: {
                  close: function ($event) {
                    _vm.orderId = 0
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showCancellationConfirmationDialog,
            callback: function ($$v) {
              _vm.showCancellationConfirmationDialog = $$v
            },
            expression: "showCancellationConfirmationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Stornierung bestätigen")]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    " Die Bearbeitung für diesen Auftrag wurde noch nicht gestartet."
                  ),
                  _c("br"),
                  _vm._v(
                    " Eine Stornierung ist in diesem Fall kostenlos möglich. Dabei kann jedoch nur der ganze Auftrag storniert werden. Bei Bedarf muss die Bestellung neu aufgegeben werden. "
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showCancellationConfirmationDialog = false
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { text: "", disabled: _vm.isLoadingCancellation },
                      on: {
                        click: function ($event) {
                          return _vm.cancelOrder()
                        },
                      },
                    },
                    [
                      _vm.isLoadingCancellation
                        ? _c("v-progress-circular", {
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _vm._v(" Stornieren "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.editAddressDialog,
            callback: function ($$v) {
              _vm.editAddressDialog = $$v
            },
            expression: "editAddressDialog",
          },
        },
        [
          _c("change-address", {
            attrs: {
              address: _vm.currentEditAddress,
              addressType: _vm.currentEditAddressType,
              newAddress: false,
              orderId: _vm.currentEditAddressOrderId,
            },
            on: {
              closedDialog: function ($event) {
                _vm.editAddressDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }