import { ErpAddressType } from './erpAddressType';

export class AddressDto {
    public id: number = 0;
    public givenName: string = '';
    public surname: string = '';
    public companyName: string = '';
    public street: string = '';
    public zip: string = '';
    public town: string = '';
    public country: string = '';
    public phone: string = '';
    public eMail: string = '';
    public customerId: number = 0;
    public isDefault: boolean = false;
    public type: ErpAddressType = ErpAddressType.Delivery;
    
    // UI only properties
    public isBilledDigitally: boolean = false;

    public get isValid() : boolean {
        let isValid = true;
        if (this.type == ErpAddressType.Delivery 
            && ((!this.givenName || this.givenName.trim().length === 0)
            || (!this.phone || this.phone.trim().length === 0))
        ) {
            isValid = false;
        }
        if ((!this.surname || this.surname.trim().length === 0)
            || (!this.street || this.street.trim().length === 0)
            || (!this.zip || this.zip.trim().length === 0)
            || (!this.town || this.town.trim().length === 0)
            || (!this.country || this.country.trim().length === 0)
        ) {
            isValid = false;
        }
        return isValid;
    }

}