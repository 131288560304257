var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "ma-0", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0",
          attrs: {
            cols:
              !_vm.isAssemblyView && (_vm.isMultiSelectionMode || _vm.hover)
                ? 10
                : 11,
          },
        },
        [
          _c(
            "p",
            { staticClass: "subtitle-1 ma-0 pa-0 truncate" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "", "open-delay": "500" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "span",
                            _vm._g(
                              {
                                staticClass: "pointerElement",
                                on: { click: _vm.openArticleEditDialog },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.article.customerArticleName) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" " + _vm._s(_vm.article.customerArticleName) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      !_vm.isAssemblyView && (_vm.hover || _vm.isMultiSelectionMode)
        ? _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "1" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "", "open-delay": "50" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    disabled: _vm.isLocked,
                                    color: "accent",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.isArticleSelected,
                                    callback: function ($$v) {
                                      _vm.isArticleSelected = $$v
                                    },
                                    expression: "isArticleSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2493155198
                  ),
                },
                [
                  _vm._v(
                    " Auswählen für Mehrfachaktion. Mögliche Aktionen werden oben angezeigt. "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "1" } },
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                icon: "",
                                small: "",
                                title: "Öffnet das Kontextmenü zum Artikel.",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled:
                          _vm.isArticleLockedAndNotAdmin || _vm.isCalculating,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openArticleEditDialog()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "accent" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(" Bearbeiten ")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled:
                          _vm.isArticleLockedAndNotAdmin ||
                          _vm.isCalculating ||
                          _vm.isDeleteDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkDeleteArticle()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "accent" } }, [
                            _vm._v("mdi-delete"),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v(" Löschen ")]),
                    ],
                    1
                  ),
                  !_vm.isAssembly && _vm.isArticleLockedAndAdmin
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.isCalculating },
                          on: {
                            click: function ($event) {
                              return _vm.unlockArticle()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-lock-open"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(" Unlock ")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAssembly && _vm.isArticleUnlockedAndAdmin
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.isCalculating },
                          on: {
                            click: function ($event) {
                              return _vm.lockArticle()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-lock"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(" Lock ")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }