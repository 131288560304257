var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.tooManyCalculatingDialog,
        callback: function ($$v) {
          _vm.tooManyCalculatingDialog = $$v
        },
        expression: "tooManyCalculatingDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Zu viele Berechnungen gleichzeitig")]),
          _c("v-card-text", [
            _vm._v(" Sie können bis zu 10 Artikel gleichzeitig blexonisieren."),
            _c("br"),
            _vm._v(
              " Die Berechnungen sind sehr zeit- und resourcenintensiv. Deshalb limitieren wir die Anzahl der gleichzeitigen Berechnungen um unsere Server zu schützen und die Leistung gleichmässig auf alle Kunden zu verteilen."
            ),
            _c("br"),
            _vm._v(
              " Bitte warten Sie, bis einige Berechnungen abgeschlossen sind. Vielen Dank für Ihr Verständnis. "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Schliessen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }