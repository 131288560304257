var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.loadingTicketStates && !_vm.orderIsTooOldForTicketCreation
    ? _c(
        "v-btn",
        {
          staticClass: "mr-3",
          attrs: {
            small: "",
            title:
              "Öffnet den Dialog zum Ausfüllen einer Beanstandung zu dieser Bestellung.",
          },
          on: {
            click: function ($event) {
              return _vm.createOrEditTicket()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }