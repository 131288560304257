var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.articleDeleteWarningDialog,
        callback: function ($$v) {
          _vm.articleDeleteWarningDialog = $$v
        },
        expression: "articleDeleteWarningDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "accent", large: "" } },
                [_vm._v("mdi-alert")]
              ),
              _vm._v(" Achtung! "),
            ],
            1
          ),
          _vm.articleCartNames.length > 0
            ? _c(
                "v-card-text",
                [
                  _vm._v(
                    " Der Artikel wird ebenfalls aus folgenden Stücklisten gelöscht:"
                  ),
                  _c("br"),
                  _vm._l(_vm.articleCartNames, function (name, index) {
                    return _c(
                      "span",
                      { key: index, staticStyle: { "margin-left": "12px" } },
                      [_c("b", [_vm._v("- " + _vm._s(name))]), _c("br")]
                    )
                  }),
                  _c("br"),
                  _vm._v("Möchten Sie den Artikel wirklich löschen? "),
                ],
                2
              )
            : _c("v-card-text", [
                _vm._v(" Möchten Sie den Artikel wirklich löschen? "),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "blexon-button",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteArticle()
                    },
                  },
                },
                [_vm._v(" Löschen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }