import axios from 'axios';

export default function() {
    const userString = window.localStorage.getItem(`oidc.user:${process.env.VUE_APP_authorityUrl}:BlexonKundenportalClient`);
    //@ts-ignore
    const user = JSON.parse(userString);
    let client = axios.create({
        baseURL: process.env.VUE_APP_SERVER_URL,
        headers: {
            Authorization: 'Bearer ' + user.access_token,
        },
    });

    client.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        console.error(error.request.responseURL);
        console.error(error.message);
        return Promise.reject(error);
    });

    return client;
}
