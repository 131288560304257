
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import httpClient from "../../store/httpClient";

	@Component
	export default class CancellationConfirmation extends Vue {
		@Prop()
		private orderId!: number;
		private isLoading: boolean = false;
		private cancellation: any = {};
		private orderData: any = {};
		private toggleAll: boolean = true;

		private async mounted() {
			await this.loadOrderData();
			if (this.orderCancellationRequests === null || this.request.length <= 0) {
				await this.loadCancellationRequest();
			} else {
				this.cancellation = this.request;
			}
			this.cancellation.positions.forEach((p: any) => p.selectedForCancellation = true);
		}

		private get orderCancellationRequests() {
			return this.$store.state.orderCancellations;
		}
		private get request() {
			return this.orderCancellationRequests.filter((o: any) => o.blexonOrderId == this.orderId)[0];
		}

		private get selectedSomePositions() {
			return this.cancellation && this.cancellation.positions.some((p: any) => p.selectedForCancellation);
		}

		private get totalCostOfCancellation() {
			if (!this.cancellation) {
				return "-";
			} else {
				let cost =
					parseFloat(this.cancellation.fixCosts) +
					this.cancellation.positions
						.filter((p: any) => p.selectedForCancellation)
						.reduce((next: number, cost: any) => next + parseFloat(cost.cancellationCost), 0);
				return cost.toFixed(2);
			}
		}

		private getArticleName(position: number) {
			if (!this.orderData.positions) return '';
			return this.orderData.positions.filter((p: any) => p.position === position)[0].customerArticleName;
		}

		private toggleAllPositions() {
			this.cancellation.positions.forEach((p: any) => {
				p.selectedForCancellation = this.toggleAll;
			});
		}

		private getThumbnailUrl(articleId: number) {
			return this.$store.getters.getThumbnailUrl(articleId, undefined);
		}

		private getShownCancellationCost(selected: boolean, cost: number) {
			return selected ? cost.toFixed(2) : '0.00';
		}

		private async loadCancellationRequest() {
			try {
				this.isLoading = true;
				const response = await httpClient().post("order/GetOrderCancellationRequestsForOrders", [this.orderId]);
				this.cancellation = response.data[0];
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Stornierungsdaten konnten nicht geladen werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private async loadOrderData() {
			try {
				this.isLoading = true;
				const response = await httpClient().get(`order/getOrderConfirmation?orderId=${this.orderId}`);
				this.orderData = response.data;
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Auftragsdaten konnten nicht geladen werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private async confirmCancellation(confirmed: boolean) {
			try {
				this.isLoading = true;
				if (!confirmed) {
					this.cancellation.positions.forEach((p:any) => p.selectedForCancellation = false);
				}
				// Don't wait for the cancellation because it might take a long time
				// if the order has to be recalculated
				httpClient().post(`order/ConfirmCancellation`, this.cancellation);
				// Wait 2 seconds so that the state of the cancellation will already
				// be set to "busy" when reloading the orders/cancellations in the order view.
				await new Promise(resolve => setTimeout(resolve, 2000));
				this.goToOrders();
			} catch (err) {
				console.log(`Fehler beim Laden der Stornierungsanfrage: ${err}`);
				this.$store.dispatch("setSnackbarErrorText", "Stornierung konnte nicht bestätigt werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private goToOrders() {
			this.$router.push({ name: "Orders" });
		}
	}
