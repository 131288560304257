var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.showHelpdeskDialogue,
        callback: function ($$v) {
          _vm.showHelpdeskDialogue = $$v
        },
        expression: "showHelpdeskDialogue",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Artikel an Blexon Helpdesk senden")]),
          _c(
            "v-card-text",
            [
              _vm._v(
                " Sie senden diesen Artikel an das Blexon Helpdesk. Wir werden die Ursache der Fehlermeldungen prüfen und, falls möglich, beheben. Gerne geben wir Ihnen auch Hinweise, wie Sie die Konstruktion ändern können, damit der Artikel herstellbar wird."
              ),
              _c("br"),
              _vm._v(" Dieser Service ist kostenlos."),
              _c("br"),
              _c("br"),
              _vm._v(
                " Sie werden per E-Mail informiert, sobald der Artikel bearbeitet wurde. Die Überprüfung erfolgt während der Geschäftszeiten in der Regel innert Stunden."
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Bitte beachten Sie, dass der Artikel nach dem Senden nicht mehr verändert oder gelöscht werden kann, bis dieser von Blexon freigegeben wird. "
              ),
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isHelpdeskEmailValid,
                    callback: function ($$v) {
                      _vm.isHelpdeskEmailValid = $$v
                    },
                    expression: "isHelpdeskEmailValid",
                  },
                },
                [
                  _c("v-text-field", {
                    staticClass: "mt-3",
                    attrs: {
                      label:
                        "E-Mail für Benachrichtigungen bezüglich dieses Artikels",
                      rules: _vm.requiredEmailRule,
                      color: "accent",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.helpdeskEmail = _vm.helpdeskEmail.trim()
                      },
                    },
                    model: {
                      value: _vm.helpdeskEmail,
                      callback: function ($$v) {
                        _vm.helpdeskEmail = $$v
                      },
                      expression: "helpdeskEmail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "blexon-button",
                  attrs: {
                    text: "",
                    disabled: _vm.isBusy || !_vm.isHelpdeskEmailValid,
                    title: "Sendet den Artikel zur Abklärung ans Helpdesk.",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.sendArticleToHelpdesk()
                    },
                  },
                },
                [_vm._v(" Senden ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }