var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.articleMultiDeleteDialog,
        callback: function ($$v) {
          _vm.articleMultiDeleteDialog = $$v
        },
        expression: "articleMultiDeleteDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "accent", large: "" } },
                [_vm._v("mdi-alert")]
              ),
              _vm._v(" Mehrere Artikel löschen "),
            ],
            1
          ),
          _vm.isLoading || _vm.isDeleting
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: "26",
                          indeterminate: "",
                          color: "accent",
                        },
                      }),
                      _c("br"),
                      _c("br"),
                      _vm.isDeleting
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.deleteCount) +
                                " von " +
                                _vm._s(_vm.validStateArticles.length) +
                                " gelöscht"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-card-text", [
                this.invalidStateArticles.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " Folgende Artikel sind entweder gesperrt oder werden derzeit berechnet und können deshalb "
                        ),
                        _c("b", [_vm._v("nicht")]),
                        _vm._v(" gelöscht werden:"),
                        _c("br"),
                        _vm._l(_vm.invalidStateArticles, function (article) {
                          return _c(
                            "span",
                            {
                              key: article.articleId,
                              staticStyle: { "margin-left": "12px" },
                            },
                            [
                              _vm._v(
                                " - " +
                                  _vm._s(article.articleId) +
                                  " | " +
                                  _vm._s(article.customerArticleName)
                              ),
                              _c("br"),
                            ]
                          )
                        }),
                        _c("br"),
                      ],
                      2
                    )
                  : _vm._e(),
                this.articlesInPartLists.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " Folgende Artikel sind Teil mindestens einer Stückliste:"
                        ),
                        _c("br"),
                        _c(
                          "v-expansion-panels",
                          { attrs: { accordion: "" } },
                          _vm._l(this.articlesInPartLists, function (article) {
                            return _c(
                              "v-expansion-panel",
                              { key: article.id },
                              [
                                _c("v-expansion-panel-header", [
                                  _vm._v(
                                    _vm._s(article.id) +
                                      " | " +
                                      _vm._s(article.name)
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _vm._v(" Enthalten in..."),
                                    _c("br"),
                                    _vm._l(article.carts, function (cart, i) {
                                      return _c("span", { key: i }, [
                                        _vm._v(" - " + _vm._s(cart)),
                                        _c("br"),
                                      ])
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c("br"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.validStateArticles.length > 0
                  ? _c("span", [
                      _vm._v(
                        "Möchten Sie " +
                          _vm._s(_vm.validStateArticles.length) +
                          " Artikel wirklich löschen?"
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "Keiner der ausgewählten Artikel kann gelöscht werden."
                      ),
                    ]),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "blexon-button",
                  attrs: {
                    text: "",
                    disabled: _vm.validStateArticles.length <= 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteArticles()
                    },
                  },
                },
                [_vm._v(" Löschen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }