import { ComplainType } from './complainType';

export interface IComplainType {
    text: string;
    value: ComplainType;
}

const values: IComplainType[] = [
    {
        text: 'Masse ausserhalb der Toleranz',
        value: ComplainType.OutOfTolerance,
    },
    {
        text: 'Abkantung fehlerhaft',
        value: ComplainType.WrongBending,
    },
    {
        text: 'Schnittqualität nicht zufriedenstellend',
        value: ComplainType.CutqualityLow,
    },
    {
        text: 'Oberfläche übermässig zerkratzt',
        value: ComplainType.Scratches,
    },
    {
        text: 'Fehlende Ausschnitte',
        value: ComplainType.MissingCuts,
    },
    {
        text: 'Falsche Schliffrichtung',
        value: ComplainType.WrongGrainDirection,
    },
    {
        text: 'Transportschaden',
        value: ComplainType.TransportDamage,
    },
    {
        text: 'Teile fehlen in Lieferung',
        value: ComplainType.Missing,
    },
    {
        text: 'Pulverbeschichtung fehlerhaft',
        value: ComplainType.PowderCoating,
    },
    {
        text: 'Schweißnaht fehlerhaft',
        value: ComplainType.Weldline,
    },
    {
        text: 'Gewinde, Senkungen oder Durchzüge fehlerhaft',
        value: ComplainType.Feature,
    },
    {
        text: 'Andere Beanstandung',
        value: ComplainType.Other,
    },
];

export default values;

