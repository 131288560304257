var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "max-width": "280" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        value: _vm.selectedDateFormated,
                        label: _vm.label,
                        hint: _vm.hint,
                        readonly: "",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.datePickerOpen,
        callback: function ($$v) {
          _vm.datePickerOpen = $$v
        },
        expression: "datePickerOpen",
      },
    },
    [
      _c("v-date-picker", {
        attrs: {
          color: "accent",
          min: _vm.minDate,
          max: _vm.maxDate,
          "first-day-of-week": "1",
          locale: "de-de",
        },
        on: {
          change: function ($event) {
            return _vm.dateChanged()
          },
        },
        model: {
          value: _vm.internalDate,
          callback: function ($$v) {
            _vm.internalDate = $$v
          },
          expression: "internalDate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }