import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                accent: '#EFBB20', // rgba(239, 187, 32)
                highlight: '#AA6200' // for highlighting text on white background
            },
        },
    },
});