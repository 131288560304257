var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticStyle: {
            "border-bottom": "1px solid #e7eaec",
            "z-index": "99",
          },
          attrs: {
            app: "",
            flat: "",
            color: "white",
            elevation: "0",
            height: "50px",
            collapse: _vm.collapseAppBar,
          },
        },
        [
          _vm.collapseAppBar
            ? _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    _vm.drawer = true
                  },
                },
              })
            : _vm._e(),
          _c("img", {
            staticStyle: { height: "50px", "padding-right": "50px" },
            attrs: { src: require("./assets/blexon_logo_portal.png") },
          }),
          !_vm.collapseAppBar && !_vm.maintenance
            ? _c(
                "v-tabs",
                { attrs: { color: "accent" } },
                [
                  _c("v-tab", { attrs: { to: "/Catalogue" } }, [
                    _vm._v(" Artikel "),
                  ]),
                  _c("v-tab", { attrs: { to: "/Orders" } }, [
                    _vm._v(" Bestellungen "),
                  ]),
                  _c("cart-tab-menu"),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.customer != null && !_vm.collapseAppBar
            ? _c(
                "div",
                {
                  staticClass: "text-body-2 pr-2",
                  staticStyle: {
                    "line-height": "0.9",
                    "white-space": "nowrap",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.customer.user.firstname) +
                      " " +
                      _vm._s(_vm.customer.user.lastname)
                  ),
                  _c("br"),
                  _c("span", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v(_vm._s(_vm.username)),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.collapseAppBar
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: menu, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "elevation-0",
                                    attrs: { color: "white" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                { ...menu }
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-2",
                                    attrs: { color: "accent" },
                                  },
                                  [_vm._v("mdi-cog")]
                                ),
                                _vm._v(" Konto "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2886138481
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.navigateTo("Settings")
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-cog"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Einstellungen")]),
                        ],
                        1
                      ),
                      _vm.userCanManageUsers
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.navigateTo("UserManagement")
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", { attrs: { color: "accent" } }, [
                                    _vm._v("mdi-account-group"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v("Benutzer verwalten"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-logout"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Abmelden")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { top: "50px" },
          attrs: { fixed: "", width: "350", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "accent" },
                  model: {
                    value: _vm.dataGroup,
                    callback: function ($$v) {
                      _vm.dataGroup = $$v
                    },
                    expression: "dataGroup",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("Catalogue")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-puzzle")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Artikel")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("Orders")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-truck")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Bestellungen")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("Checkout")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-cart")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Warenkorb")]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    { attrs: { disabled: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.username) + " "),
                        _vm.customerId !== ""
                          ? _c("span", [
                              _vm._v("(" + _vm._s(_vm.customerId) + ")"),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.navigateTo("Settings")
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-cog")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Einstellungen")]),
                    ],
                    1
                  ),
                  _vm.userCanManageUsers
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.navigateTo("UserManagement")
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-account-group")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Benutzer verwalten"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Abmelden")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-progress-linear", {
        staticClass: "ma-0",
        staticStyle: { position: "fixed", top: "50px", "z-index": "99" },
        attrs: {
          active: _vm.isLoading,
          indeterminate: "",
          color: "accent",
          height: "5px",
        },
      }),
      _vm.showErrorBanner
        ? _c(
            "v-banner",
            {
              staticStyle: { "margin-top": "55px", "margin-bottom": "-50px" },
              attrs: { color: "#EF9A9A", icon: "mdi-alert-circle", tile: "" },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0", attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _vm._v(
                          " " + _vm._s(_vm.filteredBannerErrorMessage) + " "
                        ),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 text-right",
                          attrs: { xs: "4", sm: "3", md: "2", xl: "1" },
                        },
                        [
                          !_vm.maintenance
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dismissErrorMessageBanner()
                                    },
                                  },
                                },
                                [_vm._v(" Verbergen ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showInfoBanner
        ? _c(
            "v-banner",
            {
              staticStyle: { "margin-top": "55px", "margin-bottom": "-50px" },
              attrs: { color: "#EFBB20", icon: "mdi-information", tile: "" },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0", attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _vm._v(" " + _vm._s(_vm.bannerInfoMessage) + " "),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 text-right",
                          attrs: { xs: "4", sm: "3", md: "2", xl: "1" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.dismissInfoMessageBanner()
                                },
                              },
                            },
                            [_vm._v(" Verbergen ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-main",
        { staticStyle: { "background-color": "#f3f3f4" } },
        [
          _vm.criticalDataReady && !_vm.maintenance
            ? _c("router-view")
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbar.color,
            light: "",
            timeout: _vm.snackbarTimeout,
            transition: "slide-y-reverse-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { small: "", icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.closeSnackbar()
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [
          _vm.snackbar.articleId > 0
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      width: "45px",
                      height: "45px",
                      src: _vm.getThumbnailUrl(_vm.snackbar.articleId),
                    },
                  }),
                  _c("span", { staticClass: "pl-2" }, [
                    _vm._v(_vm._s(_vm.snackbar.text)),
                  ]),
                ],
                1
              )
            : _c("div", [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]),
        ],
        1
      ),
      _c(
        "v-footer",
        {
          attrs: {
            app: "",
            padless: "",
            elevation: "0",
            height: "auto",
            fixed: "",
          },
        },
        [
          _c(
            "v-row",
            {
              staticStyle: {
                "border-top": "1px solid #e7eaec",
                "background-color": "white",
                "margin-left": "0px",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "footerLink",
                  attrs: {
                    href: "https://www.blexon.com/werkstoffe-online-blechteile/",
                    target: "_blank",
                    title: "Liste der verfügbaren Werkstoffe",
                  },
                },
                [_vm._v(" Werkstoffe ")]
              ),
              _c(
                "a",
                {
                  staticClass: "footerLink",
                  attrs: {
                    href: "https://www.blexon.com/datenschutzerklaerung/",
                    target: "_blank",
                    title: "Bestimmungen zum Datenschutz",
                  },
                },
                [_vm._v(" Datenschutz ")]
              ),
              _c(
                "a",
                {
                  staticClass: "footerLink",
                  attrs: {
                    href: "https://blexon.com/agb/",
                    target: "_blank",
                    title: "Allgemeine Geschäftsbedingungen von Blexon",
                  },
                },
                [_vm._v(" AGB ")]
              ),
              _c(
                "a",
                {
                  staticClass: "footerLink",
                  attrs: {
                    href: "https://blexon.com/impressum/",
                    target: "_blank",
                    title: "Impressum",
                  },
                },
                [_vm._v(" Impressum ")]
              ),
              _vm.customer && _vm.customer.companyName === "privat"
                ? _c("span", { staticClass: "footerText" }, [
                    _vm._v("Privatkonto (" + _vm._s(_vm.customerId) + ")"),
                  ])
                : _vm.customer
                ? _c("span", { staticClass: "footerText" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.customer.companyName) +
                        " (" +
                        _vm._s(_vm.customerId) +
                        ") "
                    ),
                  ])
                : _vm._e(),
              _vm.customer != undefined
                ? _c("span", { staticClass: "footerText" }, [
                    _vm._v(" " + _vm._s(_vm.customer.regionName) + " "),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "footerLink fill" }, [
                _c("span", { staticStyle: { "padding-left": "20px" } }, [
                  _vm._v("V " + _vm._s(_vm.appVersion)),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }