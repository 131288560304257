
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import rules from '@/store/rules'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { WerkstoffDto } from '@/dtos/werkstoffDto';
import { ArticleDataDto } from '@/dtos/articleDataDto';
import { MaterialDto } from '@/dtos/materialDto';

@Component
export default class ImportArticle extends Vue {
    @Prop()
    private articleId!: number;
    @Prop()
    private isSubArticle!: boolean; // Article of an assembly
    private articleName: string = '';
    private articleNumber: string = '';
    private deleting: boolean = false;
    private selectedThickness: number = 0;
    private articleDataValid: boolean = true;
    private requiredStringMax50Rule: any = rules.requiredStringMax50Rule;
    private optionalStringMax50Rule: any = rules.optionalStringMax50Rule;

    private mounted() {
        this.articleName = this.article.customerArticleName;
    }

    private get article() : CustomerArticleDto {
        return this.$store.state.importingArticles
            .find((a: CustomerArticleDto) => a.articleId == this.articleId);
    }

    @Watch('isCalculating')
    private resetCalculatingMessage() {
        if(!this.isCalculating) {
            this.article.calculatingMessage = '';
        }
    }

    @Watch('article.customerArticleName')
    private updateArticleName() {
        this.articleName = this.article.customerArticleName;
    }

    private get isCalculating() {
        return this.article.calculating;
    }

    private get articleInitialized() {
        return this.article.articleId >= 0;
    }

    // Werkstoffe, die für den Artikel in Frage kommen.
    // Konnte die Dicke nicht berechnet werden --> Alle Werkstoffe anzeigen.
    // Konnte die Dicke berechnet werden --> Werkstoffe anzeigen, die in der Dicke verfügbar sind.
    private get availableWerkstoffe() {
        if (this.article.thickness <= 0) {
            return (this.$store.state.werkstoffe as WerkstoffDto[]);
        }
        return (this.$store.state.werkstoffe as WerkstoffDto[])
            .filter((w: WerkstoffDto) => w.materials.some((m:MaterialDto) => m.thickness === Math.round(this.article.thickness * 1000) / 1000));
    }

    // Datenquelle für Werkstoff-DropDown.
    // Sind keine Werkstoffe vorhanden --> Alle Werkstoffe und Warnmeldung anzeigen.
    // Werkstoffe werden im Backend sortiert.
    private get selectableWerkstoffe() {
        if (this.availableWerkstoffe.length > 0) {
            return this.availableWerkstoffe;
        }
        return (this.$store.state.werkstoffe as WerkstoffDto[]);
    }

    // Datenquelle für Dicke-DropDown.
    // Ist kein Werkstoff ausgewählt --> Leere Liste.
    // Ist ein Werkstoff ausgewählt --> Vorhandene Dicken des Werkstoffs anzeigen.
    // Dicken sind im Backend vorsortiert.
    private get availableThicknesses() {
        if (this.article.werkstoffId <= 0) {
            return [];
        }
        return ((this.$store.state.werkstoffe as WerkstoffDto[])
            .find((w:WerkstoffDto) => w.blexonId === this.article.werkstoffId) as any)
            .materials.map((m:MaterialDto) => m.thickness);
    }

    private async assignWerkstoffAndStartDesign() {
        try {
            if (this.article.thickness <= 0 && 
                this.selectedThickness != undefined && this.selectedThickness > 0) {
                this.article.thickness = this.selectedThickness;
            }

            this.$store.dispatch('blexonisieren', {article: this.article, validate: true});
        } catch(err) {
            console.log(`Fehler beim hinzufügen des Werkstoffs zum Artikel ${err}`);
        }
    }

    private getThumbnailUrl(articleId: number) {
        return this.$store.getters.getThumbnailUrl(articleId, undefined);
    }

    private async deleteImportingArticle() {
        try {
            this.deleting = true;
            const response = await httpClient().post(`article/deleteArticle?articleId=${this.article.articleId}`);
            Vue.nextTick(() => {                       
                console.log(`Artikel ${this.article.articleId} wurde gelöscht`);
                this.$store.commit('deleteArticle', this.article.articleId);
            });
        } catch(err) {
            console.log(`Fehler beim Löschen des Artikels. ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Artikel konnte nicht gelöscht werden.');
        } finally {
            this.deleting = false;
        }
    }

    private async saveArticleData() {
        if(!this.articleDataValid 
            || (this.article.customerArticleName === this.articleName && this.article.customerArticleId === this.articleNumber))
        {
            return;
        }
        let articleData: ArticleDataDto = {
            articleId: this.article.articleId,
            customerArticleId: this.articleNumber,
            customerArticleName: this.articleName,
        };
        try {
            await httpClient().post('article/UpdateArticleData', articleData);
            this.$store.commit('saveArticleData', articleData);
        } catch(err) {
            console.log(`Failed to save article data of article ${this.article.articleId}. Error: ${err}`);
        }
    }
}
