/* State of orders for display or order trackings */
export enum OrderTrackingState {
    Unknown = 0,
    Ordered = 1,
    Delivered = 2,
    Paid = 3,
    InProduction = 4,
    Produced = 5,
    Fakturiert = 6,
    Cancelled = 7,
    CreditOverdrawn = 8,
}