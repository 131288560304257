
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class DatePicker extends Vue {

    @Prop()
    private selectedDate!: string;
    @Prop()
    private label!: string;
    @Prop()
    private pastDateAllowed!: boolean;
    @Prop()
    private futureDateAllowed!: boolean;
    @Prop()
    private hint!: string;
    private datePickerOpen: boolean = false;
    private internalDate: string = '';

    private minDate: string = moment().add(21, 'day').format('YYYY-MM-DD');
    private maxDate: string = moment().add(6, 'month').format('YYYY-MM-DD');

    private mounted() {
        this.setDate();
    }
    @Watch('selectedDate')
    private update() {
        this.setDate();
    }

    private setDate() {
        this.internalDate = moment(this.selectedDate).format('YYYY-MM-DD');
    }

    private get selectedDateFormated() {
        moment.locale('de');
        return this.internalDate ? moment(this.internalDate).format('DD.MM.YYYY') : '';
    }

    private dateChanged() {
        this.$emit('update:selectedDate', this.internalDate);
        this.datePickerOpen = false;
    }
}
