var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.preisInformationenDialog,
        callback: function ($$v) {
          _vm.preisInformationenDialog = $$v
        },
        expression: "preisInformationenDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "Den finalen Preis inkl. Mengenrabatt sehen Sie im Warenkorb."
            ),
          ]),
          _c("v-card-text", [
            _vm._v(
              " Die Preisliste dient Konstrukteuren zur schnellen Preisabschätzung und gilt für die Bestellung dieses einen Artikels (sortenrein)."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Um den finalen Preis zu kalkulieren, stellen Sie bitte einen Warenkorb zusammen und lassen Sie sich innert Sekunden ein Angebot inkl. Liefertermin berechnen."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              " Bei der Angebotsberechnung werden alle enthaltenen Artikel berücksichtigt und ein auftragsspezifischer Rabatt (Auftragsrabatt) berechnet. "
            ),
            _c("b", { staticStyle: { color: "#AA6200" } }, [
              _vm._v(
                "Mehrere Artikel mit gleichen Bearbeitungen oder gleichem Material führen zu einem Preisnachlass."
              ),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(" Folgende Faktoren führen zu einer "),
            _c("b", { staticStyle: { color: "#AA6200" } }, [
              _vm._v("Preisreduktion:"),
            ]),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Mehrere Artikel aus dem gleichen Rohmaterial (gleicher Werkstoff und gleiche Blechdicke)"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Mehrere Artikel mit den gleichen Bearbeitungen (Gewinde, Senkungen und Durchzüge) in gleichem Rohmaterial"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Mehrere Artikel mit gleicher Pulverbeschichtung (gleiches Pulver)"
                ),
              ]),
            ]),
            _c("br"),
            _vm._v(
              " Der Preis versteht sich exkl. Mehrwert- bzw. Umsatzsteuer, auftragsspezifischen Rabatten, Verpackung und Lieferung. "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Schliessen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }