
	import { Component, Vue, Prop } from "vue-property-decorator";
	import httpClient from "../../store/httpClient";
	import { SavedCartDto } from "@/dtos/savedCartDto";
	import SavedCarts from "@/views/Cart/SavedCarts.vue";
	import CurrentCart from "@/views/Cart/CurrentCart.vue";

	@Component({
		components: {
			SavedCarts,
			CurrentCart,
		},
	})
	export default class Cart extends Vue {
		private async mounted() {
			await this.loadSavedCarts();
		}

		private get availableSavedCarts(): SavedCartDto[] {
			var carts = this.$store.state.savedCarts;
			if (carts !== null && carts.length > 0) {
				return (carts as any[]).sort((a, b) => a.name.localeCompare(b.name));
			} else {
				return carts;
			}
		}

		private get savedCartsExist(): boolean {
			return this.availableSavedCarts != null && this.availableSavedCarts.length > 0;
		}

		private async loadSavedCarts() {
			if (this.$store.state.savedCarts === null) {
				const response = await httpClient().get("cart/GetCartsOfCustomer");
				this.$store.commit("setSavedCarts", response.data);
			}
		}
	}
