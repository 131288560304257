var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.currentOffer.orderDiscounts.length > 1 && _vm.selectedPriceModel == null
      ? _c("h1", { staticClass: "text-h4" }, [_vm._v("Wählen Sie Ihr Angebot")])
      : _c("h1", { staticClass: "text-h4" }, [_vm._v("Ihr Angebot")]),
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [
        _vm._l(_vm.currentOffer.orderDiscounts, function (discount, index) {
          return _c("offer-component", {
            key: index,
            ref: discount.priceModel,
            refInFor: true,
            staticStyle: { margin: "8px" },
            attrs: { priceModel: discount.priceModel },
            on: {
              selectedChanged: function ($event) {
                return _vm.changeSelection($event)
              },
            },
          })
        }),
        _vm.manualOfferPossible
          ? _c("manual-offer", { staticStyle: { margin: "8px" } })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }