var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.userCanCreateAddresses
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      on: {
                        click: function ($event) {
                          return _vm.createNewAddress()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" Neue Adresse erstellen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { wrap: "", justify: "start" } },
        _vm._l(_vm.addresses, function (address) {
          return _c(
            "v-col",
            {
              key: address.id,
              attrs: {
                cols: "12",
                sm: "6",
                md: "4",
                lg: "3",
                xl: "3",
                shrink: "",
              },
            },
            [
              _vm.addressLoaded
                ? _c("address-tile", {
                    attrs: {
                      type:
                        _vm.type == _vm.deliveryType
                          ? _vm.customerDeliveryType
                          : _vm.customerInvoiceType,
                      propAddress: address,
                      isOrder: _vm.isOrder,
                    },
                    on: {
                      removedAddress: function ($event) {
                        return _vm.setAddress()
                      },
                      setAddressForOrder: function ($event) {
                        return _vm.setAddressForOrder()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.newAddressdialog,
            callback: function ($$v) {
              _vm.newAddressdialog = $$v
            },
            expression: "newAddressdialog",
          },
        },
        [
          _c("change-address", {
            attrs: {
              address: _vm.newAddress,
              addressType:
                _vm.type == _vm.deliveryType
                  ? _vm.customerDeliveryType
                  : _vm.customerInvoiceType,
              newAddress: true,
            },
            on: { closedDialog: _vm.closeEditDialogAndReload },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }