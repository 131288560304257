
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ArticleMultiDeleteDialog from '@/views/Dialogs/ArticleMultiDeleteDialog.vue'
import ArticleTreatmentEditDialog from '@/views/Dialogs/ArticleTreatmentEditDialog.vue'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
@Component({
    components: {
        ArticleMultiDeleteDialog,
        ArticleTreatmentEditDialog,
    }
})
export default class Multiedit extends Vue {

    private articleMultiDeleteDialog: boolean = false;
    private articleTreatmentEditDialog: boolean = false;
    private isUpdatingArticleData: boolean = false;

    private get selectedArticlesCount() {
        return this.$store.state.selectedArticleIds.length;
    }
    private get multiSelectionMode() {
        return this.selectedArticlesCount > 0;
    }

    private get selectedArticles() {
        return this.$store.state.articles
            .filter((a:CustomerArticleDto) => this.$store.state.selectedArticleIds.includes(a.articleId));
    }

    private get allSelectedCanBePowdercoated() {
        return this.selectedArticles.every((a:any) => this.$store.getters.canArticleChangePowderCoating(a));
    }

    private get powdercoatingTooltipText() {
        if (this.allSelectedCanBePowdercoated) {
            return "Für alle ausgewählten Artikel eine Pulverbeschichtung hinzufügen, bearbeiten oder entfernen.";
        } else {
            return "Mindestens 1 ausgewählter Artikel kann nicht pulverbeschichtet werden.";
        }
    }

    private get isPowderCoatingAvailable() {
        return this.$store.state.isPowderCoatingAvailable;
    }

    private clearSelection() {
        this.$store.commit('clearSelectedArticleIds');
    }

    private setUpdating(value: boolean) {
        this.isUpdatingArticleData = value;
    }

}

