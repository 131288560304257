/* Sorting type for articles in the catalogue */
export enum CatalogueSortingType {
    CreatedAtAscending = 0,
    //
    // Summary:
    //     When was the article created
    CreatedAtDescending = 1,
    LastChangeAscending = 2,
    LastChangeDescending = 3,
    //
    // Summary:
    //     Thickness of material ascending. Example: All 1mm first. Than all 2mm
    MaterialThicknessAscending = 4,
    MaterialThicknessDescending = 5,
    //
    // Summary:
    //     Name of material ascending Example: All Aluminium first, than all stahl. The
    //     parts with aluminium will be sorted by thickness within the group.
    MaterialNameAscending = 6,
    MaterialNameDescending = 7,
    //
    // Summary:
    //      Name of article ascending and descending
    ArticleNameAscending = 8,
    ArticleNameDescending = 9,
}