
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TicketState } from '@/dtos/ticket/ticketState';
import { OrderTrackingState } from '@/dtos/orderTrackingState';
import { TicketStateDto } from '@/dtos/ticket/ticketStateDto';
@Component({
	components: {}
})
export default class TicketComponent extends Vue {
	@Prop()
	private orderId!: number;
	@Prop()
	private ticketState!: TicketStateDto;
	@Prop()
	private orderState!: OrderTrackingState;
	@Prop()
	private orderDate!: Date;
	@Prop()
	private loadingTicketStates!: boolean;
	
	private get isAdmin() {
		return this.$store.state.customer.user.isAdmin;
	}

	private get orderIsTooOldForTicketCreation() {
		if (this.isAdmin) {
			return false
		}
		var refDate = new Date(this.orderDate);
		let dateLimit = new Date();
		if (refDate === undefined || !(refDate instanceof Date)) {
			return true;
		}
		dateLimit.setDate(dateLimit.getDate() - 60);
		return refDate < dateLimit;
	}

	private createOrEditTicket() {
		this.$emit('createOrEdit', this.orderId);
	}

	private get buttonText() {
		if (this.ticketState == undefined) {
			return 'Beanstandung erfassen';
		}
		return 'Beanstandung anzeigen';
	}

	private get isOrderDelivered() {
		return (
			!this.loadingTicketStates &&
			(this.orderState === OrderTrackingState.Delivered ||
				this.orderState === OrderTrackingState.Paid ||
				this.orderState === OrderTrackingState.Fakturiert)
		);
	}
}
