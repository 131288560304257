var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VDropdown",
    {
      attrs: {
        placement: "auto",
        theme: "tooltip",
        distance: 6,
        delay: 0,
        autoHide: true,
        triggers: [],
        shown: _vm.showBubble,
      },
      scopedSlots: _vm._u([
        {
          key: "popper",
          fn: function () {
            return [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showBubble = false
                    },
                  },
                },
                [
                  _c("h4", { staticStyle: { "margin-bottom": "4px" } }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block", "margin-bottom": "8px" },
                    },
                    [_vm._v(_vm._s(_vm.message))]
                  ),
                  _c("v-checkbox", {
                    attrs: {
                      label: "Meldung nicht mehr anzeigen",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.dontShowAgain,
                      callback: function ($$v) {
                        _vm.dontShowAgain = $$v
                      },
                      expression: "dontShowAgain",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }