
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';

@Component
export default class ArticleMultiDeleteDialog extends Vue {
	@Prop()
	private articleMultiDeleteDialog!: boolean;
	private selectedArticles: CustomerArticleDto[] = new Array<CustomerArticleDto>();
	private articlesInPartLists: any[] = [];
	private isLoading: boolean = false;
	private isDeleting: boolean = false;
	private deleteCount: number = 0;

	@Watch('articleMultiDeleteDialog')
	private async load() {		
		if (this.articleMultiDeleteDialog) {
			try {
				this.isLoading = true;
				await this.loadArticles().then(this.checkArticleCarts);
			} catch(err) {
				console.log('Error while loading data for multi delete.');
			} finally {
				this.isLoading = false;
			}
		}
	}

	private get selectedArticleIds() {
		return this.$store.state.selectedArticleIds;
	}
	private get invalidStateArticles(): CustomerArticleDto[] {
		return this.selectedArticles.filter((a:CustomerArticleDto) => a.locked || a.calculating)
	}
	private get validStateArticles(): CustomerArticleDto[] {
		return this.selectedArticles.filter((a:CustomerArticleDto) => !a.locked && !a.calculating);
	}

	private async loadArticles() {
		if (this.selectedArticleIds.length > 0) {
			try {
				let response = await httpClient().post('article/GetArticlesById', this.selectedArticleIds);
				this.selectedArticles = response.data;
			} catch(err) {
				console.log(`Could not get articles from article ids. ${err}`);
			}
		}
	}

	private checkArticleCarts() {
        try {
            this.articlesInPartLists = [];
			this.validStateArticles.forEach(async (a:CustomerArticleDto) => {
				const response = await httpClient().get(`Cart/GetCartsOfArticle?articleId=${a.articleId}`);
				if (response.data.length > 0) {
					let newEntry = {
						id: a.articleId,
						name: a.customerArticleName,
						carts: response.data.sort()
					};
					this.articlesInPartLists.push(newEntry);
				}
			})
        } catch(err) {
            console.log(`Could not retrieve carts of one of the articles. ${err}`);
        }
    }

	private async deleteArticles() {
		console.log('deleting selected articles...');
        try {
			this.isDeleting = true;
			this.deleteCount = 0;
			let index = 1;
			for (const a of this.validStateArticles) {
				const response = await httpClient().post(`article/deleteArticle?articleId=${a.articleId}&force=true`);
				let data = {
					articleId: a.articleId,
					refreshCart: index >= this.validStateArticles.length,
				}
				this.$store.dispatch('deleteArticle', data);
				index++;
				this.deleteCount++;
			}
        	this.$store.commit('clearSelectedArticleIds');
			console.log('cleared selected');
        } catch(err) {
            console.log(`Fehler beim Löschen der Artikel. ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Löschen eines Artikels.');
        } finally {
			this.isDeleting = false;
			this.closeDialog();
		}
	}

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}
}

