export class UserInfoDto {
	public userId: string = '';
	public firstname: string = '';
	public lastname: string = '';
	public phone: string = '';
	public username: string = '';
	public newsletterSubscribed: boolean = false;
	public appCode: string = '';
	public isAdmin: boolean = false;
	public canOrderDirectly: boolean = false;
	public canOrderIndirectly: boolean = false;
	public canChangeInvoiceAddress: boolean = false;
	public canChangeDeliveryAddress: boolean = false;
	public canManageUsers: boolean = false;
	public preferredDeliveryAddressId: number = 0;
	public preferredInvoiceAddressId: number = 0;
}