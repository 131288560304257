export class UserLoginDto {
    public firstname: string = '';
    public lastname: string = '';
    public username: string = '';
    public userId: string = '';
	public confirmed: boolean = false;
	public canManageUsers: boolean = false;
	public canChangeDeliveryAddress: boolean = false;
	public canChangeInvoiceAddress: boolean = false;
	public canOrderDirectly: boolean = false;
	public canOrderIndirectly: boolean = false;
}