
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import TicketComponent from '@/views/OrderHistory/TicketComponent.vue';
import { PriceModel } from '@/dtos/priceModel';
import { Entgratart } from '@/dtos/entgratart';
import { getEntgratartText } from '@/dtos/entgratart';
import { OrderTrackingState } from '@/dtos/orderTrackingState';

@Component({
    components: {
        TicketComponent
    }
})
export default class OrderDetail extends Vue {
    @Prop()
    private orderId!: number
    @Prop()
    private state!: OrderTrackingState;
    private order: any = null;
    private loading: boolean = false;
    private certificateError: boolean = false;
    private fertiger: any = {
        companyName: '',
        name: '',
        street: '',
        zipAndTown: '',
    }
    private mounted() {
        this.loadOrderConfirmation();
    }

    private getEntgratartString(entgratart: Entgratart) {
        let text = '';
        if (entgratart != Entgratart.None) {
            text += 'Entgraten: ';
        }
        text += getEntgratartText(entgratart);
        return text;
    }

    private async loadOrderConfirmation() {
        try {
            this.loading = true;
            var response = await httpClient().get(`order/getOrderConfirmation?orderId=${this.orderId}`);
            this.order = response.data;
            if (this.order.rampenabholung) {
                var response = await httpClient().get(`order/getFertiger?fertigerId=${this.order.fertigerId}`);
                this.fertiger.companyName = response.data.companyname;
                this.fertiger.name = `Abholung`;
                this.fertiger.steet = response.data.street
                this.fertiger.zipAndTown = `${response.data.zip} ${response.data.town}`
            }
        } catch(e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    private get priceModelText(): string {
        let text = '';
        switch (this.order.priceModel) {
            case PriceModel.Standard:
                text = "(Schnelle Lieferung)";
                break;
            case PriceModel.Slow:
                text = "(Preisoptimale Lieferung)";
                break;
            case PriceModel.Express:
                text = "(Express Lieferung)";
                break;
            default:
                break;
        }
        return text;
    }

    private getMwSt() {
        var total = 0;
        for (const position of this.order.positions) {
            total += position.pricePerUnit * position.quantity
        }
        var mwst = Math.round(total * this.order.mwStSatz) / 100;
        return mwst;
    }

    private async downloadCertificate(position: any, order: any) {
        try {
            const response = await httpClient().get(`order/getCertificate?orderId=${order.blexonOrderId}&articleId=${position.blexonArticleId}`,
                {
                    responseType: 'blob'
                })
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.download = `Certificate ${order.blexonOrderId} - ${position.blexonArticleId}.pdf`
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
        } catch {
            this.certificateError = true;
        }
    }

    private getTotal() {
        var total = 0;
        for (const position of this.order.positions) {
            total += position.pricePerUnit * position.quantity
        }
        return total + this.getMwSt();
    }
    private getThumbnailUrl(articleId: number) {
        return this.$store.getters.getThumbnailUrl(articleId, undefined);
    }
}

