
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import httpClient from "./store/httpClient";
	import CartTabMenu from "./views/Cart/CartTabMenu.vue";

	@Component({
		components: {
			CartTabMenu,
		},
	})
	export default class App extends Vue {
		@Prop()
		private msg!: string;
		private drawer: boolean = false;
		private currentWindowWidth: number = 0;
		private dataGroup: any = null;
		private userGroup: any = null;
		private criticalDataReady: boolean = false;
		private impersonateUsername: string = "";
		private userCanManageUsers: boolean = false;

		private async created() {
			this.$store.commit("setCurrentOfferToNull");
			await this.loadUserInfoAndFileSizeLimits()
				.then(this.setUserCanManageUsers)
				.then(this.setupShoppingCart)
				.then(this.loadAvailabilities)
				.then(this.loadHints)
				.then(() => this.$store.dispatch('loadManufacturerAdresses'))
				.then(() => (this.criticalDataReady = true))
				.then(this.checkForKundenportalErrorMessages)
				.then(this.checkForKundenportalInfoMessages);
		}

		private mounted() {
			this.setupResizeEvent();
			this.setupArticlePollingInterval();
			console.log("App started");
		}

		private get isAdmin() {
			return this.$store.state.customer.user.isAdmin;
		}

		private async setUserCanManageUsers() {
			const response = await httpClient().get("Customer/CanUserManageUsers");
			this.userCanManageUsers = response.data;
		}

		private get maintenance() {
			return this.$store.getters.isMaintenance && !this.isAdmin;
		}

		private get appVersion() {
			return (this.$store.state.packageJson as any).version;
		}

		private get username() {
			if (this.$store.state.oidc !== undefined) {
				console.log("logged in as " + this.$store.state.oidc.user.profile.name);
				return this.$store.state.oidc.user.profile.name;
			}
			return "";
		}

		private get customerId() {
			return this.$store.getters.customerId;
		}

		private get customer() {
			return this.$store.state.customer;
		}

		private get snackbar() {
			return this.$store.state.snackbar;
		}

		private get isLoading() {
			return this.$store.state.loading;
		}

		private get collapseAppBar() {
			return this.currentWindowWidth < 1000;
		}

		private get bannerErrorMessage() {
			return this.$store.state.bannerErrorMessage;
		}
		private get filteredBannerErrorMessage() {
			if (this.$store.getters.isMaintenance) {
				return this.bannerErrorMessage.substring('MAINTENANCE '.length);
			}
			return this.bannerErrorMessage;
		}
		private get bannerInfoMessage() {
			return this.$store.state.bannerInfoMessage;
		}

		private getThumbnailUrl(articleId: number) {
			return this.$store.getters.getThumbnailUrl(articleId, undefined);
		}

		private get showSnackbar() {
			return this.$store.state.showSnackbar;
		}
		private set showSnackbar(value: boolean) {
			this.$store.commit("setShowSnackbar", value);
		}
		private get snackbarTimeout() {
			return this.$store.state.snackbarTimeout;
		}
		private closeSnackbar() {
			this.$store.commit("setShowSnackbar", false);
		}

		private get showErrorBanner(): boolean {
			return this.bannerErrorMessage.length > 0;
		}
		private get showInfoBanner(): boolean {
			return !this.showErrorBanner && this.bannerInfoMessage.length > 0;
		}

		private navigateTo(to: string) {
			this.$router.push({ name: to });
		}

		private logout() {
			this.$store.dispatch("logout");
		}

		private async loadUserInfoAndFileSizeLimits() {
			const response = await httpClient().get("Customer/GetCustomer");
			this.$store.dispatch('setCustomerInfo', response.data);
			this.$store.dispatch('loadFileSizeLimits');
			this.$store.dispatch('loadUsers');
		}

		private async loadAvailabilities() {
			const response = await httpClient().get(
				`material/GetAvailabilities?regionId=${this.$store.state.customer.regionId}`
			);
			let availabilities = response.data;
			this.$store.commit("setWerkstoffe", availabilities.werkstoffe);
			this.$store.commit("setPowderCoatingAvailability", availabilities.isPulverbeschichtungAvailable);
		}

		private setupShoppingCart() {
			if (this.$store.state.currentCart === null) {
				const userAddresses = {
					delivery: this.$store.getters.userPreferredDeliveryAddress,
					invoice: this.$store.getters.userPreferredInvoiceAddress,
				}
				this.$store.dispatch("initializeCart", userAddresses);
			}
		}

		private setupResizeEvent() {
			this.setWindowWidth();
			window.addEventListener("resize", (event) => {
				this.setWindowWidth();
			});
		}

		private setupArticlePollingInterval() {
			this.$store.dispatch('startArticleCalculatingPollingTimer');
		}

		private setWindowWidth() {
			this.currentWindowWidth = window.innerWidth;
		}

		private async loadHints() {
			const response = await httpClient().get("Customer/GetNotShownUserHints");
			this.$store.commit('setUnseenHintIds', response.data);
		}

		private async checkForKundenportalErrorMessages() {
			const response = await httpClient().get("Settings/GetLastBroadcastEvent?eventType=KundenportalError");
			this.$store.commit("setBannerErrorMessage", response.data);
		}
		private dismissErrorMessageBanner() {
			this.$store.commit("setBannerErrorMessage", "");
		}
		private async checkForKundenportalInfoMessages() {
			const response = await httpClient().get("Settings/GetLastBroadcastEvent?eventType=KundenportalInfo");
			this.$store.commit("setBannerInfoMessage", response.data);
		}
		private dismissInfoMessageBanner() {
			this.$store.commit("setBannerInfoMessage", "");
		}
	}
