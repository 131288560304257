var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("tr", [
    _c(
      "td",
      { attrs: { width: "40" } },
      [
        _vm.article.state == 3
          ? _c("v-progress-circular", {
              attrs: { size: "12", indeterminate: "" },
            })
          : _c(
              "v-icon",
              { attrs: { dark: "", color: _vm.articleStateColor() } },
              [_vm._v(" " + _vm._s(_vm.articleStateIcon()) + " ")]
            ),
      ],
      1
    ),
    _c(
      "td",
      { attrs: { width: "90" } },
      [
        _c("v-img", {
          attrs: {
            contain: "",
            width: "90px",
            height: "90px",
            src: _vm.thumbnailUrl,
          },
        }),
      ],
      1
    ),
    _c("td", [
      _c("div", { staticClass: "text-subtitle-1" }, [
        _vm._v(_vm._s(_vm.article.customerArticleName)),
      ]),
      _c("div", { staticClass: "text-caption" }, [
        _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
          _c("strong", [_vm._v("Material: ")]),
          _vm._v(_vm._s(_vm.article.materialname)),
        ]),
        _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
          _c("strong", [_vm._v("Blexonnummer: ")]),
          _vm._v(_vm._s(_vm.article.articleId)),
        ]),
      ]),
      _vm.article.priceState == 1 || _vm.article.priceState == 4
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "blexon-button mb-2",
                  attrs: {
                    loading: _vm.article.priceState == 4,
                    title: "Berechnet den Preis für diesen Artikel neu.",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.recalculatePrice()
                    },
                  },
                },
                [_vm._v(" Preis neu berechnen ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "td",
      { attrs: { width: "180" } },
      [
        _c("v-text-field", {
          attrs: {
            placeholder: "Stk.",
            rules: _vm.allowedCartAmountRule,
            type: "number",
            color: "accent",
          },
          model: {
            value: _vm.articleQuantity,
            callback: function ($$v) {
              _vm.articleQuantity = $$v
            },
            expression: "articleQuantity",
          },
        }),
      ],
      1
    ),
    _c(
      "td",
      { staticClass: "text-right", attrs: { width: "160" } },
      [
        _c(
          "v-btn",
          {
            staticClass: "mr-1",
            attrs: {
              small: "",
              title:
                "Entfernt den Artikel aus dem Warenkorb. Entfernt den Artikel nicht aus Stücklisten.",
            },
            on: {
              click: function ($event) {
                return _vm.removeFromCart()
              },
            },
          },
          [_c("v-icon", [_vm._v("mdi-close")])],
          1
        ),
        _vm.selectedSavedCartId > 0
          ? _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  title: "Position zur aktuellen Stückliste hinzufügen",
                },
                on: {
                  click: function ($event) {
                    return _vm.addToSavedCart()
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-playlist-plus")])],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }