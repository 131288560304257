export class FertigerAddressDto{
    public manufacturerId: number = 0;
    public companyname: string = '';
    public street: string = '';
    public zip: string = '';
    public town: string = '';
    public deliveryPhoneNumber: string = '';
    public ticketEMail: string = '';
    public technicalEMail: string = '';
    public generalEMail: string = '';
    public isRampenabholungAvailable: boolean = true;
}