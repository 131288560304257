var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5" }, [
            _vm._v(" Lieferart "),
          ]),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                "Auf welchem Weg möchten Sie die Blechteile zugestellt bekommen?"
              ),
            ]),
            _c(
              "div",
              [
                _vm.isAdmin
                  ? _c("span", [
                      _vm._v(
                        "Hergestellt von: " + _vm._s(_vm.selectedFertigerName)
                      ),
                      _c("br"),
                    ])
                  : _vm._e(),
                _c(
                  "v-radio-group",
                  {
                    attrs: { "hide-details": "" },
                    model: {
                      value: _vm.deliveryChoice,
                      callback: function ($$v) {
                        _vm.deliveryChoice = $$v
                      },
                      expression: "deliveryChoice",
                    },
                  },
                  [
                    _c("v-radio", {
                      attrs: {
                        color: "accent",
                        value: "delivery",
                        label: "Geliefert (1-2 Tage ab Lieferdatum)",
                      },
                    }),
                    _c("v-radio", {
                      attrs: {
                        color: "accent",
                        value: "rampe",
                        label: "Rampenabholung (unverpackt)",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isRampenabholung
              ? _c(
                  "div",
                  { staticClass: "ml-10 my-0" },
                  [
                    _vm.areFertigerAddressesLoading
                      ? _c("v-progress-circular", {
                          attrs: { color: "accent", indeterminate: "" },
                        })
                      : _vm.isFertigerAddressesLoadingFailed
                      ? _c("p", [
                          _vm._v(
                            " Die Fertiger konnten nicht geladen werden. "
                          ),
                        ])
                      : _c(
                          "v-radio-group",
                          {
                            attrs: { "hide-details": "" },
                            model: {
                              value: _vm.rampeManufacturerId,
                              callback: function ($$v) {
                                _vm.rampeManufacturerId = $$v
                              },
                              expression: "rampeManufacturerId",
                            },
                          },
                          [
                            _c("v-radio", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              attrs: { value: "-1", label: "invisible" },
                            }),
                            _vm._l(
                              _vm.manufacturingOffers,
                              function (fertiger, index) {
                                return _c(
                                  "v-row",
                                  { key: index },
                                  [
                                    _c("v-radio", {
                                      attrs: {
                                        color: "accent",
                                        label: _vm.getFertigerTownById(
                                          fertiger.manufacturerId
                                        ),
                                        value: fertiger.manufacturerId,
                                        disabled:
                                          !_vm.isRampenabholungAvailable(
                                            fertiger.manufacturerId
                                          ),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "ml-2" },
                                      [
                                        _vm.isRampenabholungAvailable(
                                          fertiger.manufacturerId
                                        )
                                          ? _c(
                                              "v-icon",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openManufacturerAddressDialog(
                                                      fertiger
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-map-marker-radius "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isManufacturerNotPossibleDialogVisible = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-information-outline "
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", [
              !_vm.isValidSelection
                ? _c("span", { staticClass: "red--text" }, [
                    _vm._v(
                      "Bitte wählen Sie einen Standort für die Rampenabholung."
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.isManufacturerAddressDialogVisible,
            callback: function ($$v) {
              _vm.isManufacturerAddressDialogVisible = $$v
            },
            expression: "isManufacturerAddressDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Adresse für Rampenabholung")]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.currentFertigerAddress.companyname)),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.currentFertigerAddress.street)),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.currentFertigerAddress.zip) +
                    " " +
                    _vm._s(_vm.currentFertigerAddress.town)
                ),
                _c("br"),
                _vm._v(
                  " Tel.: " +
                    _vm._s(_vm.currentFertigerAddress.deliveryPhoneNumber) +
                    " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isManufacturerAddressDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" Schliessen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.isManufacturerNotPossibleDialogVisible,
            callback: function ($$v) {
              _vm.isManufacturerNotPossibleDialogVisible = $$v
            },
            expression: "isManufacturerNotPossibleDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Rampenabholung nicht möglich")]),
              _c("v-card-text", [
                _vm._v(
                  " Die Abholung dieser Bestellung ist bei diesem Fertigungspartner leider nicht möglich. Es kann sein, dass dieser Fertigungspartner keine Rampenabholung anbietet, oder dass ein Material oder eine Bearbeitung nicht verfügbar ist. "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isManufacturerNotPossibleDialogVisible = false
                        },
                      },
                    },
                    [_vm._v(" Schliessen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }