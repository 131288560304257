// Muss in den jeweiligen Modulen importiert werden mit:
// import rules from '@/store/rules'

export default {
    optionalStringMax35Rule: [
        (v: string) => v.length <= 35 || 'Maximal 35 Zeichen erlaubt.'
    ],
    requiredRule: [
        (v: string) => !!v || 'Angabe erforderlich. ',
		(v: string) => !v || v.replace(/\s/g, '').length > 0 || 'Eingabe darf nicht nur Leerzeichen sein.',
    ],
    addressOptionalStringMax35Rule: [
        (v: string) => v.length <= 35 || 'Maximal 35 Zeichen erlaubt.',
        (v: string) => !/[\*\^\\\{\}\[\]\=\>\<\|]/.test(v) || 'Enthält unerlaubte Zeichen \"* ^ \ { } [ ] = < > |\"'
    ],
    optionalStringMax50Rule: [
        (v: string) => v.length <= 50 || 'Maximal 50 Zeichen erlaubt.'
    ],
    requiredStringMax35Rule: [
        (v: string) => !!v || 'Angabe erforderlich',
        (v: string) => v.replace(/\s/g, '').length > 0 || 'Eingabe darf nicht nur Leerzeichen sein.',
        (v: string) => !v || v.length <= 35 || 'Maximal 35 Zeichen erlaubt.'
    ],
    addressRequiredStringMax35Rule: [
        (v: string) => !!v || 'Angabe erforderlich',
        (v: string) => v.replace(/\s/g, '').length > 0 || 'Eingabe darf nicht nur Leerzeichen sein.',
        (v: string) => !v || v.length <= 35 || 'Maximal 35 Zeichen erlaubt.',
        (v: string) => !/[\*\^\\\{\}\[\]\=\>\<\|]/.test(v) || 'Enthält unerlaubte Zeichen \"* ^ \ { } [ ] = < > |\"'
    ],
    requiredStringMax50Rule: [
        (v: string) => !!v || 'Angabe erforderlich',
        (v: string) => v.replace(/\s/g, '').length > 0 || 'Eingabe darf nicht nur Leerzeichen sein.',
        (v: string) => !v || v.length <= 50 || 'Maximal 50 Zeichen erlaubt.'
    ],
    allowedCartAmountRule: [
        (v: number) => !!v || 'Angabe erforderlich',
        (v: number) => !isNaN(v) || 'Muss eine Zahl sein.',
        (v: number) => v >= 1 || 'Muss positiv sein.',
        (v: number) => v % 1 === 0 || 'Keine Kommastellen erlaubt',
    ],
    requiredEmailRule: [
        (v: string) => !!v || 'Angabe erforderlich. ',
        (v: string) => v.length <= 50 || 'Maximal 50 Zeichen erlaubt. ',
        (v: string) => /^[^@\s;]+@[^@\s;]+\.[^@\s;]+$/.test(v) || 'Muss eine gültige E-Mail Adresse sein. ',
    ],
    optionalEmailRule: [
        (v: string) => !v || (/^[^@\s;]+@[^@\s;]+\.[^@\s;]+$/.test(v)) || 'Muss eine gültige E-Mail Adresse sein. '
    ],
    requiredIntegerRule: [
        (v: number) => !!v || 'Angabe erforderlich',
        (v: number) => !isNaN(v) || 'Muss eine Zahl sein.',
        (v: string) => parseInt(v) === parseFloat(v) || 'Muss eine ganze Zahl sein.'
    ],
    requiredInteger0To1000Rule: [
        (v: number) => !!v || 'Angabe erforderlich',
        (v: number) => !isNaN(v) || 'Muss eine Zahl sein.',
        (v: string) => parseInt(v) === parseFloat(v) || 'Muss eine ganze Zahl sein.',
        (v: number) => v > 0 && v <= 1000 || 'Muss zwischen 0 und 1000 sein',
    ],
}