var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "importArticleTile", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _c("strong", [_vm._v("Blexon Nr:")]),
                    _vm._v(" " + _vm._s(_vm.article.articleId)),
                  ]),
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.articleDataValid,
                        callback: function ($$v) {
                          _vm.articleDataValid = $$v
                        },
                        expression: "articleDataValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelname",
                          rules: _vm.requiredStringMax50Rule,
                          disabled: !_vm.articleInitialized,
                          color: "accent",
                        },
                        on: {
                          blur: () => {
                            _vm.articleName = _vm.articleName.trim()
                            _vm.saveArticleData()
                          },
                        },
                        model: {
                          value: _vm.articleName,
                          callback: function ($$v) {
                            _vm.articleName = $$v
                          },
                          expression: "articleName",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelnummer (optional)",
                          placeholder: "Ihre Artikelnummer",
                          disabled: !_vm.articleInitialized,
                          rules: _vm.optionalStringMax50Rule,
                          color: "accent",
                        },
                        on: {
                          blur: () => {
                            _vm.articleNumber = _vm.articleNumber.trim()
                            _vm.saveArticleData()
                          },
                        },
                        model: {
                          value: _vm.articleNumber,
                          callback: function ($$v) {
                            _vm.articleNumber = $$v
                          },
                          expression: "articleNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticStyle: { position: "relative", "text-align": "center" },
                  attrs: { cols: "6" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      contain: "",
                      "max-height": "130",
                      src: _vm.getThumbnailUrl(_vm.article.articleId),
                    },
                  }),
                  _vm.article.calculating
                    ? _c(
                        "div",
                        [
                          _c("v-progress-circular", {
                            staticStyle: { position: "absolute", top: "49px" },
                            attrs: {
                              color: "accent",
                              size: "32",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.article.calculating
                    ? _c("span", { staticClass: "text-caption" }, [
                        _vm._v(
                          " Oh, eine Baugruppe. Dann zerlegen wir die mal... "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  !_vm.article.calculating
                    ? _c("v-select", {
                        attrs: {
                          dense: "",
                          color: "accent",
                          disabled:
                            _vm.article.calculating ||
                            _vm.selectableWerkstoffe.length <= 0,
                          placeholder:
                            _vm.selectableWerkstoffe.length > 0
                              ? "Bitte Werkstoff wählen..."
                              : "Kein gemeinsamer Werkstoff für alle Teile verfügbar",
                          "item-text": "bezeichnung",
                          "item-value": "blexonId",
                          items: _vm.selectableWerkstoffe,
                        },
                        model: {
                          value: _vm.selectedWerkstoffId,
                          callback: function ($$v) {
                            _vm.selectedWerkstoffId = $$v
                          },
                          expression: "selectedWerkstoffId",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.deleting,
                          disabled: _vm.deleting,
                          title:
                            "Bricht den Import-Prozess ab und löscht den Artikel aus der Liste.",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteImportingArticle()
                          },
                        },
                      },
                      [
                        !_vm.article.calculating
                          ? _c("v-icon", [_vm._v("mdi-delete")])
                          : _c("span", [_vm._v("Abbrechen")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-right mt-2" },
                  [
                    !_vm.deleting
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "blexon-button",
                            attrs: {
                              disabled:
                                !_vm.subArticlesValid ||
                                _vm.article.calculating ||
                                !_vm.articleDataValid,
                              title:
                                "Analysiert die Baugruppe und erkennt Bearbeitungen automatisch. Preisberechnung wird gestartet.",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.blexonisieren()
                              },
                            },
                          },
                          [_vm._v(" Blexonisieren ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        _vm._l(_vm.subArticles, function (subArticle) {
          return _c(
            "v-row",
            { key: subArticle.articleId },
            [
              _c("import-article", {
                staticStyle: { "margin-left": "3em" },
                attrs: { articleId: subArticle.articleId, isSubArticle: true },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }