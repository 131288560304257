
import { Component, Vue } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import { OrderRequestDto } from '@/dtos/orderRequestDto';
import { ManufacturingOfferDto } from '@/dtos/manufacturingOfferDto';
import DatePicker from '../Settings/DatePicker.vue'
import moment from 'moment';
import { FertigerAddressDto } from '@/dtos/fertigerAddressDto';

@Component({
    components: {
        DatePicker
    }
})
export default class ManualOffer extends Vue {
    private deliveryDate: Date = new Date();
    private isLoading: boolean = false;

    mounted() {
        const preselectedIndex = Math.round(this.allowedDates.length / 2);
        if (preselectedIndex > 0) {
            this.deliveryDate = this.allowedDates[preselectedIndex];
        }
    }

    private isOfferRequestAllowedDueToDeliveryType() {
        if (this.$store.state.offer.deliveryChoice === 'rampe') {
            const manufacturingOffer = this.$store.state.offer.manufacturingOffers.find((o:ManufacturingOfferDto) => o.manualOfferPossible)
            if (manufacturingOffer.manufacturerId === this.$store.state.offer.selectedManufacturerId) {
                return true;
            }
            return false;
        }
        return true
    }

    private getTownOfManualOfferManufacturer() {
        const manufacturingOffer = this.$store.state.offer.manufacturingOffers.find((o:ManufacturingOfferDto) => o.manualOfferPossible)
        const manufacturer = this.$store.state.manufacturerAddresses.find((addr: FertigerAddressDto) => addr.manufacturerId === manufacturingOffer.manufacturerId);
        return manufacturer.town
    }

    private async requestOffer() {

        try { 
            var orderRequest = new OrderRequestDto();
            const manufacturingOffer = this.$store.state.offer.manufacturingOffers.find((o:ManufacturingOfferDto) => o.manualOfferPossible)
            orderRequest.customerId = 0; // is set in backend
            orderRequest.deliveryAddress = this.$store.state.currentCart.deliveryAddress;
            orderRequest.invoiceAddress = this.$store.state.currentCart.invoiceAddress;
            orderRequest.calculationId = this.$store.state.offer.calculationId;
            orderRequest.rampenabholung = this.$store.state.offer.deliveryChoice === 'rampe'
            orderRequest.kommission = this.$store.state.offer.customKommissionsnummer;
            orderRequest.selectedManufacturerId = manufacturingOffer.manufacturerId;
            orderRequest.additionalOrderConfirmationEmail = this.$store.state.additionalOrderConfirmationEmail;
            orderRequest.dueDate = this.deliveryDate;
            this.$store.commit('setLoading', true);
            await httpClient().post(`offer/requestManualOffer`, orderRequest);
            this.$store.commit('setManualOfferRequested', true);
            this.$store.commit('setOrderSuccessful', true);
            await this.$store.dispatch('deletePendingOrdersInCart')
            await this.$store.dispatch('resetOrderAfterSuccess');
        } catch(ex) {
            console.error(`Fehler bei der Erstellung der Offertanfrage. ${ex}`);
        } finally {
            this.$store.commit('setLoading', false);
        }

    }

    private get allowedDates() {
        const manufacturingOffer = this.$store.state.offer.manufacturingOffers
            .find((mo: any) => mo.manualOfferPossible);
        const dates = [];
        if (manufacturingOffer != undefined) {
            const validFrom = moment().add(25, 'days');
            var validTo = moment().add(2, 'year');
            for (const datum of manufacturingOffer.datesOfDelivery) {
                if (moment(datum).isAfter(validFrom) && moment(datum).isBefore(validTo)) {
                    dates.push(datum);
                }
            }
        }
        return dates;
    }
}

