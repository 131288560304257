var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.articleEditDialog,
            callback: function ($$v) {
              _vm.articleEditDialog = $$v
            },
            expression: "articleEditDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  "Artikel " +
                    _vm._s(_vm.article.articleId) +
                    " - Einstellungen"
                ),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.articleDataValid,
                        callback: function ($$v) {
                          _vm.articleDataValid = $$v
                        },
                        expression: "articleDataValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelname",
                          rules: _vm.requiredStringMax50Rule,
                          disabled: _vm.article.calculating,
                          color: "accent",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.articleName = _vm.articleName.trim()
                          },
                        },
                        model: {
                          value: _vm.articleName,
                          callback: function ($$v) {
                            _vm.articleName = $$v
                          },
                          expression: "articleName",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Artikelnummer",
                          rules: _vm.optionalStringMax50Rule,
                          disabled: _vm.article.calculating,
                          placeholder: "Ihre Artikelnummer",
                          color: "accent",
                          hint: "Sie können jedem Artikel eine eigene Artikelnummer vergeben. Die Nummer erscheint auch auf dem Lieferschein und der Rechnung.",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.articleNumber = _vm.articleNumber.trim()
                          },
                        },
                        model: {
                          value: _vm.articleNumber,
                          callback: function ($$v) {
                            _vm.articleNumber = $$v
                          },
                          expression: "articleNumber",
                        },
                      }),
                      !_vm.article.isAssembly
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.availableWerkstoffe,
                              "item-text": "bezeichnung",
                              "item-value": "blexonId",
                              disabled: _vm.article.calculating,
                              label: "Material",
                              color: "accent",
                            },
                            model: {
                              value: _vm.selectedWerkstoffId,
                              callback: function ($$v) {
                                _vm.selectedWerkstoffId = $$v
                              },
                              expression: "selectedWerkstoffId",
                            },
                          })
                        : _vm._e(),
                      !_vm.article.isAssembly
                        ? _c(
                            "div",
                            [
                              _c(
                                "p",
                                { staticClass: "ma-0 font-weight-bold" },
                                [_vm._v("Entgraten:")]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "11" },
                                    },
                                    [
                                      _vm.article.migrated
                                        ? _c("p", { staticClass: "ma-0" }, [
                                            _vm._v(
                                              " Blexonisieren Sie den Artikel, um die Entgratung zu verändern. "
                                            ),
                                          ])
                                        : !_vm.hasWerkstoffChanged
                                        ? _c("v-select", {
                                            attrs: {
                                              disabled: _vm.loadingEntgratarten,
                                              dense: "",
                                              color: "accent",
                                              "hide-details": "",
                                              placeholder:
                                                "Bitte Entgratart wählen...",
                                              "item-disabled":
                                                _vm.isEntgratartDisabled,
                                              "item-text": "displayText",
                                              "item-value": "entgratart",
                                              items: _vm.availableEntgratarten,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              data.item
                                                                .displayText
                                                            )
                                                          ),
                                                        ]),
                                                        !data.item.available
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                  color: "gray",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .reason
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2366530216
                                            ),
                                            model: {
                                              value: _vm.entgratart,
                                              callback: function ($$v) {
                                                _vm.entgratart = $$v
                                              },
                                              expression: "entgratart",
                                            },
                                          })
                                        : _c("p", { staticClass: "ma-0" }, [
                                            _vm._v(
                                              " Wenn das Material geändert werden soll, muss der Artikel zuerst neu berechnet werden, bevor die Entgratart angepasst werden kann. Speichern Sie dazu die aktuellen Änderungen und warten Sie, bis der Artikel validiert ist. "
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                          },
                                          attrs: {
                                            href: "https://blexon.com/blechteile-entgraten/",
                                            target: "_blank",
                                            title:
                                              "Informationen über das Entgraten von Metallteilen",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { color: "accent" },
                                            },
                                            [_vm._v("mdi-help-circle")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.saveFailedErrorMessage !== ""
                        ? _c("span", { staticClass: "red--text" }, [
                            _c("br"),
                            _vm._v(_vm._s(_vm.saveFailedErrorMessage)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.isUpdatingArticleData },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Schliessen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: {
                        text: "",
                        disabled:
                          !_vm.articleDataValid || _vm.isUpdatingArticleData,
                        title:
                          "Speichert den Artikel mit den angegebenen Daten.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveArticleData()
                        },
                      },
                    },
                    [
                      _vm.isUpdatingArticleData
                        ? _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _vm._v(" Speichern "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }