
import { Component, Vue } from 'vue-property-decorator';
import { UserInfoDto } from '@/dtos/userInfoDto';
import rules from '@/store/rules';
import httpClient from '@/store/httpClient';

@Component({})
export default class UserSettings extends Vue {
	private user: UserInfoDto = new UserInfoDto();
	private lastUser: UserInfoDto = new UserInfoDto();
	private registeredChangedUserEmail: string = '';
	private isValid: boolean = false;
	private isUsernameValid: boolean = false;
	private isLoading: boolean = false;
	private requiredStringMax35Rule = rules.requiredStringMax35Rule;
	private requiredEmailRule = rules.requiredEmailRule;
	private emailChangeWarningdialog: boolean = false;
	private emailChangeRegisteredDialog: boolean = false;
	private emailChangeFailedDialog: boolean = false;
	private changePasswordDialog: boolean = false;
	private newsletterMessage: boolean = false;
	private newUsername: string = '';

    private mounted() {
		this.loadUser();
		this.registeredChangedUserEmail = this.user.username;
    }

	private get hasChanges() {
		return (this.lastUser.firstname !== this.user.firstname
			|| this.lastUser.lastname !== this.user.lastname
			|| this.lastUser.phone !== this.user.phone
			|| this.lastUser.newsletterSubscribed !== this.user.newsletterSubscribed) 
	}

	private get usernameIsDifferent() {
		return this.newUsername !== this.$store.state.customer.user.username
			&& this.newUsername !== this.registeredChangedUserEmail;
	}

	private async loadUser() {
		this.user = this.$store.state.customer.user;
		this.lastUser = JSON.parse(JSON.stringify(this.user));
	}

	private async saveUser() {
		if (this.isValid && !this.isLoading && this.hasChanges) {
			try {
				this.isLoading = true;
				const response = await httpClient().post('Customer/SaveUser', this.user);
				this.$store.commit('updateUserSettings', {
					firstname: this.user.firstname,
					lastname: this.user.lastname,
					phone: this.user.phone,
					newsletterSubscribed: this.user.newsletterSubscribed,
				});
            	this.$store.dispatch('setSnackbarText', 'Einstellungen erfolgreich gespeichert.');
				this.lastUser = JSON.parse(JSON.stringify(this.user));
			} catch(err) {
            	this.$store.dispatch('setSnackbarErrorText', 'Einstellungen konnten nicht gespeichert werden.');
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async saveUsername() {
		if (this.isUsernameValid && !this.isLoading) {
			if (!this.usernameIsDifferent) {
				console.log('New email/username is the same as the current one');
	            this.$store.dispatch('setSnackbarErrorText', 'Der neue Benutzername ist identisch zum jetzigen oder zum bereits geänderten Benutzernamen.');
    	        return;
			}
			try {
				this.isLoading = true;
				const response = await httpClient().post(`Customer/RequestUserNameChange?newEMail=${encodeURI(this.newUsername)}`);
				this.emailChangeWarningdialog = false;
				this.emailChangeRegisteredDialog = true;
				this.registeredChangedUserEmail = this.newUsername;
			} catch(err) {
				this.emailChangeFailedDialog = true;
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async changePassword() {
		if (this.isLoading) return;
		try {
			this.isLoading = true;
			await httpClient().post('Customer/ChangePassword');
			this.changePasswordDialog = false;
			this.$store.dispatch('setSnackbarText', 'E-Mail zur Passwortänderung wurde versendet.');
		} catch(err) {
			this.$store.dispatch('setSnackbarErrorText', 'E-Mail zur Passwortänderung konnte nicht versendet werden.');
		} finally {
			this.isLoading = false;
		}
	}

	private async generateCode() {
		try {
			this.isLoading = true;
			const response = await httpClient().post(`Customer/GenerateAppCode`);
			this.user.appCode = response.data;
		} catch(err) {
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Generieren des App Code. Bitte melden Sie sich bei unserem Helpdesk.');
		} finally {
			this.isLoading = false;
		}
	}

	private async copyToClipboard() {
		if (this.user.appCode != null && this.user.appCode != '') {
			await navigator.clipboard.writeText(this.user.appCode);
			this.$store.dispatch('setSnackbarText', 'App Code erfolgreich in Zwischenablage gespeichert.');
		} else {

		}
	}

}
