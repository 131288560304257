var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "1200", persistent: "" },
      model: {
        value: _vm.articleTreatmentEditDialog,
        callback: function ($$v) {
          _vm.articleTreatmentEditDialog = $$v
        },
        expression: "articleTreatmentEditDialog",
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "ma-5 blexon-button",
          attrs: {
            fab: "",
            small: "",
            fixed: "",
            right: "",
            top: "",
            title: "Dialog schliessen.",
          },
          on: {
            click: function ($event) {
              return _vm.closeDialog()
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      !_vm.isPowderCoatingAvailable
        ? _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Pulverbeschichtung ")]),
              _c("v-card-text", [
                _vm._v(
                  " Das Pulverbeschichten von Artikeln ist in Ihrer Region leider noch nicht verfügbar. "
                ),
              ]),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _vm.isSingleArticle
                ? _c("v-card-title", [
                    _vm._v(
                      " Pulverbeschichtung - Artikel '" +
                        _vm._s(_vm.singleArticle.customerArticleName) +
                        "' (" +
                        _vm._s(_vm.singleArticle.articleId) +
                        ") "
                    ),
                  ])
                : _c("v-card-title", [
                    _vm._v(
                      " Pulverbeschichtung - " +
                        _vm._s(_vm.articles.length) +
                        " Artikel "
                    ),
                  ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      !_vm.showAllPowders
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              staticStyle: { "margin-bottom": "-13px" },
                              attrs: { cols: "9" },
                            },
                            [
                              _vm._v(
                                " Es werden nur Standardfarben ohne Aufpreis angezeigt. "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Alle Farben anzeigen",
                              color: "accent",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.showAllPowders,
                              callback: function ($$v) {
                                _vm.showAllPowders = $$v
                              },
                              expression: "showAllPowders",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "RAL Farbe suchen",
                              color: "accent",
                              "prepend-icon": "mdi-magnify",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.ralFilterTerm = _vm.ralFilterTerm.trim()
                              },
                            },
                            model: {
                              value: _vm.ralFilterTerm,
                              callback: function ($$v) {
                                _vm.ralFilterTerm = $$v
                              },
                              expression: "ralFilterTerm",
                            },
                          }),
                          _c("v-select", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: {
                              items: _vm.powderCoatingStructureOptions,
                              hint: _vm.powderStructureHint,
                              "item-text": "name",
                              "item-value": "value",
                              disabled: _vm.anyArticleCalculating,
                              label: "Struktur",
                              color: "accent",
                            },
                            model: {
                              value: _vm.selectedPowderStructure,
                              callback: function ($$v) {
                                _vm.selectedPowderStructure = $$v
                              },
                              expression: "selectedPowderStructure",
                            },
                          }),
                          _c("v-select", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: {
                              items: _vm.powderCoatingFinishOptions,
                              "item-text": "name",
                              "item-value": "value",
                              disabled: _vm.anyArticleCalculating,
                              label: "Finish",
                              color: "accent",
                            },
                            model: {
                              value: _vm.selectedPowderFinish,
                              callback: function ($$v) {
                                _vm.selectedPowderFinish = $$v
                              },
                              expression: "selectedPowderFinish",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "9" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "190px",
                              "overflow-y": "auto",
                              "overflow-x": "hidden",
                              border: "2px solid DarkGray",
                              "border-radius": "5px",
                              padding: "4px",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  wrap: "",
                                  justify: "center",
                                  align: "center",
                                },
                              },
                              _vm._l(
                                _vm.filteredSurfaceColors,
                                function (color) {
                                  return _c(
                                    "div",
                                    {
                                      key: color.id,
                                      staticStyle: { "line-height": "0em" },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "rgba(0, 0, 0, 1)",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        {
                                                          class:
                                                            _vm.selectedPowderColorId ==
                                                            color.id
                                                              ? "selectedColorSquare"
                                                              : "surfaceColorSquare",
                                                          style: `background-color:${color.hexCode}`,
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.selectedPowderColorId =
                                                                color.id
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("div", {
                                                          class:
                                                            _vm.getColorClass(
                                                              color.id
                                                            ),
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" " + _vm._s(color.name)),
                                          _c("br"),
                                          _vm._v(
                                            " RAL " +
                                              _vm._s(color.ralCode) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("h4", { staticStyle: { "margin-top": "12px" } }, [
                    _vm._v("Pulverauswahl:"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { wrap: "", align: "start" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "7" } },
                        [
                          _vm.filteredPowders.length > 0 &&
                          _vm.selectedPowderColorId >= 0
                            ? _c(
                                "v-radio-group",
                                {
                                  staticClass: "ma-0",
                                  model: {
                                    value: _vm.selectedPowderId,
                                    callback: function ($$v) {
                                      _vm.selectedPowderId = $$v
                                    },
                                    expression: "selectedPowderId",
                                  },
                                },
                                _vm._l(_vm.filteredPowders, function (powder) {
                                  return _c("v-radio", {
                                    key: powder.id,
                                    attrs: {
                                      value: powder.id,
                                      color: "accent",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("span", {
                                                style: {
                                                  display: "inline-block",
                                                  width: "20px",
                                                  height: "20px",
                                                  background:
                                                    powder.surfaceColor
                                                      .hexCode + "ff",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                    "vertical-align": "top",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " RAL " +
                                                      _vm._s(
                                                        powder.surfaceColor
                                                          .ralCode
                                                      ) +
                                                      " (" +
                                                      _vm._s(
                                                        powder.surfaceColor.name
                                                      ) +
                                                      ") - " +
                                                      _vm._s(
                                                        _vm.getStructureText(
                                                          powder.structure
                                                        )
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.getFinishText(
                                                          powder.finish
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.isStandardPowder(
                                                          powder.setupCost
                                                        )
                                                          ? " (Standard ohne Aufpreis)"
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "open-delay": "200",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            !_vm.isStandardPowder(
                                                              powder.setupCost
                                                            )
                                                              ? _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "pl-2",
                                                                      attrs: {
                                                                        color:
                                                                          "accent",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-diamond-stone "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "ma-0" },
                                                    [
                                                      _vm._v(
                                                        " Dieses Pulver ist nicht Teil des Standard-Sortiments und muss bestellt werden."
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " Ein einmaliger Aufpreis von "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "round2Decimal"
                                                            )(powder.setupCost)
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " wird angewandt. Bei mehreren Positionen in dieser Farbe, wird der Aufpreis nur einmal berechnet, indem er beim Auftragsrabatt berücksichtigt wird."
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        " Bitte beachten Sie, dass dies auch eine Verlängerung der Lieferzeiten zur Folge haben kann. "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "Keine Pulver mit aktuellem Filter verfügbar"
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "5" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.articleFaceOptions,
                              "item-text": "name",
                              "item-value": "value",
                              disabled: _vm.anyArticleCalculating,
                              label: "Fläche",
                              color: "accent",
                            },
                            model: {
                              value: _vm.selectedPowderFace,
                              callback: function ($$v) {
                                _vm.selectedPowderFace = $$v
                              },
                              expression: "selectedPowderFace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pb-0 text-center",
                          attrs: { cols: "1" },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "accent", large: "" } },
                            [_vm._v("mdi-alert")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pb-0", attrs: { cols: "11" } },
                        [
                          _vm._v(" Bei der Pulverbeschichtung muss mit einer "),
                          _c("b", [_vm._v("Schichtdicke von ca. 0.1mm")]),
                          _vm._v(
                            " gerechnet werden. Konstruieren Sie allfällige Bohrungen und Ausschnitte entsprechend grösser. "
                          ),
                          _c("b", [_vm._v("Gewinde")]),
                          _vm._v(
                            " werden vor dem Beschichten abgedeckt und müssen nicht angepasst werden. "
                          ),
                          _c("b", [_vm._v("Senkungen")]),
                          _vm._v(
                            " werden nicht abgedeckt, jedoch wird die Schichtdicke bei der Fertigung kompensiert. "
                          ),
                          _vm.selectedPowderFace != 2
                            ? _c("span", [
                                _vm._v(
                                  " Im Weiteren muss berücksichtigt werden, dass bei einseitiger Beschichtung die gegenüberliegende Seite nicht abgedeckt wird und somit mit einem "
                                ),
                                _c("b", [_vm._v("Sprühnebel")]),
                                _vm._v(
                                  " auf dieser Seite gerechnet werden muss. "
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("b", [_vm._v("Achtung:")]),
                          _vm._v(" Die Pulverbeschichtung allein ist "),
                          _c("b", [_vm._v("kein Korrosionsschutz")]),
                          _vm._v(". Pulverbeschichtete "),
                          _c("b", [_vm._v("Stahlteile")]),
                          _vm._v(" sind deshalb "),
                          _c("b", [
                            _vm._v(
                              "nicht für Aussenanwendung oder Orte mit hoher Luftfeuchtigkeit"
                            ),
                          ]),
                          _vm._v(
                            " geeignet. Pulverbeschichtete Teile aus Edelstahl oder Aluminium sind für Aussenanwendung oder Orte mit hoher Luftfeuchtigkeit geeignet, sofern keine extremen Umgebungsbedingungen herrschen. Bei Fragen und Unklarheiten können Sie sich gerne bei unserem Helpdesk melden. "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.isUpdatingArticleData },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Schliessen ")]
                  ),
                  _vm.anyArticlesPowdercoated
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            text: "",
                            disabled: _vm.isUpdatingArticleData,
                            title: _vm.isSingleArticle
                              ? "Entfernt die Pulverbeschichtung vom Artikel."
                              : "Entfernt die Pulverbeschichtung von allen Artikeln.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteTreatments()
                            },
                          },
                        },
                        [
                          _vm.isUpdatingArticleData
                            ? _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: { size: "15", indeterminate: "" },
                              })
                            : _vm._e(),
                          _vm._v(" Entfernen "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: {
                        text: "",
                        disabled:
                          !_vm.powderTreatmentValid ||
                          _vm.isUpdatingArticleData,
                        title: _vm.isSingleArticle
                          ? "Speichert die Pulverbeschichtung."
                          : "Speichert die Pulverbeschichtung für alle Artikel.",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveTreatments()
                        },
                      },
                    },
                    [
                      _vm.isUpdatingArticleData
                        ? _c("v-progress-circular", {
                            staticClass: "mr-2",
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _vm._v(" Speichern "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }