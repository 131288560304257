
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import rules from '@/store/rules'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { ArticleState } from '@/dtos/articleState';
import { CartItem } from '@/dtos/cartItem';
import { PriceDto } from '@/dtos/priceDto';
import { PriceState } from '@/dtos/priceState';
import { TokenResponseDto } from '@/dtos/tokenResponseDto';
import ArticlePriceInfoDialog from '@/views/Dialogs/ArticlePriceInfoDialog.vue'
import ArticleEditDialog from '@/views/Dialogs/ArticleEditDialog.vue'
import ArticleDeleteWarningDialog from '@/views/Dialogs/ArticleDeleteWarningDialog.vue'
import SendToHelpdeskDialog from '@/views/Dialogs/SendToHelpdeskDialog.vue'
import TooManyCalculatingDialog from '@/views/Dialogs/TooManyCalculatingDialog.vue'
import ArticleTreatmentEditDialog from '@/views/Dialogs/ArticleTreatmentEditDialog.vue'
import PowderCoatingNotEditableDialog from '@/views/Dialogs/PowderCoatingNotEditableDialog.vue'
import CertificateHelperDialog from '@/views/Dialogs/CertificateHelperDialog.vue'
import { PowderFinish, PowderStructure, ArticleFaceType } from '@/dtos/treatmentTypes';
import { PriceListDto } from '@/dtos/priceListDto';
import PriceModelDefault, { PriceModel } from "@/dtos/priceModel"
import { getEntgratartText } from '@/dtos/entgratart';
import { CheckArticleReason } from '@/dtos/checkArticleReason';
import { HintId } from '@/dtos/hintId';
import SpeechBubbleFloat from '../Dialogs/SpeechBubbleFloat.vue';
import { CertificateTypes } from '@/dtos/certificateTypes';

@Component({
    components: {
		ArticlePriceInfoDialog,
        ArticleEditDialog,
        ArticleDeleteWarningDialog,
        SendToHelpdeskDialog,
        TooManyCalculatingDialog,
        ArticleTreatmentEditDialog,
        PowderCoatingNotEditableDialog,
        SpeechBubbleFloat,
        CertificateHelperDialog
    }
})
export default class ArticleTile extends Vue {
    @Prop()
    private articleId!: number;
    private cadDialog: boolean = false;
    private currentWindowHeight: string = '100px';
    private amountToAddToCart: number = 1;
    private preisInformationenDialog: boolean = false;
    private articleEditDialog: boolean = false;
    private articleDeleteWarningDialog: boolean = false;
    private tooManyCalculatingDialog: boolean = false;
    private showHelpdeskDialogue: boolean = false;
    private isValidAmount: boolean = true;
    private deleteOverlay: boolean = false;
    private isUpdatingArticleData: boolean = false;
    private allowedCartAmountRule = rules.allowedCartAmountRule;
    private articleToken: TokenResponseDto | null = null;
    private articleCartNames: string[] = [];
    private articleTreatmentEditDialog: boolean = false;
    private powderCoatingNotEditableDialog: boolean = false;
    private certificateHelperDialog: boolean = false;
	private priceModels =  PriceModelDefault.allPriceModels;
    private getEntgratartText = getEntgratartText;
    private reasonToSendArticleToHelpdesk: CheckArticleReason = CheckArticleReason.ModelError;
    private priceListHintTitle = 'Den finalen Preis inkl. Mengenrabatt sehen Sie im Warenkorb.';
    private priceListHintText = 'Mehrere Artikel mit gleichen Bearbeitungen oder gleichem Material führen zu einem Preisnachlass. Um den definitiven Preis zu kalkulieren, stellen Sie bitte einen Warenkorb zusammen und lassen Sie sich innert Sekunden ein Angebot inkl. Liefertermin berechnen.';

    private mounted() {        
        this.setWindowHeight();
        window.addEventListener('resize', event => {
            this.setWindowHeight();
        });
        if (this.article.quantityInAssembly != null) {
            this.amountToAddToCart = this.article.quantityInAssembly;
        }
    }

    /**
     * Gets a value if the user can change the settings for powder coating.
     * This does NOT define if he can see it or not.
     */
    private get canChangePowderCoating() : boolean {
        return this.$store.getters.canArticleChangePowderCoating(this.article);
    }

    private get certificates() : string[] {
        const material = this.$store.getters.getMaterial(this.article.materialId)
        if (material === undefined) {
            return []
        }
        if (material.certificateTypes !== CertificateTypes.None) {
            return ['3.1']
        } else {
            return []
        }
    }
    private get certificate() : boolean {
        return this.article.certificateTypes > 0
    }
    private set certificate(value: boolean) {
        if (value) {
            this.article.certificateTypes = CertificateTypes.ThreeDotOne
        } else {
            this.article.certificateTypes = CertificateTypes.None
        }
        httpClient().post(`article/SetCertificate?articleId=${this.article.articleId}&certificateTypes=${this.article.certificateTypes}`)
            .then(() => {this.$store.dispatch('updateArticleById', this.article.articleId)});
    }

    private get hasFeatures() {
        return this.article.powderCoatingTreatment != null || 
            this.article.featureDtos.length > 0 || 
            this.article.hasEngravings ||
            this.article.weldlines.length > 0;
    }

    private get article() : CustomerArticleDto{
        return this.$store.state.articles
            .find((a: CustomerArticleDto) => a.articleId == this.articleId);
    }

    private get region(){
        return this.$store.getters.customerDefaultDeliveryAddress.country;
    }
   
    private get articleCadViewUrl() {
        const email = encodeURI(this.$store.state.customer.user.username);
        const url = process.env.VUE_APP_cadClientUrl + `artikel?id=${this.article.articleId}` +
            `&token=${this.articleToken?.token}&readonly=${this.articleToken?.readonly}` +
            `&defaultEMail=${email}&locked=${this.article.locked}`;
        return url;
    }

    private get isCalculating() {
        return this.article.calculating;
    }

    private get isCartButtonRowVisible() {
        if (this.article.calculating) return false;
        if (this.article.migrated) return false;
        if (this.article.locked) return false;
        if (this.article.state === ArticleState.OK 
            || this.article.state === ArticleState.Warning) {
            if (this.article.priceState !== PriceState.Failed) {
                return true;
            }
        }
        return false;
    }
    private get isCartButtonDisabled() {
        if (!this.isValidAmount || !this.article.canBeProduced ||
            (this.article.state !== ArticleState.OK && this.article.state !== ArticleState.Warning) ||
            this.article.priceState !== PriceState.Ok) {
            return true;
        }
        // Bei Gravuren und Pulverbeschichtung ist der Button disabled. Die Unterseite wäre aber möglich. Dort ist keine Gravur.
        if (this.article.hasEngravings && this.article.powderCoatingTreatment != null && this.article.powderCoatingTreatment.treatmentFace != ArticleFaceType.Back) {
            return true;
        }
        return false;
    }
    private get isCadButtonVisible() {
        if (this.article.calculating || this.article.migrated) {
            return false;
        } 
        return true;
    }
    private get isCadButtonDisabled() {
        return false;   // Wenn er sichtbar ist, ist er auch enabled.
                        // Mehrere Benutzer können aufmachen.
    }
    private get isHelpdeskButtonVisible() {
        if (this.article.migrated) {
            return false;
        }
        if (this.article.calculating) {
            return false;
        }
        if (this.article.state === ArticleState.Error
            || this.article.locked){
            if (this.article.priceState !== PriceState.Calculating){
                return true;
            }
        }
        if (this.article.state === ArticleState.OK
            || this.article.state === ArticleState.Warning){
            if (this.article.priceState === PriceState.Failed){
                return true;
            }
        }        
        return false;
    }
    private get isHelpdeskButtonDisabled() {
        if (this.article.calculating) return false;
        if (this.article.locked){
            if (this.article.priceState !== PriceState.Calculating){
                return true;
            }
        }
        return false;
    }

    private get isArticleLockedAndNotAdmin() {
        return this.article.locked
            && (!this.$store.state.customer.user.isAdmin
            || !this.isImpersonated);
    }

    private get isArticleLockedAndAdmin() {
        return this.article.locked
            && (this.$store.state.customer.user.isAdmin
            || this.isImpersonated);
    }

    private get isArticleUnlockedAndAdmin() {
        return this.article.locked == false
            && (this.$store.state.customer.user.isAdmin
            || this.isImpersonated);
    }

    private get isStatusChipLinkToCad() {
        return (this.article.state === ArticleState.Warning
            || this.article.state === ArticleState.Error
            || this.article.state === ArticleState.Info)
            && !this.article.migrated
    }

    private get isImpersonated() {
        var oldToken = window.localStorage.getItem(`oidc.user:${process.env.VUE_APP_authorityUrl}:BlexonKundenportalClient_`);
        if (oldToken != null) {
            return true;
        }
        return false;
    }

    private get showPriceError() {
        return this.article.priceState == PriceState.NotCalculated
            || this.article.priceState == PriceState.Invalid
            || this.article.priceState == PriceState.Failed;
    }

    private get waehrung() {
        return this.$store.getters.waehrung;
    }

    private get articleHasPowderCoating() {
        return this.article.powderCoatingTreatment != null;
    }

    private get powderCoatingText() {
        if (!this.articleHasPowderCoating) return "Nein";
        return `RAL\u00A0${this.article.powderCoatingTreatment?.powder.surfaceColor.ralCode}`;
    }

    private get isPowderCoatingAvailable() {
        return this.$store.state.isPowderCoatingAvailable;
    }

    private setUpdating(value: boolean) {
        this.isUpdatingArticleData = value;
    }

    private setDeleteOverlay(value: boolean) {
        this.deleteOverlay = value;
    }

    private getThumbnailUrl(id: string) {
        return this.$store.getters.getThumbnailUrl(this.article.articleId, this.article.lastChange);
    }

    private get sortedPrices() {
        if (this.article.priceLists.length <= 0) {
            return [];
        } else {
            let sortedPrices: PriceDto[] = Array.from(this.article.priceLists[0].prices);
            return sortedPrices.sort((a:any, b:any) => a.quantity - b.quantity);
        }
    }

    private getModelPrice(quantity: number, model: PriceModel): string {
        let list = this.article.priceLists.filter((pl:PriceListDto) => pl.priceModel == model)[0];
        return list.prices.filter((p:PriceDto) => p.quantity === quantity)[0].price.toFixed(2);
    }

    private get isArticleSelected() {
        return this.$store.state.selectedArticleIds.includes(this.articleId);
    }
    private set isArticleSelected(newValue: boolean) {
        // the new value is irrelevant. We just toggle the checkbox.
        this.$store.commit('addOrRemoveSelectedArticleId', this.articleId);
    }

    private get availablePriceModels() {
		let availablePriceModels = this.priceModels.filter((p:any) => 
			this.article.priceLists.some((pl:PriceListDto) => pl.priceModel == p.value));
		return availablePriceModels;
	}

    private get isMultiSelectionMode() {
        return this.$store.state.selectedArticleIds.length > 0;
    }

    private async openCadDialog() {
        try {
            if (this.article.calculating || this.article.migrated) {
                return;
            }
            const response = await httpClient().post(`article/GetArticleToken?articleId=${this.articleId}`);
            this.articleToken = response.data;
            if (this.articleToken != null) {
                window.addEventListener('message', this.handleArticleChangedInCad);
                this.cadDialog = true;
            }
        } catch(err) {
            console.log(`Für den Artikel konnte kein Token geladen werden. Error: ${err}`);
        }
    }

    private openArticleEditDialog() {
        if (!this.isArticleLockedAndNotAdmin && !this.isCalculating) {
            this.articleEditDialog = true;
        }
    }

    /**
     * This event handler is called, when the cad article in the iframe has changed.
     */
    private handleArticleChangedInCad (event: any) {
        if (event.origin == 'https://cad.test.blexon.com' || 
            event.origin == 'https://cad.blexon.com' || 
            event.origin == 'http://localhost:8180') {
                
            if (event.data == `article-${this.articleId}-closed`) {
                this.closeCadDialog(true);
            } else if (event.data == `article-${this.articleId}-saved`) {
                this.closeCadDialog(false);
            }
        }
    }

    private async closeCadDialog(deleteToken: boolean) {
        this.cadDialog = false;
        window.removeEventListener('message', this.handleArticleChangedInCad);
        // delete token only when the article was not saved.
        // Wenn der Artikel gespeichert wurde, wird das Token nach der Validierung gelöscht.
        if (deleteToken) {
            await httpClient().post(`article/DeleteToken?token=${this.articleToken?.token}`);
        }
        this.articleToken = null;
    }

    private async recalculatePrice(){
        try{
            this.$store.commit('updatePriceStateOfArticle', { articleId: this.articleId, priceState: PriceState.Calculating });
            await httpClient().get(`article/UpdatePriceOfArticle?articleId=${this.articleId}`);
            // Article will be updated via SignalR
        } catch(err) {
            console.error(`Failed to update prices of article ${this.articleId}. Error: ${err}`);
            this.$store.commit('updatePriceStateOfArticle', { articleId: this.articleId, priceState: PriceState.Failed });
        }
    }

    private addToCart() {
        let newCartItem = new CartItem();
        newCartItem.articleId = this.articleId;
        newCartItem.quantity = parseInt(this.amountToAddToCart as any);
        this.$store.dispatch("addToCart", newCartItem);
        this.$store.dispatch(
            'setSnackbarArticleText', 
            { 
                text: `${this.amountToAddToCart} Stück in den Warenkorb gelegt.`, 
                articleId: this.articleId 
            }
        );
        this.amountToAddToCart = 1;
    }

    private async checkDeleteArticle() {        
        try {
            this.articleCartNames = [];
            const response = await httpClient().get(`Cart/GetCartsOfArticle?articleId=${this.articleId}`);
            if (response.data.length > 0) {
                this.articleCartNames = response.data.sort();
            }
            this.articleDeleteWarningDialog = true;
        } catch(err) {
            console.error(`Could not retrieve carts of article ${this.articleId}.`);
            this.$store.dispatch('setSnackbarErrorText', 'Aktuell kann nicht überprüft werden, ob der Artikel in einer Stückliste verwendet wird.');
        }
    }

    private async lockArticle() {
        try {
            await httpClient().post(`article/LockArticle?articleId=${this.articleId}`);
            this.$store.commit('setArticleStateToLocked', this.articleId);
        } catch(err) {
            console.log(`Der Artikel konnte nicht gesperrt werden. Error: ${err}`);
        }
    }

    private async unlockArticle() {
        try {
            await httpClient().post(`article/UnlockArticle?articleId=${this.articleId}`);
            this.$store.commit('setArticleStateToUnlocked', this.articleId);
        } catch(err) {
            console.log(`Der Artikel konnte nicht entsperrt werden. Error: ${err}`);
        }
    }

    private async revalidate() {
        try {
            this.article.calculating = true;
            const response = await httpClient().post(`article/revalidate?articleId=${this.articleId}`);
            if (response.data === false) {
                // zu viele von diesem Kunden sind am blexonisieren.
                this.tooManyCalculatingDialog = true;
            }
        } catch(err) {
            console.log(`Fehler beim erneuten Validieren des Artikels. Error: ${err}`);
        }
    }

    private setWindowHeight () {
        // 165 besteht aus...
        // - Margins der Dialogbox (variieren etwas je nach Aspect Ratio)
        // - Buttons für Speichern / Schliessen im Dialogfenster
        // - Sicherheitsmarge, sodass sicher keine Scrollbar im Dialog erscheint
        const currentHeight = window.innerHeight - 65
        this.currentWindowHeight = `${currentHeight}px`
    }

    private getFinishText(value: number) {
        switch (value) {
            case PowderFinish.Matt: {
                return "Matt";
            }
            case PowderFinish.Glossy: {
                return "Glanz";
            }
            default: {
                return "Nicht verfügbar";
            }
        }
    }

    private getStructureText(value: number) {
        switch (value) {
            case PowderStructure.Smooth: {
                return "Glatt";
            }
            case PowderStructure.Fine: {
                return "Fein";
            }
            case PowderStructure.Rough: {
                return "Grob";
            }
            default: {
                return "Nicht verfügbar";
            }
        }
    }

    private get articleStateText() {
        if(this.article.locked) {
            return 'In Überprüfung';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'OK';
            }
            case ArticleState.Warning: { 
                return 'Warnung';
            }
            case ArticleState.Error: { 
                return 'Fehler';
            }
            case ArticleState.New: { 
                return 'Neu';
            }
            case ArticleState.Info: { 
                return 'Aktion erforderlich';
            } 
            default: { 
                return 'Unklar';
            } 
        }     
    }
    private get articleStateIcon() {
        if(this.article.locked) {
            return 'mdi-lock';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'mdi-check';
            } 
            case ArticleState.Warning: { 
                return 'mdi-exclamation';
            } 
            case ArticleState.Error: { 
                return 'mdi-close';
            } 
            case ArticleState.New: { 
                return 'mdi-new-box';
            }
            default: { 
                return 'mdi-help';
            } 
        }     
    }
    private get articleStateColor() {
        if(this.article.locked) {
            return '#CFD8DC';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return '#C8E6C9';
            }
            case ArticleState.Warning: { 
                return '#FFF9C4';
            }
            case ArticleState.Error: { 
                return '#FFCDD2';
            }
            case ArticleState.Info: { 
                return '#efbb20';
            }
            default: {
                return 'blue';
            }
        }
    }    
    private get priceStateColor() {
        return this.showPriceError ? '#FFCDD2' : 'white';
    }

    private getZusatzbezeichnungOberseite() {
        if (this.article.materialname != null) {
            if (this.article.materialname.includes('geschliffen')) {
                return ' / Schliff / Folie'
            }
            if (this.article.materialname.includes('eloxiert')) {
                return ' / Folie'
            }
        }
        return ''
    }

    private getZusatzbezeichnungUnterseite() {
        if (this.article.materialname != null) {
            if (this.article.materialname.includes('Riffel')) {
                return ' / Riffeln'
            }
        }
        return ''
    }

    private get priceListHintId(): number {
        return HintId.ShopPriceList;
    }

    /**
     * Creates a text to show at the user interface to inform the user
     * about the weldline features.
     */
    private getWeldlineString() : string {
        var text = '';
        if (this.article.weldlines.length === 1) {
            text = '1 Schweißnaht';
        } else if (this.article.weldlines.length > 1)  {
            text = `${this.article.weldlines.length} Schweißnähte`
        }
        const numberOfCorners = this.article.weldlines.reduce( (a, b) => {
            return a + b.corners.length;
        }, 0);
        if (numberOfCorners === 1) {
            text += ` | 1 Ecke`
        } else if (numberOfCorners > 1) {
            text += ` | ${numberOfCorners} Ecken`
        }
        if (this.article.weldlines.some(w => w.clean)) {
            text += ` | verputzt`
        } else {
            text += ` | unverputzt`
        }
        return text;
    }

}
