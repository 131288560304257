// define a new console
var console = (function(oldCons){
    return {
        log: function(text){
            post(text, 'Debug');
        },
        info: function (text) {
            post(text, 'Information');
        },
        warn: function (text) {
            post(text, 'Warning');
        },
        error: function (text) {
            post(text, 'Error');
        }
    };
}(window.console));

//Then redefine the old console
// Umleitung des logs geschieht nur auf den Servern
if (process.env.VUE_APP_MODE !== 'DEV') {
    window.console = console;
}

window.onerror = function (message, file, line, col, error) {
    console.error(message);
    return false;
};
window.addEventListener("error", function (e) {
    console.error(message);
    return false;
});
window.addEventListener('unhandledrejection', function (e) {
    console.error(message);
});

const package = JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B%7D'));

const post = (message, level) => {

    const item = localStorage.getItem(`oidc.user:${process.env.VUE_APP_authorityUrl}:BlexonKundenportalClient`);
    var user = undefined;
    if (item !== undefined) {
        var object = JSON.parse(item);
        if (object != null) {
            user = object.profile.name
        }
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", `${process.env.VUE_APP_eventUrl}api/log/postClientLog`, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({
        message: message,
        user: user,
        level: level,
        system: 'Shop - V' + package.version
    }));
}

