var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-container",
              {
                staticClass: "addressTile",
                class: { "elevation-10": hover },
                staticStyle: { position: "relative" },
                style: _vm.address.isValid ? "" : "border:2px solid red;",
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0 mb-3 text-h6",
                    attrs: { align: "center" },
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "accent" } },
                      [_vm._v("mdi-map-marker")]
                    ),
                    _vm.hasName
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.address.givenName) +
                              " " +
                              _vm._s(_vm.address.surname) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(" " + _vm._s(_vm.address.companyName) + " "),
                        ]),
                  ],
                  1
                ),
                _vm.hasName && _vm.address.companyName !== ""
                  ? _c("v-row", { staticClass: "ma-0 mb-2" }, [
                      _vm._v(" " + _vm._s(_vm.address.companyName) + " "),
                    ])
                  : _vm._e(),
                _c("v-row", { staticClass: "ma-0" }, [
                  _vm._v(" " + _vm._s(_vm.address.street) + " "),
                ]),
                _c("v-row", { staticClass: "ma-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.address.zip) +
                      " " +
                      _vm._s(_vm.address.town) +
                      " "
                  ),
                ]),
                _c("v-row", { staticClass: "ma-0 mb-2" }, [
                  _vm._v(" " + _vm._s(_vm.address.country) + " "),
                ]),
                _vm.address.type == _vm.deliveryType
                  ? _c("v-row", { staticClass: "ma-0 mb-2" }, [
                      _vm._v(
                        " Lieferungsavisierung: " +
                          _vm._s(_vm.address.phone) +
                          " "
                      ),
                    ])
                  : _c("v-row", { staticClass: "ma-0 mb-2" }, [
                      _vm._v(" " + _vm._s(_vm.address.eMail) + " "),
                    ]),
                _vm.address.type == _vm.invoiceType
                  ? _c("v-row", { staticClass: "ma-0" }, [
                      _c("span", [
                        _vm._v("Rechnung wird per "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.address.isBilledDigitally ? "E-Mail" : "Post"
                            )
                          ),
                        ]),
                        _vm._v(" versendet"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isOrder
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "blexon-button",
                        attrs: {
                          disabled: !_vm.address.isValid,
                          small: "",
                          absolute: "",
                          bottom: "",
                          left: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setAddressForOrder()
                          },
                        },
                      },
                      [_vm._v(" Übernehmen ")]
                    )
                  : _vm._e(),
                (_vm.isUserPreferred || hover) && _vm.isNotOrder
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-delay": "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mt-8 black--text",
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          color: _vm.isUserPreferred
                                            ? "accent"
                                            : "",
                                          absolute: "",
                                          top: "",
                                          right: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            !_vm.isUserPreferred
                                              ? _vm.changeUserPreferred()
                                              : null
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-star"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm.isUserPreferred
                          ? _c("span", [
                              _vm._v(
                                "Diese Adresse ist bereits als Favorit markiert und wird bei Bestellungen für Sie vorgewählt."
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "Als Favorit wählen. Diese Adresse wird bei Bestellungen für Sie vorgewählt."
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
                hover
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-delay": "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mb-9 blexon-button",
                                        attrs: {
                                          fab: "",
                                          small: "",
                                          disabled: !_vm.canEditAddress,
                                          absolute: "",
                                          bottom: "",
                                          right: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openEditDialog()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        !_vm.canEditAddress
                          ? _c("span", [
                              _vm._v(
                                "Berechtigung zum Bearbeiten der Adresse nicht vorhanden"
                              ),
                            ])
                          : _c("span", [_vm._v("Adresse bearbeiten")]),
                      ]
                    )
                  : _vm._e(),
                hover && _vm.isNotOrder
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "", "open-delay": "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mb-9 mr-12 blexon-button",
                                        attrs: {
                                          fab: "",
                                          small: "",
                                          disabled:
                                            !_vm.canEditAddress ||
                                            _vm.isLastAddress,
                                          absolute: "",
                                          bottom: "",
                                          right: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.deleteAddressDialog = true
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        !_vm.canEditAddress
                          ? _c("span", [
                              _vm._v(
                                "Berechtigung zum Löschen der Adresse nicht vorhanden"
                              ),
                            ])
                          : _c("span", [_vm._v("Adresse löschen")]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", width: "500" },
                    model: {
                      value: _vm.editAddressDialog,
                      callback: function ($$v) {
                        _vm.editAddressDialog = $$v
                      },
                      expression: "editAddressDialog",
                    },
                  },
                  [
                    _c("change-address", {
                      attrs: {
                        address: _vm.currentEditAddress,
                        addressType: _vm.type,
                        newAddress: false,
                      },
                      on: { closedDialog: _vm.closeEditDialogAndReload },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { width: "500" },
                    model: {
                      value: _vm.deleteAddressDialog,
                      callback: function ($$v) {
                        _vm.deleteAddressDialog = $$v
                      },
                      expression: "deleteAddressDialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [_vm._v("Adresse löschen")]),
                        _c("v-card-text", [
                          _vm._v(
                            " Sind Sie sicher, dass Sie die Adresse löschen wollen? "
                          ),
                        ]),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.deleteAddressDialog = false
                                  },
                                },
                              },
                              [_vm._v(" Abbrechen ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "blexon-button",
                                attrs: {
                                  text: "",
                                  title: "Löscht die Adresse endgültig.",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteAddress()
                                  },
                                },
                              },
                              [_vm._v(" Löschen ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }