
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import rules from '@/store/rules'
import httpClient from '@/store/httpClient';
import Cart from '@/views/Cart/Cart.vue';
import SummaryComponent from '@/views/Checkout/Summary.vue';
import DeliveryComponent from '@/views/Checkout/Delivery.vue';
import OfferInformation from '@/views/Checkout/OfferInformation.vue';
import AddressTile from '@/views/Settings/AddressTile.vue';
import AddressesOverview from '@/views/Settings/AddressesOverview.vue'
import { CartItem } from '@/dtos/cartItem';
import { AddressType } from '@/dtos/addressType';
import { OfferDto } from '@/dtos/offerDto';
import { ErpAddressType } from '@/dtos/erpAddressType';
import { AddressDto } from '@/dtos/addressDto';
import { OfferFailReason } from '@/dtos/offerFailReason';

@Component({
	components: {
		Cart,
		SummaryComponent,
		DeliveryComponent,
		OfferInformation,
		AddressTile,
		AddressesOverview,
	}
})
export default class Checkout extends Vue {
	private stepCount: number = 1;
    private optionalStringMax35Rule: any = rules.optionalStringMax35Rule;
	private optionalEmailRule: any = rules.optionalEmailRule;
	private orderDeliveryType: AddressType = AddressType.OrderDelivery;
	private orderInvoiceType: AddressType = AddressType.OrderInvoice;
	private addressPickerDialog: boolean = false;
	private currentAddressPickerType: ErpAddressType = ErpAddressType.Delivery;
	private deliveryAddress: AddressDto = new AddressDto();
	private invoiceAddress: AddressDto = new AddressDto();
	private deliveryAddressKey: number = 1;
	private invoiceAddressKey: number = -1;
	private isValidForm: boolean = false;

	private mounted() {
		this.refreshAddresses();
	}

	beforeRouteLeave(to: any, from: any, next: any) {
		this.$store.commit('setOrderSuccessful', false);
		next();
	}

	private get currentOffer() {
		return this.$store.state.offer;
	}

	private get offerFailed() {
		return this.$store.state.offerFailed;
	}

	private get isCartValid() {
		return this.$store.getters.isCartValid;
	}

	private get hasItemsInCart() {
        if (this.$store.state.currentCart === null) {
            return false;
        }
        return this.$store.state.currentCart.cartItems.length > 0;
    }

	@Watch('refreshCartAddressesToggle')
	private refreshAddressesToggle() {
		if(this.refreshCartAddressesToggle) {
			this.refreshAddresses();
			this.refreshCartAddressesToggle = false;
		}
	}

	private get refreshCartAddressesToggle() {
		return this.$store.state.refreshCartAddresses;
	}
	private set refreshCartAddressesToggle(value: boolean) {
		this.$store.commit("setRefreshCardAddressesToggle", value);
	}
	
	private get offerDecrementTimerId() {
		return this.$store.state.offerDecrementTimerId;
	}

	@Watch('currentOffer')
	private resetTimerInterval() {
		if (this.currentOffer === null) {
			window.clearInterval(this.offerDecrementTimerId);
		}
	}
	private get email() {
		return this.invoiceAddress.eMail;
    }
	private get isBilledDigitally() {
		return this.invoiceAddress.isBilledDigitally;
    }
	private get addressString() {
		return this.invoiceAddress.street + ' / ' + this.invoiceAddress.town;
	}
    private get customKommissionsnummer(){
        return this.$store.state.offer.customKommissionsnummer;
    }
    private set customKommissionsnummer(value: string){
        this.$store.commit('setCustomKommissionsnummer', value);
    }
	private get currentOfferCalculating() {
		return this.$store.state.currentOfferCalculating;
	}

	private get isValidManufacturerSelected() {
		return this.$store.state.rampenabholungSelectionValid;
	}

	private get isAdditionalEmailSelected() {
		return this.$store.state.isAdditionalEmailSelected;
	}
	private set isAdditionalEmailSelected(value: boolean) {
		this.$store.commit('setIsAdditionalEmailSelected', value);
	}
	private get additionalOrderConfirmationEmail() {
		return this.$store.state.additionalOrderConfirmationEmail;
	}
	private set additionalOrderConfirmationEmail(value: string) {
		this.$store.commit('setAdditionalOrderConfirmationEmail', value);
	}
	private onIsAdditionalEmailSelectedChanged() {
		this.additionalOrderConfirmationEmail = this.isAdditionalEmailSelected 
			? this.$store.state.customer.defaultAdditionalOrderConfirmationEmail
			: '';
	}

	private get userEmailAddress() {
		if (this.$store.state.oidc !== undefined) {
			return this.$store.state.oidc.user.profile.name;
		}
		return "";
	}

	private get areAddressesValid() {
		return this.deliveryAddress.isValid && this.invoiceAddress.isValid;
	}

	private get isCountryValidForDelivery() {
        return this.$store.getters.customerIsCountryValidForDelivery;
    }

	// @change auf dem step-control geht nicht, weil dann die Buttons (Zurück/Weiter) nichts auslösen
	@Watch('stepCount')
	private async onStepCountChanged() {
		if (
			(this.stepCount == 3 || this.stepCount == 4) 
			&& this.$store.getters.isCartValid
			&& this.$store.getters.amountOfItemsInCart > 0 
			&& (this.$store.state.currentCart.hasChanged 
				|| this.$store.state.offerValidTimerSeconds == 0 
				|| this.offerFailed
			)
		) {
			await this.updateOffer();
		} else if (
			(this.stepCount == 3 || this.stepCount == 4) 
			&& this.$store.getters.amountOfItemsInCart <= 0) 
		{
			this.resetOffer();
		}
	}

	private async updateOffer() {
		let calculationId = 0;
		if (this.$store.state.offer != null) {
			calculationId = this.$store.state.offer.calculationId;
		}
		this.$store.commit('setOfferFailed', false);
		this.$store.commit('setOfferFailReason', OfferFailReason.None);
		this.$store.commit('setOrderSuccessful', false);
		this.$store.commit('setCurrentOfferCalculating', true);
		this.$store.commit('setOfferInvalidForCartUpdate', false);
		this.$store.commit('setCurrentOfferToNull');

		const apiCall: string =
			'offer/CalculateOffer?' +
			'calculationId=' +
			calculationId +
			'&cityOfDelivery=' +
			encodeURI(this.deliveryAddress.town) +
			'&streetOfDelivery=' +
			encodeURI(this.deliveryAddress.street) +
			'&zipOfDelivery=' +
			encodeURI(this.deliveryAddress.zip);

		// [IEnumerable<PositionDto> positions] wird vom Backend verlangt.
		// PositionDto ist identisch wie CartItem im Frontend.
		// Diese werden im Body übergeben.
		try {
			this.$store.commit('setLoading', true);
			this.$store.commit('setCurrentOfferCalculating', true);
			const response = await httpClient().post(
				apiCall,
				this.$store.state.currentCart.cartItems as Array<CartItem>
			);

			var offerDto = response.data as OfferDto;
			if (offerDto.offerFailReason != OfferFailReason.None || 
				offerDto.manufacturingOffers.length <= 0 ||
				offerDto.orderDiscounts.length <= 0) {
				this.$store.commit('setOfferFailReason', offerDto.offerFailReason);
				this.$store.commit('setOfferFailed', true);
			} else {
				this.$store.commit('updateOffer', offerDto);
				this.startOfferValidTimer();
			}
		} catch (error) {
			console.log(`Fehler bei der Erstellung des Angebots: ${error}`);
			this.$store.commit('setCurrentOfferToNull');
			this.$store.commit('setOfferFailed', true);
		} finally {
			this.$store.commit('setLoading', false);
			this.$store.commit('setCurrentOfferCalculating', false);
		}
	}

	private resetOffer() {
		this.$store.commit('setOfferFailed', false);
		this.$store.commit('setOfferFailReason', OfferFailReason.None);
		this.$store.commit('setOrderSuccessful', false);
		this.$store.commit('setCurrentOfferToNull');
		this.$store.commit('setCurrentOfferCalculating', false);
		this.$store.commit('setOfferInvalidForCartUpdate', false);
	}

	private openAddressPicker(type: ErpAddressType) {
		this.currentAddressPickerType = type;
		this.addressPickerDialog = true;
	}

	private refreshAddresses() {
		let delivery = JSON.parse(JSON.stringify(this.$store.state.currentCart.deliveryAddress));
		let invoice = JSON.parse(JSON.stringify(this.$store.state.currentCart.invoiceAddress));
		this.deliveryAddress = Object.assign(new AddressDto, delivery);
		this.invoiceAddress = Object.assign(new AddressDto, invoice);
		this.deliveryAddressKey += 1;
		this.invoiceAddressKey -= 1;
	}

	private startOfferValidTimer() {
		window.clearInterval(this.offerDecrementTimerId);
		this.$store.commit('restartOfferValidTimer');
		let newIntervalId = window.setInterval(this.decrementOfferTimer, 1000);
		this.$store.commit('setOfferDecrementTimerId', newIntervalId);
	}

	private decrementOfferTimer() {
		this.$store.commit('decrementOfferValidTimer');
		if (this.$store.state.offerValidTimerSeconds === 0) {
			window.clearInterval(this.offerDecrementTimerId);
		}
	}

	private goToCustomerSettings() {
		this.$router.push({ 
			name: 'Settings', 
			params: { 
				tab: 'customer', 
			} 
		});
	}
}
