var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.userCanManageUsers && _vm.usersLoaded
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xs: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { shrink: "" },
                      on: {
                        click: function ($event) {
                          _vm.addUserDialog = true
                        },
                      },
                    },
                    [_vm._v(" Benutzer hinzufügen ")]
                  ),
                  _c("span", { staticStyle: { "font-size": "0.8em" } }, [
                    _c("br"),
                    _vm._v(
                      "Hinweis: Um die E-Mail Adresse des aktuellen Benutzers zu ändern, verwenden Sie bitte die Funktion in den "
                    ),
                    _c("a", { on: { click: _vm.goToUserSettings } }, [
                      _vm._v("Benutzereinstellungen"),
                    ]),
                    _vm._v(". "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.userCanManageUsers && _vm.usersLoaded
        ? _c(
            "v-row",
            { attrs: { id: "userListe", wrap: "" } },
            _vm._l(_vm.sortedUsers, function (user) {
              return _c(
                "v-col",
                {
                  key: user.userId,
                  attrs: {
                    cols: "12",
                    xs: "12",
                    sm: "12",
                    md: "6",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-container",
                                {
                                  staticClass: "userTile",
                                  class: { "elevation-10": hover },
                                },
                                [
                                  _c(
                                    "v-overlay",
                                    {
                                      attrs: { absolute: true },
                                      model: {
                                        value: _vm.deleteOverlay,
                                        callback: function ($$v) {
                                          _vm.deleteOverlay = $$v
                                        },
                                        expression: "deleteOverlay",
                                      },
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "accent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0 mb-2 align-center" },
                                    [
                                      user.confirmed
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "1" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    large: "",
                                                    color: "accent",
                                                  },
                                                },
                                                [_vm._v("mdi-account")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "1" },
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "open-delay": "200",
                                                    "content-class":
                                                      "customTooltip",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    large: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-timer-sand-full"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(
                                                    " Bestätigung des eingeladenen Benutzers noch ausstehend. "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                      user.confirmed
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "10" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "title ma-0 pa-0 truncate",
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        top: "",
                                                        "open-delay": "500",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          user.firstname
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          user.lastname
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.firstname
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            user.lastname
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "10" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "title ma-0 pa-0 truncate",
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        top: "",
                                                        "open-delay": "500",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          user.username
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.username
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "1" },
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  small: "",
                                                                  title:
                                                                    "Öffnet das Kontextmenü zum Benutzer.",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-dots-vertical"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.userIsLoggedInUser(
                                                            user.userId
                                                          ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.userToRemove =
                                                            user
                                                          _vm.userDeleteWarningDialog = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "accent",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(" Löschen "),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  user.confirmed
                                    ? _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c("p", { staticClass: "ma-0" }, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(user.username) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "open-delay": "200",
                                                "content-class":
                                                  "customTooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.savingClaim ||
                                                                  _vm.userIsLoggedInUser(
                                                                    user.userId
                                                                  ) ||
                                                                  _vm.isOnlyTrueManageUser(
                                                                    user.canManageUsers
                                                                  ) ||
                                                                  !user.confirmed,
                                                                label:
                                                                  "Benutzer verwalten",
                                                                color: "accent",
                                                                "hide-details": true,
                                                                dense: true,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveClaim(
                                                                      user.userId,
                                                                      user.username,
                                                                      "ManageUser",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  user.canManageUsers,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      user,
                                                                      "canManageUsers",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.canManageUsers",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.userIsLoggedInUser(
                                                user.userId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte des eigenen Benutzers können nicht angepasst werden. "
                                                    ),
                                                  ])
                                                : !user.confirmed
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte können erst angepasst werden, wenn der Benutzer bestätigt ist. "
                                                    ),
                                                  ])
                                                : _vm.isOnlyTrueManageUser(
                                                    user.canManageUsers
                                                  )
                                                ? _c("span", [
                                                    _vm._v(
                                                      ' Es muss stets 1 Benutzer das Recht "Benutzer verwalten" haben. '
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Der Benutzer kann weitere Benutzer hinzufügen, Benutzer löschen und die Rechte aller Benutzer bearbeiten. "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "open-delay": "200",
                                                "content-class":
                                                  "customTooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.savingClaim ||
                                                                  _vm.userIsLoggedInUser(
                                                                    user.userId
                                                                  ) ||
                                                                  _vm.isOnlyDirectOrderUser(
                                                                    user.canOrderDirectly
                                                                  ) ||
                                                                  !user.confirmed,
                                                                label:
                                                                  "Bestellen",
                                                                color: "accent",
                                                                "hide-details": true,
                                                                dense: true,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveClaim(
                                                                      user.userId,
                                                                      user.username,
                                                                      "DirectOrder",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  user.canOrderDirectly,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      user,
                                                                      "canOrderDirectly",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.canOrderDirectly",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.userIsLoggedInUser(
                                                user.userId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte des eigenen Benutzers können nicht angepasst werden. "
                                                    ),
                                                  ])
                                                : !user.confirmed
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte können erst angepasst werden, wenn der Benutzer bestätigt ist. "
                                                    ),
                                                  ])
                                                : _vm.isOnlyDirectOrderUser(
                                                    user.canOrderDirectly
                                                  )
                                                ? _c("span", [
                                                    _vm._v(
                                                      ' Es muss stets 1 Benutzer das Recht "Bestellen" haben. '
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Der Benutzer kann zahlungspflichtig bestellen. "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "open-delay": "200",
                                                "content-class":
                                                  "customTooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.savingClaim ||
                                                                  _vm.userIsLoggedInUser(
                                                                    user.userId
                                                                  ) ||
                                                                  !user.confirmed,
                                                                label:
                                                                  "Bestellung beantragen",
                                                                color: "accent",
                                                                "hide-details": true,
                                                                dense: true,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveClaim(
                                                                      user.userId,
                                                                      user.username,
                                                                      "IndirectOrder",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  user.canOrderIndirectly,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      user,
                                                                      "canOrderIndirectly",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.canOrderIndirectly",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.userIsLoggedInUser(
                                                user.userId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte des eigenen Benutzers können nicht angepasst werden. "
                                                    ),
                                                  ])
                                                : !user.confirmed
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte können erst angepasst werden, wenn der Benutzer bestätigt ist. "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Der Benutzer kann Bestellungen vorbereiten und zur Bestätigung an Benutzer mit der entsprechenden Berechtigung senden. "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "open-delay": "200",
                                                "content-class":
                                                  "customTooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.savingClaim ||
                                                                  _vm.userIsLoggedInUser(
                                                                    user.userId
                                                                  ) ||
                                                                  !user.confirmed,
                                                                label:
                                                                  "Lieferadressen ändern",
                                                                color: "accent",
                                                                "hide-details": true,
                                                                dense: true,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveClaim(
                                                                      user.userId,
                                                                      user.username,
                                                                      "ChangeDeliveryAddress",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  user.canChangeDeliveryAddress,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      user,
                                                                      "canChangeDeliveryAddress",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.canChangeDeliveryAddress",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.userIsLoggedInUser(
                                                user.userId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte des eigenen Benutzers können nicht angepasst werden. "
                                                    ),
                                                  ])
                                                : !user.confirmed
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte können erst angepasst werden, wenn der Benutzer bestätigt ist. "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Der Benutzer kann die Lieferadressen ändern. "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "open-delay": "200",
                                                "content-class":
                                                  "customTooltip",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g({}, on),
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.savingClaim ||
                                                                  _vm.userIsLoggedInUser(
                                                                    user.userId
                                                                  ) ||
                                                                  !user.confirmed,
                                                                label:
                                                                  "Rechnungsadressen ändern",
                                                                color: "accent",
                                                                "hide-details": true,
                                                                dense: true,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveClaim(
                                                                      user.userId,
                                                                      user.username,
                                                                      "ChangeInvoiceAddress",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  user.canChangeInvoiceAddress,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      user,
                                                                      "canChangeInvoiceAddress",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.canChangeInvoiceAddress",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.userIsLoggedInUser(
                                                user.userId
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte des eigenen Benutzers können nicht angepasst werden. "
                                                    ),
                                                  ])
                                                : !user.confirmed
                                                ? _c("span", [
                                                    _vm._v(
                                                      " Die Rechte können erst angepasst werden, wenn der Benutzer bestätigt ist. "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Der Benutzer kann die Rechnungsadressen ändern. "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          )
        : !_vm.isLoading
        ? _c("v-row", [
            _vm._v(" Berechtigung zur Benutzerverwaltung nicht vorhanden. "),
          ])
        : _vm._e(),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.isValidEmail,
            callback: function ($$v) {
              _vm.isValidEmail = $$v
            },
            expression: "isValidEmail",
          },
        },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500", persistent: "" },
              model: {
                value: _vm.addUserDialog,
                callback: function ($$v) {
                  _vm.addUserDialog = $$v
                },
                expression: "addUserDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Benutzer hinzufügen")]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "Email des neuen Benutzers",
                          rules: _vm.validEmailRule,
                          color: "accent",
                        },
                        model: {
                          value: _vm.newUser.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.newUser, "username", $$v)
                          },
                          expression: "newUser.username",
                        },
                      }),
                      _vm._v(" Rechte des neuen Benutzers "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "open-delay": "200",
                            "content-class": "customTooltip",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Benutzer verwalten",
                                          color: "accent",
                                        },
                                        model: {
                                          value: _vm.newUser.canManageUsers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "canManageUsers",
                                              $$v
                                            )
                                          },
                                          expression: "newUser.canManageUsers",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            " Der Benutzer kann weitere Benutzer hinzufügen, Benutzer löschen und die Rechte aller Benutzer bearbeiten. "
                          ),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "open-delay": "200",
                            "content-class": "customTooltip",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Bestellen",
                                          color: "accent",
                                        },
                                        model: {
                                          value: _vm.newUser.canOrderDirectly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "canOrderDirectly",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newUser.canOrderDirectly",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            " Der Benutzer kann zahlungspflichtig bestellen. "
                          ),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "open-delay": "200",
                            "content-class": "customTooltip",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Bestellung beantragen",
                                          color: "accent",
                                        },
                                        model: {
                                          value: _vm.newUser.canOrderIndirectly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "canOrderIndirectly",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newUser.canOrderIndirectly",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            " Der Benutzer kann Bestellungen vorbereiten und zur Bestätigung an Benutzer mit der entsprechenden Berechtigung senden. "
                          ),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "open-delay": "200",
                            "content-class": "customTooltip",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Lieferadressen ändern",
                                          color: "accent",
                                        },
                                        model: {
                                          value:
                                            _vm.newUser
                                              .canChangeDeliveryAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "canChangeDeliveryAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newUser.canChangeDeliveryAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            " Der Benutzer kann die Lieferadressen ändern. "
                          ),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "open-delay": "200",
                            "content-class": "customTooltip",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g({}, on),
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Rechnungsadressen ändern",
                                          color: "accent",
                                        },
                                        model: {
                                          value:
                                            _vm.newUser.canChangeInvoiceAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newUser,
                                              "canChangeInvoiceAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newUser.canChangeInvoiceAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(
                            " Der Benutzer kann die Rechnungsadressen ändern. "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.addUserDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: { text: "", disabled: !_vm.isValidEmail },
                          on: {
                            click: function ($event) {
                              return _vm.addUser()
                            },
                          },
                        },
                        [_vm._v(" Hinzufügen ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.userToRemove != null
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.userDeleteWarningDialog,
                callback: function ($$v) {
                  _vm.userDeleteWarningDialog = $$v
                },
                expression: "userDeleteWarningDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Sind Sie sicher?")]),
                  _c("v-card-text", [
                    _vm._v(
                      " Soll der Benutzer " +
                        _vm._s(_vm.userToRemove.username) +
                        " wirklich aus dem Konto entfernt werden? "
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.userDeleteWarningDialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.removeUserFromAccount()
                            },
                          },
                        },
                        [_vm._v(" Entfernen ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }