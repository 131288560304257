
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import { PendingOrderDto } from '../../dtos/pendingOrderDto';

@Component
export default class PendingOrder extends Vue {
    @Prop()
    private pendingOrder!: PendingOrderDto;
    private deleteOrderDialog: boolean = false;

    private async deletePendingOrder() {
        try {
            await httpClient().delete(`order/DeletePendingOrder?cartId=${this.pendingOrder.id}`);
            this.$emit('deleted', this.pendingOrder.id)
            this.deleteOrderDialog = false;
        } catch(e) {
            console.error(e);
        }
    }

    private async addToCart() {
        this.pendingOrder.isInCart = true;
        const articleIds = this.pendingOrder.positions.map((p) => p.articleId);
        await this.$store.dispatch('loadMissingArticles', articleIds);
        this.$store.dispatch('loadSavedCartIntoCurrentCart', this.pendingOrder.positions);
        this.$router.push({ name: 'Checkout' });
    }
}
