var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-container",
              {
                staticClass: "assemblyTile",
                class: { "elevation-10": hover },
                staticStyle: { position: "relative" },
              },
              [
                _c(
                  "v-overlay",
                  {
                    attrs: { absolute: true },
                    model: {
                      value: _vm.deleteOverlay,
                      callback: function ($$v) {
                        _vm.deleteOverlay = $$v
                      },
                      expression: "deleteOverlay",
                    },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "accent" },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "ma-0", attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0",
                        attrs: {
                          cols: _vm.isMultiSelectionMode || hover ? 10 : 11,
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "subtitle-1 ma-0 pa-0 truncate" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass: "pointerElement",
                                                on: {
                                                  click:
                                                    _vm.openArticleEditDialog,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.article
                                                      .customerArticleName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.article.customerArticleName) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    hover || _vm.isMultiSelectionMode
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "1" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "50" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  disabled: _vm.state.locked,
                                                  color: "accent",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.isArticleSelected,
                                                  callback: function ($$v) {
                                                    _vm.isArticleSelected = $$v
                                                  },
                                                  expression:
                                                    "isArticleSelected",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(
                                  " Auswählen für Mehrfachaktion. Mögliche Aktionen werden oben angezeigt. "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "1" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                title:
                                                  "Öffnet das Kontextmenü zum Artikel.",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-dots-vertical"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.openArticleEditDialog } },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "accent" } },
                                          [_vm._v("mdi-pencil")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Bearbeiten "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.state.calculating ||
                                        _vm.state.locked,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkDeleteArticle()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "accent" } },
                                          [_vm._v("mdi-delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Löschen "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0",
                    staticStyle: { "min-height": "200px" },
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-2", attrs: { cols: "6" } },
                      [
                        _c("v-img", {
                          staticClass: "previewImage",
                          style: { cursor: "pointer" },
                          attrs: {
                            contain: "",
                            width: "160px",
                            height: "150px",
                            title: "Öffnet das CAD Modell im Browser.",
                            src: _vm.getThumbnailUrl(_vm.article.articleId),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openCadDialog()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption pa-0",
                        attrs: { cols: "6" },
                      },
                      [
                        _vm.article.customerArticleId !== ""
                          ? _c("p", { staticClass: "ma-0 pa-0" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "pointerElement",
                                  on: { click: _vm.openArticleEditDialog },
                                },
                                [
                                  _c("strong", [_vm._v("Artikel Nr:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.article.customerArticleId) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "ma-0 pa-0" }, [
                          _c("strong", [_vm._v("Blexon Nr:")]),
                          _vm._v(" " + _vm._s(_vm.article.articleId) + " "),
                        ]),
                        _c("p", { staticClass: "ma-0 pa-0" }, [
                          _c("strong", [_vm._v("Gewicht: ")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("round3Decimal")(_vm.gewicht)) +
                              "kg "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "5px",
                              display: "flex",
                              "flex-wrap": "wrap",
                              gap: "5px 5px",
                            },
                          },
                          [
                            _vm._l(
                              _vm.article.subArticleIds.slice(0, 6),
                              function (subarticleId) {
                                return _c("v-img", {
                                  key: subarticleId,
                                  attrs: {
                                    height: "60px",
                                    width: "60px",
                                    contain: "",
                                    src: _vm.getThumbnailUrl(subarticleId),
                                  },
                                })
                              }
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.article.subArticleIds.length > 6,
                                    expression:
                                      "article.subArticleIds.length > 6",
                                  },
                                ],
                              },
                              [_vm._v("mehr...")]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mx-0 mt-2 align-center" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "6" } },
                      [
                        !_vm.state.calculating
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              {
                                                staticClass: "text-caption",
                                                attrs: {
                                                  color: _vm.articleStateColor,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { left: "" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.articleStateIcon
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.articleStateText) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm.article.subArticleIds.length <= 0 &&
                                _vm.article.camMessages.length <= 0
                                  ? _c("span", [
                                      _vm._v(
                                        "Baugruppe enthält keine gültigen Volumenkörper. Evtl. sind die Körper offen oder nur als Oberfläche definiert."
                                      ),
                                    ])
                                  : _vm.article.subArticleIds.length <= 0 &&
                                    _vm.article.camMessages.length > 0
                                  ? _c(
                                      "span",
                                      _vm._l(
                                        _vm.article.camMessages,
                                        function (message, index) {
                                          return _c("span", { key: index }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(message.shortDescription)
                                            ),
                                            _c("br"),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "Klicken Sie auf 'Baugruppe anzeigen' um die Status der einzelnen Artikel zu sehen."
                                      ),
                                    ]),
                              ]
                            )
                          : _c("v-progress-circular", {
                              attrs: {
                                small: "",
                                indeterminate: "",
                                color: "accent",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm.article.subArticleIds.length > 0
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button",
                                                attrs: { small: "", block: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.navigateTo(
                                                      `catalogue/assembly/${_vm.article.articleId}`
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" Baugruppe anzeigen ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Zeigt alle Artikel dieser Baugruppe an. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mx-0 my-0 mt-2 align-center" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-0 pr-2", attrs: { cols: "6" } },
                      [
                        _c(
                          "v-form",
                          {
                            model: {
                              value: _vm.isValidAmount,
                              callback: function ($$v) {
                                _vm.isValidAmount = $$v
                              },
                              expression: "isValidAmount",
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                placeholder: "Menge",
                                type: "number",
                                rules: _vm.allowedCartAmountRule,
                                color: "accent",
                                dense: "",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.amountToAddToCart,
                                callback: function ($$v) {
                                  _vm.amountToAddToCart = _vm._n($$v)
                                },
                                expression: "amountToAddToCart",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pa-0", attrs: { cols: "6" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", "open-delay": "500" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g({}, on),
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "pa-0 blexon-article-button",
                                              attrs: {
                                                small: "",
                                                block: "",
                                                disabled:
                                                  _vm.isCartButtonDisabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addToCart()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-cart-arrow-down")]
                                              ),
                                              _vm._v(" In Warenkorb "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm.article.canBeProduced
                              ? _c("span", [
                                  _vm._v(
                                    " Fügt den Artikel zum Warenkorb hinzu. "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " Artikel kann aktuell in " +
                                      _vm._s(_vm.region) +
                                      " nicht bestellt werden. "
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "content-class": "specialSize", persistent: "" },
                    model: {
                      value: _vm.cadDialog,
                      callback: function ($$v) {
                        _vm.cadDialog = $$v
                      },
                      expression: "cadDialog",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "#f3f3f4",
                          padding: "0px",
                        },
                      },
                      [
                        _vm.cadDialog
                          ? _c("iframe", {
                              staticStyle: {
                                padding: "0px",
                                margin: "0px",
                                border: "0px",
                                width: "100%",
                              },
                              attrs: {
                                loading: "lazy",
                                height: _vm.currentWindowHeight,
                                src: _vm.articleCadViewUrl,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c("article-edit-dialog", {
                  attrs: {
                    article: _vm.article,
                    articleEditDialog: _vm.articleEditDialog,
                    isUpdatingArticleData: _vm.isUpdatingArticleData,
                  },
                  on: {
                    isUpdating: _vm.setUpdating,
                    closeDialog: function ($event) {
                      _vm.articleEditDialog = false
                    },
                  },
                }),
                _c("article-delete-warning-dialog", {
                  attrs: {
                    articleDeleteWarningDialog: _vm.articleDeleteWarningDialog,
                    articleCartNames: _vm.articleCartNames,
                    articleId: _vm.articleId,
                  },
                  on: {
                    setDeleteOverlay: _vm.setDeleteOverlay,
                    closeDialog: function ($event) {
                      _vm.articleDeleteWarningDialog = false
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }