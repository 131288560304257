
import { Component, Prop, Vue, } from 'vue-property-decorator';
import { CheckArticleReason} from '../../dtos/checkArticleReason';
import httpClient from '../../store/httpClient';
import rules from '@/store/rules'

@Component
export default class SendToHelpdeskDialog extends Vue {
	@Prop()
	private showHelpdeskDialogue!: boolean;
	@Prop()
	private articleId!: number;
	@Prop()
	private reason!: CheckArticleReason;
    private isHelpdeskEmailValid: boolean = true;
    private helpdeskEmail: string = '';
    private requiredEmailRule: any = rules.requiredEmailRule;
	private isBusy: boolean = false;

	private mounted() {
        this.helpdeskEmail = this.$store.state.customer.user.username;
	}

	private async sendArticleToHelpdesk() {
        try {
			this.isBusy = true;
            await httpClient().post(`article/SendArticleToHelpdesk?articleId=${this.articleId}&eMail=${this.helpdeskEmail}&reason=${this.reason}`);
            this.$store.commit('setArticleStateToLocked', this.articleId);
        } catch(err) {
            console.log(`Der Artikel konnte nicht ans Helpdesk gesendet werden. Error: ${err}`);
        } finally {
			this.isBusy = false;
			this.closeDialog();
		}
    }

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}
}

