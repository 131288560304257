
import { Component, Vue } from "vue-property-decorator";
import httpClient from "@/store/httpClient";
import rules from "@/store/rules";
import { CustomerInfoDto } from "@/dtos/customerInfoDto";
import { CustomerSettingsDto } from "@/dtos/customerSettingsDto";

@Component({})
export default class CustomerSettings extends Vue {
	private customer: CustomerInfoDto = new CustomerInfoDto();
	private lastCustomer: CustomerInfoDto = new CustomerInfoDto();
	private canUserChangeSettings: boolean = false;
	private isValid: boolean = false;
	private isLoading: boolean = false;
	private useCompanyName: boolean = false;
	private requiredStringMax35Rule = rules.requiredStringMax35Rule;
	private requiredEmailRule = rules.requiredEmailRule;
	private optionalEmailRule = rules.optionalEmailRule;
	private addressRequiredStringMax35Rule = rules.addressRequiredStringMax35Rule;
	private companyNameRule = [
		(v: string) => !!v || "Angabe erforderlich",
		(v: string) => v.replace(/\s/g, "").length > 0 || "Eingabe darf nicht nur Leerzeichen sein.",
		(v: string) => !v || v.length <= 50 || "Maximal 50 Zeichen erlaubt.",
		(v: string) => !this.useCompanyName || v !== "privat" || "Firmenname darf nicht 'privat' sein.",
	];
	private correctPlzRule = [
		(v: string) => {
			const isValid =
				(this.getCustomerCountry() !== "Deutschland" && this.getCustomerCountry() !== "Schweiz" && this.getCustomerCountry() !== "Liechtenstein") ||
				(this.getCustomerCountry() === "Deutschland" && v.length === 5 && parseInt(v) >= 1000 && parseInt(v) <= 99999) ||
				((this.getCustomerCountry() === "Schweiz" || this.getCustomerCountry() === "Liechtenstein") && v.length === 4 && parseInt(v) >= 1000 && parseInt(v) <= 9999);

			return isValid || "Muss eine gültige PLZ sein.";
		},
	];

	private customerMwstNumberRule = [(v: string) => v.length <= 50 || "Maximal 50 Zeichen erlaubt."];

	private mounted() {
		this.loadCustomer();
		this.setRights();
	}

	private get hasChanges() {
		return (
			this.lastCustomer.companyName !== this.customer.companyName ||
			this.lastCustomer.street !== this.customer.street ||
			this.lastCustomer.town !== this.customer.town ||
			this.lastCustomer.plz !== this.customer.plz ||
			this.lastCustomer.generalPhone !== this.customer.generalPhone ||
			this.lastCustomer.generalEmail !== this.customer.generalEmail ||
			this.lastCustomer.customerMwstNumber !== this.customer.customerMwstNumber ||
			this.lastCustomer.defaultAdditionalOrderConfirmationEmail !== this.customer.defaultAdditionalOrderConfirmationEmail
		);
	}

	private loadCustomer() {
		this.customer = JSON.parse(JSON.stringify(this.$store.state.customer));
		this.useCompanyName = this.customer.companyName !== "privat";
		this.lastCustomer = JSON.parse(JSON.stringify(this.customer));
	}

	private async setRights() {
		const response = await httpClient().get("Customer/CanUserManageUsers");
		this.canUserChangeSettings = response.data;
	}

	private async saveCustomer() {
		if (this.isValid && !this.isLoading && this.hasChanges && !(this.useCompanyName && this.customer.companyName === "")) {
			try {
				console.log("Saving customer...");
				this.isLoading = true;
				const settings: CustomerSettingsDto = {
					customerId: this.customer.customerId,
					companyName: this.customer.companyName,
					street: this.customer.street,
					plz: this.customer.plz,
					town: this.customer.town,
					generalEmail: this.customer.generalEmail,
					generalPhone: this.customer.generalPhone,
					customerMwstNumber: this.customer.customerMwstNumber,
					defaultAdditionalOrderConfirmationEmail: this.customer.defaultAdditionalOrderConfirmationEmail,
				};
				await httpClient().post("Customer/SaveCustomerSettings", settings);
				this.$store.commit("updateCustomerSettings", {
					companyName: this.customer.companyName,
					phone: this.customer.generalPhone,
					email: this.customer.generalEmail,
					customerMwstNumber: this.customer.customerMwstNumber,
					defaultAdditionalOrderConfirmationEmail: this.customer.defaultAdditionalOrderConfirmationEmail,
				});
				this.$store.dispatch("setSnackbarText", "Einstellungen erfolgreich gespeichert.");
				this.lastCustomer = JSON.parse(JSON.stringify(this.customer));
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Einstellungen konnten nicht gespeichert werden.");
			} finally {
				this.isLoading = false;
			}
		}
	}

	private getCustomerCountry() {
		return this.customer ? (this.customer.country ? this.customer.country : "") : "";
	}

	private validate() {
		(this.$refs.form as any).validate();
	}
}
