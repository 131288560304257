export enum PowderFinish {
	Matt = 0,
	Glossy = 1
}

export enum PowderStructure {
	Fine = 0,
	Rough = 1,
	Smooth = 2,
}

export enum PowderApplication {
	Internal = 0,
	ExternalIndustry = 10,
	ExternalFassade = 20,
}

export enum ArticleFaceType {
	Visible = 0,
	Back = 1,
	Both = 2,
}