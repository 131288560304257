
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import httpClient from "@/store/httpClient";
	import rules from "@/store/rules";
	import { SavedCartDto } from "@/dtos/savedCartDto";
	import { SavedCartPosition } from "@/dtos/savedCartPosition";

	@Component
	export default class SavedCarts extends Vue {
		private selectedCartId: number = 0;
		private sortedSavedCarts: SavedCartDto[] = [];
		private deleteSavedCartDialog = false;
		private allowedCartAmountRule = rules.allowedCartAmountRule;
		private requiredIntegerRule = rules.requiredIntegerRule;
		private sentToCartAmount: number = 1;

		private async mounted() {
			if (this.savedCarts !== null && this.savedCarts.length > 0) {
				this.sortedSavedCarts = (this.savedCarts as any[]).sort((a, b) => a.name.localeCompare(b.name));
			} else {
				this.sortedSavedCarts = this.savedCarts;
			}
			if (this.lastSelectedSavedCartId > 0) {
				this.selectedCartId = this.lastSelectedSavedCartId;
			}
		}

		@Watch("selectedCartId")
		private setLastSelectedSavedCart() {
			if (this.selectedCartId > 0 && this.selectedCartId !== this.lastSelectedSavedCartId) {
				this.lastSelectedSavedCartId = this.selectedCartId;
			}
		}

		private get currentlySelectedCart(): SavedCartDto {
			if (this.selectedCartId > 0) {
				let index = this.sortedSavedCarts.findIndex((cart: SavedCartDto) => cart.id === this.selectedCartId);
				return (index >= 0) ? this.sortedSavedCarts[index] : new SavedCartDto();
			} else {
				return new SavedCartDto();
			}
		}

		private get savedCarts() {
			if (this.$store.state.savedCarts === null) {
				this.loadSavedCarts();
			}
			return this.$store.state.savedCarts;
		}
		private set savedCarts(value: SavedCartDto[]) {
			this.$store.commit("setSavedCarts", value);
		}

		private get savedCartsExist(): boolean {
			return this.savedCarts != null && this.savedCarts.length > 0;
		}

		private get lastSelectedSavedCartId(): number {
			return this.$store.state.currentSavedCartId;
		}
		private set lastSelectedSavedCartId(newId: number) {
			this.$store.commit("setCurrentSavedCartId", newId);
		}

		private getThumbnailUrl(articleId: number) {
			return this.$store.getters.getThumbnailUrl(articleId, undefined);
		}

		private async loadSavedCarts() {
			if (this.savedCarts === null) {
				const response = await httpClient().get("cart/GetCartsOfCustomer");
				this.$store.commit("setSavedCarts", response.data);
			}
		}

		private async loadArticlesFromSavedCart() {
			const articleIds = this.currentlySelectedCart.positions.map((p) => p.articleId);
			await this.$store.dispatch("loadMissingArticles", articleIds);
			let tempLoopAmount = 1;
			if (this.sentToCartAmount && this.sentToCartAmount > 1) {
				tempLoopAmount = this.sentToCartAmount;
			}
			for (let i = 0; i < tempLoopAmount; i++) {
				this.$store.dispatch("loadSavedCartIntoCurrentCart", this.currentlySelectedCart.positions);
			}
			this.$store.dispatch("setSnackbarText", "Artikel aus Stückliste wurden dem Warenkorb hinzugefügt.");
		}

		private async addArticleFromSavedCartToCurrentCart(articleId: number, quantity: number) {
			await this.$store.dispatch("loadMissingArticles", [articleId]);
			let oldQuantity = this.$store.getters.quantityOfItemInCart(articleId);
			this.$store.dispatch("addArticleFromSavedCartToCurrentCart", { articleId: articleId, quantity: quantity });
			this.$store.dispatch("setSnackbarArticleText", {
				text: `${quantity} Stück dem Warenkorb hinzugefügt. ${oldQuantity} + ${quantity} = ${parseInt(oldQuantity) + quantity}`,
				articleId: articleId,
			});
		}

		private savedCartFilter(item: any, queryText: string, itemText: string) {
			const cartName = item.name.toLowerCase();
			const searchText = queryText.toLowerCase();
			return cartName.indexOf(searchText) > -1;
		}

		private setNewSavedCartArticleQuantity(articleId: number, quantity: string) {
			const parsed: number = parseInt(quantity);
			if (quantity !== undefined && !isNaN(parsed) && parsed > 0) {
				this.$store.dispatch("setAmountOfSavedCartItem", {
					cartId: this.currentlySelectedCart.id,
					articleId: articleId,
					quantity: parsed,
				});
			}
		}

		private removeArticleFromSavedCart(articleId: number) {
			this.$store.dispatch("removeArticleFromSavedCart", {
				cartId: this.currentlySelectedCart.id,
				articleId: articleId,
			});
			this.$store.dispatch("setSnackbarText", "Artikel wurde aus der Stückliste entfernt.");
		}

		private deleteSavedCart() {
			let cartIdToDelete = this.selectedCartId;
			this.selectedCartId = 0;
			this.$store.dispatch("deleteSavedCart", cartIdToDelete);
			this.deleteSavedCartDialog = false;
			this.$store.dispatch("setSnackbarText", "Stückliste wurde gelöscht.");
		}
	}
