var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { attrs: { align: "center", dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-img", {
                attrs: {
                  contain: "",
                  width: "50px",
                  height: "50px",
                  src: _vm.thumbnailUrl,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-3 text-right",
              staticStyle: { width: "75px" },
              attrs: { cols: "auto" },
            },
            [_vm._v(" " + _vm._s(_vm.cartItem.quantity) + " x ")]
          ),
          _c("v-col", { staticClass: "mr-2" }, [
            _vm.article.customerArticleName.length > 40
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.article.customerArticleName.substring(0, 37) + "..."
                      ) +
                      " "
                  ),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.article.customerArticleName))]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    fab: "",
                    "x-small": "",
                    title: "Entfernt den Artikel aus dem Warenkorb.",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.removeFromCart()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }