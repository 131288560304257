import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Catalogue from './views/Catalogue/Catalogue.vue'
import Assembly from './views/Catalogue/Assembly.vue'
import Orders from './views/OrderHistory/Orders.vue'
import Checkout from './views/Checkout/Checkout.vue'
import Settings from './views/Settings/Settings.vue'
import NotFoundComponent from './views/NotFoundComponent.vue'
import UserManagement from './views/Settings/UserManagement.vue'
import RequestCancellation from '@/views/OrderHistory/RequestCancellation.vue'
import CancellationConfirmation from '@/views/OrderHistory/CancellationConfirmation.vue'
import CreateTicket from '@/views/OrderHistory/CreateTicket.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/Settings/:tab?',
    name: 'Settings',
    props: (route) => ({
      tabName: route.params.tab,
    }),
    component: Settings
  },
  {
    path: '/UserManagement',
    name: 'UserManagement',
    component: UserManagement
  },
  {
    path: '/Catalogue',
    name: 'Catalogue',
    component: Catalogue
  },
  {
    path: '/Catalogue/Assembly/:assemblyId?',
    name: 'Assembly',
    props: (route) => ({
      assemblyId: route.params.assemblyId,
    }),
    component: Assembly
  },
  {
    path: '/Orders',
    name: 'Orders',
    component: Orders 
  },
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/RequestCancellation/:orderId/:orderProcessedInMeantime',
    name: 'RequestCancellation',
    props: (route) => ({ 
      orderId: route.params.orderId, 
      orderProcessedInMeantime: (String(route.params.orderProcessedInMeantime).toLowerCase() === 'true')
    }),
    component: RequestCancellation,
  },
  {
    path: '/CancellationConfirmation/:orderId/',
    name: 'CancellationConfirmation',
    props: (route) => ({ 
      orderId: route.params.orderId
    }),
    component: CancellationConfirmation,
  },
  {
    path: '/Ticket/:orderId/',
    name: 'CreateOrEditTicket',
    props: (route) => ({ 
      orderId: route.params.orderId
    }),
    component: CreateTicket,
  },
  {
    path: '/',
    redirect: to => {
      return { path: '/Catalogue', name: 'Catalogue', component: Catalogue }
    }
  },
  {
    path: '*',
    component: NotFoundComponent,
  }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log('to:');  
//   console.log(to);
//   console.log('from:');
//   console.log(from);
//   next();
// })

Vue.use(VueRouter)
export default router
