import { render, staticRenderFns } from "./ChangeAddress.vue?vue&type=template&id=694053d2&scoped=true"
import script from "./ChangeAddress.vue?vue&type=script&lang=ts"
export * from "./ChangeAddress.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "694053d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\src\\Blexon.Kundenportal.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('694053d2')) {
      api.createRecord('694053d2', component.options)
    } else {
      api.reload('694053d2', component.options)
    }
    module.hot.accept("./ChangeAddress.vue?vue&type=template&id=694053d2&scoped=true", function () {
      api.rerender('694053d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Settings/ChangeAddress.vue"
export default component.exports