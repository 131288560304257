
import { Component, Prop, Vue, } from 'vue-property-decorator';

@Component
export default class ArticlePriceInfoDialog extends Vue {
	@Prop()
	private preisInformationenDialog!: boolean;

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}

	private get hasPriceModels()
	{
		return this.$store.getters.hasPriceModels;
	}
}

