var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "ma-1": "" } },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function ($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "4", xl: "3" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Vorname",
                              color: "accent",
                              disabled: _vm.isLoading,
                              rules: _vm.requiredStringMax35Rule,
                            },
                            on: {
                              blur: () => {
                                _vm.user.firstname = _vm.user.firstname.trim()
                                _vm.saveUser()
                              },
                            },
                            model: {
                              value: _vm.user.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "firstname", $$v)
                              },
                              expression: "user.firstname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Nachname",
                              color: "accent",
                              disabled: _vm.isLoading,
                              rules: _vm.requiredStringMax35Rule,
                            },
                            on: {
                              blur: () => {
                                _vm.user.lastname = _vm.user.lastname.trim()
                                _vm.saveUser()
                              },
                            },
                            model: {
                              value: _vm.user.lastname,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "lastname", $$v)
                              },
                              expression: "user.lastname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Telefonnummer",
                              color: "accent",
                              disabled: _vm.isLoading,
                              rules: _vm.requiredStringMax35Rule,
                            },
                            on: {
                              blur: () => {
                                _vm.user.phone = _vm.user.phone.trim()
                                _vm.saveUser()
                              },
                            },
                            model: {
                              value: _vm.user.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "phone", $$v)
                              },
                              expression: "user.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.user.newsletterSubscribed
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  disabled: _vm.isLoading,
                                  label: "Newsletter abonnieren",
                                  color: "accent",
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.saveUser()
                                    _vm.newsletterMessage = true
                                  },
                                },
                                model: {
                                  value: _vm.user.newsletterSubscribed,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "newsletterSubscribed",
                                      $$v
                                    )
                                  },
                                  expression: "user.newsletterSubscribed",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newsletterMessage == true
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(
                              " Newsletter abonniert. Falls gewünscht, können Sie den Newsletter beim nächsten Versand wieder abbestellen. "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { align: "end" } },
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v("Benutzername / E-Mail"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.user.username) + " "),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { align: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                title:
                                  "Klicken Sie hier, um die E-Mail Adresse / den Benutzernamen zu ändern. Sie legen den neuen Benutzernamen in einer Dialogbox fest.",
                                disabled: _vm.isLoading,
                              },
                              on: {
                                click: () => {
                                  _vm.newUsername = _vm.user.username
                                  _vm.emailChangeWarningdialog = true
                                },
                              },
                            },
                            [_vm._v(" Ändern ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                title:
                                  "Klicken Sie hier, um ihr Passwort zu ändern.",
                                disabled: _vm.isLoading,
                              },
                              on: {
                                click: () => (_vm.changePasswordDialog = true),
                              },
                            },
                            [_vm._v(" Passwort ändern ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", lg: "8", xl: "9" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "pa-0": "", "ma-0": "" } },
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v("App Code für Fusion 360 / SolidWorks Add-In"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.user.appCode)),
                        _c("br"),
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v(
                            " Wenn Sie mit Autodesk Fusion 360 oder SolidWorks konstruieren, empfehlen wir die Nutzung des Blexon Add-In. Installieren Sie das AddIn für "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://blexon.com/blexon-integration-in-autodesk-fusion-360-cad/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Fusion 360")]
                          ),
                          _vm._v(" bzw. "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://blexon.com/blexon-integration-in-solidWorks-cad/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("SolidWorks")]
                          ),
                          _vm._v(
                            " und verbinden Sie es mit dem obenstehenden App Code mit dem Blexon Shop. Damit können Sie Ihre CAD Modelle ohne Export / Import direkt in den Blexon Shop laden. "
                          ),
                        ]),
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v(
                            " Halten Sie Ihren App Code geheim und geben Sie ihn auf keinen Fall an Dritte weiter. Falls Sie den App Code zugänglich gemacht haben, generieren Sie unbedingt einen neuen Code. "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "pa-0": "", "ma-0": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "blexon-button",
                              attrs: {
                                title: "Code in Zwischenablage kopieren",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyToClipboard()
                                },
                              },
                            },
                            [_vm._v(" Kopieren ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 blexon-button",
                              attrs: {
                                title:
                                  "Klicken Sie hier, um einen neuen Code zu generieren. Der Zugriff mit dem alten Code ist dann ab sofort nicht mehr möglich.",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.generateCode()
                                },
                              },
                            },
                            [_vm._v(" Neu Generieren ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.emailChangeWarningdialog,
            callback: function ($$v) {
              _vm.emailChangeWarningdialog = $$v
            },
            expression: "emailChangeWarningdialog",
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isUsernameValid,
                callback: function ($$v) {
                  _vm.isUsernameValid = $$v
                },
                expression: "isUsernameValid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Ändern des Benutzernamens")]),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " Ihre E-Mail Adresse ist gleichzeitig auch ihr Benutzername bei der Anmeldung."
                      ),
                      _c("br"),
                      _vm._v(
                        " Wenn Sie ihre E-Mail Adresse ändern, ändert sich auch ihr Benutzername. Bitte beachten Sie das bei Ihrer nächsten Anmeldung."
                      ),
                      _c("br"),
                      _c("br"),
                      _c("v-text-field", {
                        attrs: {
                          label: "Neuer Benutzername / E-Mail",
                          color: "accent",
                          disabled: _vm.isLoading,
                          rules: _vm.requiredEmailRule,
                        },
                        on: {
                          blur: function ($event) {
                            _vm.newUsername = _vm.newUsername.trim()
                          },
                        },
                        model: {
                          value: _vm.newUsername,
                          callback: function ($$v) {
                            _vm.newUsername = $$v
                          },
                          expression: "newUsername",
                        },
                      }),
                      _c("strong", [_vm._v("Hinweis:")]),
                      _vm._v(
                        " Das ist nicht die E-Mail Adresse für den Rechnungsversand. Diese finden Sie in den Einstellungen zur Rechnungsadresse. "
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.emailChangeWarningdialog = false
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            text: "",
                            disabled: !_vm.isUsernameValid || _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveUsername()
                            },
                          },
                        },
                        [_vm._v(" Speichern ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.emailChangeRegisteredDialog,
            callback: function ($$v) {
              _vm.emailChangeRegisteredDialog = $$v
            },
            expression: "emailChangeRegisteredDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Bestätigungsemail versendet")]),
              _c("v-card-text", [
                _vm._v(
                  " Zur Bestätigung erhalten Sie eine E-Mail an die angegebene neue Adresse."
                ),
                _c("br"),
                _vm._v(
                  " Mit einem Klick auf den darin enthaltenen Link wird die Änderung aktiv. "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.emailChangeRegisteredDialog = false
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.emailChangeFailedDialog,
            callback: function ($$v) {
              _vm.emailChangeFailedDialog = $$v
            },
            expression: "emailChangeFailedDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Änderung Fehlgeschlagen")]),
              _c("v-card-text", [
                _vm._v(" Die Änderung konnte nicht durchgeführt werden."),
                _c("br"),
                _vm._v(
                  " Bitte kontrollieren Sie, ob die E-Mail Adresse korrekt und gültig ist."
                ),
                _c("br"),
                _vm._v(
                  " Vielleicht besteht bereits ein Blexonkonto unter dieser E-Mail Adresse."
                ),
                _c("br"),
                _vm._v(
                  " Wenn Sie der Meinung sind, die neue E-Mail Adresse ist gültig und gehört Ihnen, kontaktieren Sie bitte unser Helpdesk. "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.emailChangeFailedDialog = false
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.changePasswordDialog,
            callback: function ($$v) {
              _vm.changePasswordDialog = $$v
            },
            expression: "changePasswordDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Passwort ändern")]),
              _c("v-card-text", [
                _vm._v(
                  " Wir senden Ihnen eine E-Mail an " +
                    _vm._s(_vm.user.username) +
                    ". Diese enthält einen Link, mit dem Sie ihr Passwort ändern können."
                ),
                _c("br"),
                _vm._v(
                  " Bitte überprüfen Sie ebenfalls den Spam-Ordner, falls Sie die E-Mail nicht erhalten. "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          _vm.changePasswordDialog = false
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { text: "", disabled: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          return _vm.changePassword()
                        },
                      },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }