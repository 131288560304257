
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import httpClient from "../../store/httpClient";

	@Component
	export default class RequestCancellation extends Vue {
		@Prop()
		private orderId!: number;
		@Prop()
		private orderProcessedInMeantime!: boolean;
		private acceptingCosts: boolean = false;
		private forceCancellation: boolean = false;
		private toggleAll: boolean = false;
		private order: any = {};
		private fixCost: number = -1;

		private async mounted() {
			if (!this.order || this.order.orderId !== this.orderId) {
				await this.loadOrder();
			}
			if (this.fixCost === -1) {
				await this.loadFixCost();
			}
		}

		private get isLoading() {
			return this.$store.state.loading;
		}
		private set isLoading(state: boolean) {
			this.$store.commit("setLoading", state);
		}

		private get cancellationRequestIsValid() {
			return (
				!this.isLoading &&
				this.acceptingCosts &&
				this.order != null &&
				this.order.positions != null &&
				this.order.positions.some((p: any) => p.isSelected)
			);
		}

		private async loadFixCost() {
			try {
				const response = await httpClient().get(`order/GetFixCostOfCancellation`);
				this.fixCost = response.data;
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Stornierungspauschale konnte nicht geladen werden.");
			}
		}

		private async loadOrder() {
			try {
				this.isLoading = true;
				const response = await httpClient().get(`order/getOrderConfirmation?orderId=${this.orderId}`);
				let orderData = response.data;
				orderData.positions.forEach((pos: any) => {
					pos.isSelected = false;
				});
				this.order = {
					orderId: orderData.blexonOrderId,
					fertigerId: orderData.fertigerId,
					currency: orderData.currency,
					positions: orderData.positions.filter((p: any) => p.materialname !== null),
				};
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Auftragspositionen konnten nicht geladen werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private async requestOrderCancellation() {
			if (!this.cancellationRequestIsValid) return false;
			console.log("requesting order cancellation...");
			try {
				this.isLoading = true;

				let orderPositions = this.order.positions
					.filter((p: any) => p.isSelected)
					.map((p: any) => ({
						articleId: parseInt(p.blexonArticleId),
						position: p.position,
					}));

				await httpClient().post(
					`Order/RequestOrderCancellation?orderId=${this.order.orderId}&force=${this.forceCancellation}`,
					orderPositions
				);

				this.$store.dispatch("setSnackbarText", "Stornierung wurde beantragt.");

				// Reload cancellations to get the updated list
				this.$store.dispatch('reloadCancellationRequests');				
				this.goToOrders();
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Stornierung konnte nicht beantragt werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private toggleAllPositions() {
			this.order.positions.forEach((p: any) => {
				p.isSelected = this.toggleAll;
			});
		}

		private getThumbnailUrl(articleId: number) {
			return this.$store.getters.getThumbnailUrl(articleId, undefined);
		}

		private goToOrders() {
			this.$router.push("/Orders");
		}
	}
