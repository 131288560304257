
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import { AddressDto } from '@/dtos/addressDto';
import rules from '@/store/rules'
import { AddressType } from '@/dtos/addressType';
import { ErpAddressType } from '@/dtos/erpAddressType';

@Component({})
export default class ChangeAddress extends Vue {
    @Prop()
    private address!: AddressDto;
	@Prop()
	private addressType!: AddressType;
    @Prop()
    private newAddress!: boolean;
    @Prop()
    private orderId!: number; // Must be given only if the address of a completed order should be changed
    private customerDeliveryType: AddressType = AddressType.CustomerDelivery;
	private customerInvoiceType: AddressType = AddressType.CustomerInvoice;
	private orderDeliveryType: AddressType = AddressType.OrderDelivery;
	private orderInvoiceType: AddressType = AddressType.OrderInvoice;
	private completedOrderInvoiceType: AddressType = AddressType.CompletedOrderInvoice;
	private completedOrderDeliveryType: AddressType = AddressType.CompletedOrderDelivery;
    private deliveryType: ErpAddressType = ErpAddressType.Delivery;
	private invoiceType: ErpAddressType = ErpAddressType.Invoice;
    private invoiceVersandart: string = 'email';
    private isValid: boolean = true;
	private isSaving: boolean = false;
    private addressOptionalStringMax35Rule = rules.addressOptionalStringMax35Rule;
    private addressRequiredStringMax35Rule = rules.addressRequiredStringMax35Rule;
    private requiredStringMax35Rule = rules.requiredStringMax35Rule;

    private mounted() {
		if (this.address.type == ErpAddressType.Invoice) {
			this.invoiceVersandart = this.address.isBilledDigitally ? 'email' : 'post';
		}
        if (!this.newAddress) {
            (this.$refs.form as any).validate();
        }
    }

    private correctPlz = [
        (v: string) => (
            (this.address.country !== 'Deutschland' && this.address.country !== 'Schweiz' && this.address.country !== 'Liechtenstein') ||
            (this.address.country === 'Deutschland' && v.length === 5 && parseInt(v) >= 1000 && parseInt(v) <= 99999) ||
            ((this.address.country === 'Schweiz' || this.address.country === 'Liechtenstein') && v.length === 4 && parseInt(v) >= 1000 && parseInt(v) <= 9999)) ||
            'Muss eine gültige PLZ sein.'
    ];

    private get emailRule() {
        if (this.invoiceVersandart === 'email' || !this.rechnungsversandPerPostMöglich) {
            return rules.requiredEmailRule;
        }
        return rules.optionalEmailRule;
    }

    @Watch('invoiceVersandart')
    private updateVersandart() {
        if (this.invoiceVersandart === 'email') {
            this.address.isBilledDigitally = true;
            this.address.eMail = this.$store.getters.customerDefaultInvoiceAddress.eMail;
        } else {
            this.address.isBilledDigitally = false;
            this.address.eMail = '';
        }
        (this.$refs.form as any).validate();
    }

    private get rechnungsversandPerPostMöglich() {
        return this.address.country === 'Schweiz' || this.address.country === 'Liechtenstein'
    }

	private async saveAddress() {
        if (this.isValid && !this.isSaving) {
			try {
				this.isSaving = true;
                let response;
                switch (this.addressType) {
                    case AddressType.CustomerDelivery:
                        response = await httpClient().post('customer/SaveCustomerAddress', this.address);
                        this.address.id = response.data;
                        this.$store.commit('addOrUpdateCustomerDeliveryAddress', this.address);
                        break;
                    case AddressType.CustomerInvoice:
                        response = await httpClient().post('customer/SaveCustomerAddress', this.address);
                        this.address.id = response.data;
                        this.$store.commit('addOrUpdateCustomerInvoiceAddress', this.address);
                        break;
                    case AddressType.OrderDelivery:
                        this.$store.commit('setOrderDeliveryAddress', this.address);
                        break;
                    case AddressType.OrderInvoice:
                        this.$store.commit('setOrderInvoiceAddress', this.address);
                        break;
                    case AddressType.CompletedOrderDelivery:
                        console.log('here')
					    await httpClient().post(`Order/SaveOrderDeliveryAddress?orderId=${this.orderId}`, this.address);
                        break;
                    case AddressType.CompletedOrderInvoice:
					    await httpClient().post(`Order/SaveOrderInvoiceAddress?orderId=${this.orderId}`, this.address);
                        break;
                    default:
                        throw 'Adresstyp ist ungültig';
                }
            	this.$store.dispatch('setSnackbarText', 'Adresse erfolgreich gespeichert.');
		        this.closeDialog(true);
			} catch(err) {
                console.log('Error while saving address.', err)
            	this.$store.dispatch('setSnackbarErrorText', 'Adresse konnte nicht gespeichert werden.');
			} finally {
				this.isSaving = false;
			}
		}
	}

    private closeDialog(changed: boolean) {
		this.$emit('closedDialog', {
            changed: changed,
            address: this.address
        });
	}
}
