var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.powderCoatingNotEditableDialog,
        callback: function ($$v) {
          _vm.powderCoatingNotEditableDialog = $$v
        },
        expression: "powderCoatingNotEditableDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Pulverbeschichtung nicht wählbar")]),
          _vm.hasEngravings
            ? _c("v-card-text", [
                _vm._v(
                  " Der Artikel enthält Gravuren. Da diese bei Blexon nicht vertieft sind, würden Gravuren von der Pulverbeschichtung vollständig überdeckt."
                ),
                _c("br"),
                _vm._v(
                  " Entfernen Sie die Gravur, um eine Pulverbeschichtung auswählen zu können."
                ),
                _c("br"),
                _vm._v(" Bei Fragen wenden Sie sich bitte an unser Helpdesk. "),
              ])
            : _c("v-card-text", [
                _vm._v(
                  " Um eine Pulverbeschichtung anzubringen, werden die Teile an dünnen Drähten aufgehängt."
                ),
                _c("br"),
                _vm._v(
                  " Unser Algorithmus hat keine Löcher oder Ausschnitte erkannt, an denen das Teil aufgehängt werden könnte."
                ),
                _c("br"),
                _vm._v(
                  " Falls möglich, bearbeiten Sie Ihr Modell und bringen Sie ein kleines Loch (mindestens 4mm Durchmesser) an. Ohne eine solche Aufhängung verteuert sich die Pulverbeschichtung. Dieser Mehrpreis kann nicht automatisch berechnet werden."
                ),
                _c("br"),
                _vm._v(
                  " Bitte senden Sie den Artikel an unser Helpdesk, wenn Sie keinen Ausschnitt anbringen können und trotzdem eine Pulverbeschichtung wünschen. "
                ),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              !_vm.hasEngravings
                ? _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button ml-3",
                      attrs: { text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("helpdeskSendenClicked")
                        },
                      },
                    },
                    [_vm._v(" An Helpdesk senden ")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Schliessen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }