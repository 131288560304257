import { render, staticRenderFns } from "./ArticleDeleteWarningDialog.vue?vue&type=template&id=696d5801"
import script from "./ArticleDeleteWarningDialog.vue?vue&type=script&lang=ts"
export * from "./ArticleDeleteWarningDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\src\\Blexon.Kundenportal.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('696d5801')) {
      api.createRecord('696d5801', component.options)
    } else {
      api.reload('696d5801', component.options)
    }
    module.hot.accept("./ArticleDeleteWarningDialog.vue?vue&type=template&id=696d5801", function () {
      api.rerender('696d5801', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Dialogs/ArticleDeleteWarningDialog.vue"
export default component.exports