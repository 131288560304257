import { PowderStructure, PowderFinish, PowderApplication } from './treatmentTypes';
import { SurfaceColorDto } from './surfaceColorDto';

export class PowderDto {
	public id: number = 0;
	public surfaceColor: SurfaceColorDto = new SurfaceColorDto();
	public structure: PowderStructure = PowderStructure.Smooth;
	public finish: PowderFinish = PowderFinish.Glossy;
	public application: PowderApplication = PowderApplication.Internal;
	public layerThickness: number = 100;
	public available: boolean = false;
	public setupCost: number = 0.0;
}