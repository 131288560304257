var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-tabs",
        {
          attrs: { color: "accent", "show-arrows": "mobile" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(_vm.tabTypes, function (type) {
          return _c("v-tab", { key: type.key, attrs: { ripple: "" } }, [
            _vm._v(" " + _vm._s(type.display) + " "),
          ])
        }),
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", { key: "user" }, [_c("user-settings")], 1),
          _c("v-tab-item", { key: "customer" }, [_c("customer-settings")], 1),
          _c(
            "v-tab-item",
            { key: "delivery" },
            [
              _c("addresses-overview", {
                attrs: { type: _vm.deliveryType, isOrder: false },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "invoice" },
            [
              _c("addresses-overview", {
                attrs: { type: _vm.invoiceType, isOrder: false },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }