
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import { FileDto } from '../../dtos/ticket/fileDto';
import ComplainTypes from '../../dtos/ticket/IComplainType';
import { IComplainType } from '../../dtos/ticket/IComplainType';
import { ComplainType } from '../../dtos/ticket/complainType';
import ComplaintExpectationTypes from '../../dtos/ticket/IComplaintExpectationType';
import { IComplaintExpectationType } from '../../dtos/ticket/IComplaintExpectationType';
import { ComplaintExpectationType } from '../../dtos/ticket/complaintExpectationType';
import { ComplaintDto } from '../../dtos/ticket/complaintDto';
import rules from '@/store/rules'
import { TicketState } from '@/dtos/ticket/ticketState';
import { TicketDto } from '@/dtos/ticket/ticketDto';

@Component({
    components: {},
})
export default class EditTicketPositionComponent extends Vue {
    @Prop()
    private complaint!: ComplaintDto;
    @Prop()
    private ticket!: TicketDto;
    private valid: boolean = false;
    private showGallery: boolean = false;
    private uploadingImages: boolean = false;
    private currentImage: number = 0;
    private source: any = '';
    private expectationTypes: IComplaintExpectationType[] = ComplaintExpectationTypes;
    private ersatzExpectation: ComplaintExpectationType = ComplaintExpectationType.Ersatzlieferung;
    private requiredRule = rules.requiredRule;

    private requiredExpectationDescription = [
        (value: string) => ((this.complaint.expectationType === this.ersatzExpectation) || (value !== undefined && value !== null && value !== '')) || 'Muss angegeben werden.',
    ];

    private requiredChoiceRule = [
        (value: ComplainType) => (value !== ComplainType.None) || 'Muss angegeben werden.',
    ];

    private requiredNumberRule = [
        (value: number) => !!value || 'Wert angeben',
        (value: number) => value >= 1 || 'Mindestens 1',
        (value: number) => value <= this.complaint.part.menge || `Maximal ${this.complaint.part.menge}`,
    ];

    private get beanstandungsTypen() : IComplainType[] {
        return ComplainTypes.filter(t => 
            (t.value != ComplainType.PowderCoating || this.complaint.part.powderCoatingTreatmentDto != null) &&
            (t.value != ComplainType.Feature || this.complaint.part.featureDtos.length > 0) &&
            (t.value != ComplainType.Weldline || this.complaint.part.weldlinesAsText != null));
    }

    private beforeMount() {
        if (this.complaint !== null && 
            this.complaint.numberOfWrongParts === 0) {
            this.complaint.numberOfWrongParts = 1;
        }
    }

    private mounted() {
        (this.$refs.form as any).validate();
    }

    private get partsAreMissing() {
        const result: boolean = this.complaint.complainType === ComplainType.Missing;
        return result;
    }

    private async uploadFiles(files: FileList) {
        if (files.length === 0) {
            return;
        }
        if (files.length + this.complaint.files.length > 10) {
            this.$store.commit('setSnackbarErrorText', 'Sie können maximal 10 Bilder hochladen.');
            return;
        }
        this.uploadingImages = true;
        await this.$nextTick()
        for (const file of files) {
            const data = new FormData();
            data.append('file', file);
            const config = {
                header : {
                    'Content-Type' : 'multipart/form-data',
                },
                onUploadProgress: (progressEvent: any) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload Progress: ${percentCompleted}`);
                },
            };

            try {
                const response = await httpClient().post(
                    'Ticket/UploadImage',
                    data,
                    config,
                );
                const newImage: FileDto = new FileDto();
                newImage.guid = response.data;
                this.complaint.files.push(newImage);
            } catch {
                this.$store.commit('setSnackbarErrorText', 'Bild kann nicht gelesen werden. ' +
                    'Bitte verwenden Sie ein Bild im Format jpg, png oder gif. ' +
                    'Bei Problemen wenden Sie sich bitte an info@blexon.com.');
            }
        }

        this.uploadingImages = false;
        this.validateForm();
    }

    private onImageClick(index: number) {
        this.currentImage = index;
        this.showGallery = true;
    }

    private deleteImage(index: number) {
        this.complaint.files.splice(index, 1);
    }

    private getThumbnailUrl(partId: string) {
        return this.$store.getters.getThumbnailUrl(partId, undefined);
    }

    private getComplaintImageUri(guid: string) {
        return process.env.VUE_APP_HELPDESK_BACKEND + 'api/File/GetImage?guid=' + guid;
    }

    private setMaxParts() {
        this.complaint.numberOfWrongParts = this.complaint.part.menge;
    }

    private get isReadonly() {
        return this.ticket.state != TicketState.Open
    }

    private validateForm() {
        if (this.partsAreMissing) {
            this.valid = (this.$refs.form as any).validate();
        } else {
            this.valid = ((this.$refs.form as any).validate() &&
                (this.complaint.files !== null && this.complaint.files.length > 0));
        }
    }

    @Watch('valid')
    private onValidChanged() {
        this.$emit('validChanged', this.valid);
    }
}
