
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import ChoosePosition from './ChoosePosition.vue';
import EditTicketPositionComponent from './EditTicketPositionComponent.vue';
import ComplainTypes from '../../dtos/ticket/IComplainType';
import { ComplaintExpectationType } from '../../dtos/ticket/complaintExpectationType';
import { TicketDto } from '../../dtos/ticket/ticketDto';
import { TicketState } from '@/dtos/ticket/ticketState';

Component.registerHooks([
    'beforeRouteLeave',
]);
@Component({
    components: {
        ChoosePosition,
        EditTicketPositionComponent,
    },
})
export default class CreateTicketView extends Vue {
    @Prop()
    private orderId!: string;
    private ticketDto: TicketDto = new TicketDto();
    private currentStep: number = 1;
    private saving: boolean = false;
    private formValid: boolean = false;
    private hasChanges: boolean = false;
    private next: any;
    private loading: boolean = false;
    private seiteVerlassenDialog: boolean = false;

    private requiredAltContactRule = [
        (value: string) => (this.isAlternativeContact() === false || (value !== undefined && value !== null && value !== '')) || 'Muss angegeben werden.',
    ];

    private requiredAltContactEmailRule = [
        (value: string) => (this.isAlternativeContact() === false || (value !== undefined && value !== null && value !== '')) || 'Muss angegeben werden.',
        (value: string) => (this.isAlternativeContact() === false || (this.validateEmail(value))) || 'Keine gültige Email.',
    ];

    private async mounted() {
        this.loadTicketDto();
    }

    private get altContactButtonText() {
        if (this.isAlternativeContact()) {
            return 'Standard Kontaktdaten verwenden';
        } else {
            return 'Alternative Kontaktdaten verwenden';
        }
    }

    private validateEmail(email: string) {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const matches = re.exec(email);
        return matches !== null && matches[0] === matches.input;
    }

    private isAlternativeContact() {
        return this.ticketDto.useAlternativeContactInfo;
    }

    private get selectedPositions() {
        return this.ticketDto.complains.filter((c) => c.part.selectedForComplaint === true);
    }

    private getComplainType(value: number) {
        const selectedValue = ComplainTypes.filter((t) => t.value === value)[0];
        if (selectedValue !== undefined) {
            return selectedValue.text;
        }
        return '';
    }

    private getExpectationDescription(typeValue: ComplaintExpectationType, stringValue: string) {
        if (typeValue === ComplaintExpectationType.Ersatzlieferung) {
            return 'Ersatzlieferung';
        }

        return stringValue;
    }

    private getThumbnailUrl(partId: string) {
        return this.$store.getters.getThumbnailUrl(partId, undefined);
    }

    private get ticketBlocked() {
        if (this.ticketDto != null && this.ticketDto.state != TicketState.Open) {
            return true
        }
        return false;
    }

    private canWeiter(): boolean {
        // Wenn noch nichts ausgewählt ist, kann man nicht weiter
        if (this.selectedPositions.length <= 0) {
            return false;
        }
        if (this.currentStep === this.selectedPositions.length + 3 &&
            (this.ticketDto.complains.some((c) => c.part.selectedForComplaint && !c.valid) ||
            !this.formValid ||
            this.ticketBlocked)) {
            return false;
        }
        return true;
    }

    private async weiter() {
        if (this.currentStep < this.selectedPositions.length + 3) {
            this.currentStep++;
        } else {
            try {
                this.saving = true;
                await httpClient().post('Ticket/SaveTicket', this.ticketDto);
                this.$store.dispatch('setSnackbarText', 'Beanstandung wurde erfolgreich gespeichert.');
                this.hasChanges = false;
                this.close();
            } catch(err) {
                this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Speichern. Bitte wenden Sie sich an info@blexon.com.');
            } finally {
                this.saving = false;
            }
        }
    }

    private close() {
        this.$router.push({name: 'Catalogue'});
    }

    private async loadTicketDto() {
        try {
            this.loading = true;
            const response = await httpClient().get(`Ticket/GetTicket?orderId=${this.orderId}`);
            this.ticketDto = response.data;
        } catch {
            this.$store.dispatch('setSnackbarErrorText', 'Ticket kann nicht erstellt werden. Auftrag nicht gefunden.');
            this.hasChanges = false;
            this.$router.push('/Orders')
        } finally {
            this.loading = false;
        }
    }

    private validateForm() {
        this.formValid = (this.$refs.form as any).validate();
    }

    private beforeRouteLeave(to: any, from: any, next: any) {
        if (this.hasChanges) {
            this.next = next;
            this.seiteVerlassenDialog = true;
        } else {
            next();
        }
    }    
}
