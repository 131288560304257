
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import httpClient from '../../store/httpClient';

@Component
export default class SpeechBubbleFloat extends Vue {
	@Prop()
	private hintId!: number;
	@Prop()
	private title!: "";
	@Prop()
	private message!: "";

	private showBubble = false;
	private dontShowAgain = false;

	private mounted() {
		// Don't show if hint id is invalid
		if (this.hintId >= 0) {
			this.toggleTooltip();
		}
	}

	private beforeUnmount() {
		this.showBubble = false;
	}

	private get hasNotSeen() {
		return (this.$store.state.unseenHintIds as Array<number>).includes(this.hintId);
	}

	@Watch('dontShowAgain')
	private ondontShowAgainChanged() {
		if (this.dontShowAgain) {
			this.setHintAsShown();
		}
	}

	private toggleTooltip() {
		if (!this.showBubble) {
			// Only show if the hint has not been seen by the user
			if (!this.hasNotSeen) return;
			this.setHintAsShownLocally();
		}
		this.showBubble = !this.showBubble;
	}

	private async setHintAsShown() {
		await httpClient().post(`customer/SetUserHintShown?hintId=${this.hintId}`, null);
	}

	private setHintAsShownLocally() {
		// Set hint as shown locally so that it doesn't pop up again in the current session
		this.$store.commit('removeUnseenHintId', this.hintId);
	}

}
