
import { OfferDto } from '@/dtos/offerDto';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OrderRequestDto } from '@/dtos/orderRequestDto';
import httpClient from '@/store/httpClient';
import { DeliveryType } from '@/dtos/deliveryType';
import { PendingOrderDto } from '@/dtos/pendingOrderDto';
import moment from 'moment';
import { PriceModel } from '@/dtos/priceModel';
import Delivery from '@/views/Checkout/Delivery.vue';
import { OrderDiscountDto } from '@/dtos/orderDiscountDto';
import { ManufacturingOfferDto } from '@/dtos/manufacturingOfferDto';

@Component({
    components: {
        Delivery
    }
})
export default class Offer extends Vue {
    @Prop()
    private priceModel!: PriceModel;
    private deliveryDate: Date = new Date();
    private selected: boolean = false;
    private anySelected: boolean = false;
    private isLoading: boolean = false;
    private isAuftragsrabattDialogVisible: boolean = false;
    private isNachAufwandDialogVisible: boolean = false;
    private isOrderFailedDialogVisible: boolean = false;

    private mounted() {
        this.setFirstDate();
    }

    private get isRampenabholung(): boolean {
        return this.$store.state.offer.deliveryChoice === 'rampe';
    }
    private selectedOfferChanged() {
        this.selected = false;
        this.anySelected = true;
    }

    @Watch('allowedDates')
    private setFirstDate() {
        if (this.allowedDates.length > 0) {
            this.deliveryDate = this.allowedDates[0];
        }
    }

    private async createOrder(requestOnly: boolean){
        if (!this.readyToBuy || this.priceModel == null) {
            return;
        }
        this.isLoading = true;
        var newOrderId = 0;
        var orderRequest = new OrderRequestDto();
        orderRequest.customerId = 0; // is set in backend
        orderRequest.deliveryAddress = this.$store.state.currentCart.deliveryAddress;
        orderRequest.invoiceAddress = this.$store.state.currentCart.invoiceAddress;
        orderRequest.calculationId = this.currentOffer.calculationId;
        orderRequest.selectedManufacturerId = this.selectedManufacturerId;
        orderRequest.rampenabholung = this.isRampenabholung;
        orderRequest.dueDate = this.deliveryDate;
        orderRequest.kommission = this.customKommissionsnummer;
        orderRequest.priceModel = this.priceModel;
        orderRequest.additionalOrderConfirmationEmail = this.$store.state.additionalOrderConfirmationEmail;
        
        try {            
            this.$store.commit('setLoading', true);
            if (requestOnly) {
                await httpClient().post('order/SaveOrder', orderRequest);
                newOrderId = -1;
            } else {
                const response = await httpClient().post('order/CreateOrder', orderRequest);
                newOrderId = response.data
            }
        } catch(ex) {
            console.error(`Fehler bei der Erstellung der Bestellung. ${ex}`);
        } finally {
            this.isLoading = false;
        }
        this.$store.commit('setLoading', false);
        if (newOrderId > 0 || newOrderId === -1) {
            this.$store.commit('setManualOfferRequested', false);  // reset, falls tatsächlich nach einer offert anfrage, gleich noch eine Bestellung folgt
            this.$store.commit('setOrderSuccessful', true);
            this.$store.dispatch('deletePendingOrdersInCart')
            this.$store.dispatch('resetOrderAfterSuccess');
        } else {
            this.isOrderFailedDialogVisible = true;
        }
    }

    private async downloadOffer(){
        var orderRequest = new OrderRequestDto();
        orderRequest.customerId = 0; // is set in backend
        orderRequest.deliveryAddress = this.$store.state.currentCart.deliveryAddress;
        orderRequest.invoiceAddress = this.$store.state.currentCart.invoiceAddress;
        orderRequest.calculationId = this.currentOffer.calculationId;
        orderRequest.selectedManufacturerId = this.selectedManufacturerId;
        orderRequest.rampenabholung = this.isRampenabholung;
        orderRequest.dueDate = this.deliveryDate;
        orderRequest.kommission = this.customKommissionsnummer;
        orderRequest.priceModel = this.priceModel;
        
        try {            
            this.$store.commit('setLoading', true);
            const response = await httpClient().post('offer/getOfferAsPdf', orderRequest,
            {
                responseType: 'blob'
            });

            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.download = 'Angebot ' + orderRequest.calculationId + '.pdf'
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)

        } catch(ex) {
            console.error(`Fehler beim Download des Pdf für das Angebot. ${ex}`);
        } finally {
            this.$store.commit('setLoading', false);
        }
        
    }

    private get readyToBuy() {
        // offer timed out
        if (this.$store.state.currentCart.hasChanged) return false;
        if (this.isOfferTimerZero) return false;
        // offer was invalidated because of article updates
        if (this.offerInvalidForCartUpdate) return false;
        // AGBs are not accepted (this is ok for "Bestellung beantragen")
        if (!this.acceptedAGB && this.canOrderDirectly) return false;
        // offer has delivery "nach Aufwand" and the checkbox was not ticked 
        // and it's not Rampenabholung (this is ok for "Bestellung beantragen")
        if (this.isDeliveryNachAufwand && !this.acceptedNachAufwand && this.canOrderDirectly && !this.isRampenabholung) return false;
        // chosen delivery date is invalid
        if (!this.isDeliveryDateValid) return false;
        if (this.allowedDates.length <= 0) return false;
        // this customer is from a country that is not serviced by Blexon.
        // we still want them to be able to see the offers (with discounts)
        // but they can't order. The offer-information-component will show an appropriate message.
        if (!this.isCountryValidForDelivery) return false;
        if (!this.currentlySelectedManufacturerMakesAutomaticOrders) return false;
        return true;
    }

    private redirectToArticles() {
        this.$router.push({ name: 'Catalogue' });
    }

    private navigateToAgb() {
        window.open('https://blexon.com/agb/', '_blank');
    }

    private get currentlySelectedManufacturerMakesAutomaticOrders() {
        const manufacturingOffer = this.$store.state.offer.manufacturingOffers
            .find((o: ManufacturingOfferDto) => o.manufacturerId === this.$store.state.offer.selectedManufacturerId)

        return manufacturingOffer.automaticOfferPossible
    }

    private get canOrderDirectly() {
        return this.$store.state.customer.user.canOrderDirectly;
    }
    private get canOrderIndirectly() {
        return this.$store.state.customer.user.canOrderIndirectly;
    }

    private get currentOffer() {
        return this.$store.state.offer as OfferDto;
    }
    
    private get selectedManufacturerId() {
        return this.$store.state.offer.selectedManufacturerId;
    }

    private get amountOfPositionsInCart(){
        return this.$store.state.currentCart.cartItems.length;
    }
    private get isBilledDigitally() {
        return this.$store.state.currentCart.invoiceAddress.isBilledDigitally;
    }
    private get isDeliveryNachAufwand() {
        return this.currentOffer.deliveryType == DeliveryType.NachAufwand;
    }
    private get addressString() {
        let invoiceAddress = this.$store.state.currentCart.invoiceAddress;
        return invoiceAddress.street + ' / ' + invoiceAddress.town;
    }
    private get lieferArtString() {
        if (this.currentOffer.deliveryType == DeliveryType.NachAufwand) {
            return ""
        }
        return DeliveryType[this.currentOffer.deliveryType];
    }
    private get isCountryValidForDelivery() {
        return this.$store.getters.customerIsCountryValidForDelivery;
    }


    private get allowedDates() {
        const manufacturingOffer = this.$store.state.offer.manufacturingOffers
            .find((offer: any) => offer.manufacturerId == this.$store.state.offer.selectedManufacturerId);
        const dates = [];
        if (manufacturingOffer != undefined) {
            const orderDiscount = this.$store.state.offer.orderDiscounts.find((discount: OrderDiscountDto) => discount.priceModel == this.priceModel);
            const validFrom = moment().add(orderDiscount.minimalDelayInDays, 'days');
            var validTo = moment().add(2, 'year');
            if (this.priceModel === PriceModel.Standard) {
                const orderDiscountSlow = this.$store.state.offer.orderDiscounts.find((discount: OrderDiscountDto) => discount.priceModel == PriceModel.Slow);
                if (orderDiscountSlow != null) {
                    validTo = moment().add(orderDiscountSlow.minimalDelayInDays - 1, 'days');
                }
            }
            for (const datum of manufacturingOffer.datesOfDelivery) {
                if (moment(datum).isAfter(validFrom) && moment(datum).isBefore(validTo)) {
                    dates.push(datum);
                }
            }
        }
        return dates;
    }

    private get isOfferTimerZero() {
        return this.$store.state.offerValidTimerSeconds <= 0;
    }
    private get isDeliveryDateValid() {
        return this.deliveryDate !== null && this.deliveryDate !== undefined;
    }
    private get customKommissionsnummer(){
        return this.$store.state.offer.customKommissionsnummer;
    }
    private set customKommissionsnummer(value: string){
        this.$store.commit('setCustomKommissionsnummer', value);
    }

    private get acceptedNachAufwand(){
        return this.$store.state.acceptedNachAufwand;
    }
    private set acceptedNachAufwand(value: string){
        this.$store.commit('setAcceptedNachAufwand', value);
    }

    private get acceptedAGB(){
        return this.$store.state.acceptedAGB;
    }
    private set acceptedAGB(value: string){
        this.$store.commit('setAcceptedAGB', value);
    }

    private get wasOrderedSuccessful() {
        return this.$store.state.orderSuccessful;
    }
    private get pendingOrders() {
        return this.$store.state.pendingOrders;
    }
    private getLabelForPendingOrder(pendingOrder: PendingOrderDto) {
        return `Offene Bestellung vom ${moment(String(pendingOrder.requestedAt)).format('DD.MM.YYYY')} von ` +
                `${pendingOrder.requestedByFirstname} ${pendingOrder.requestedByLastname}`
    }
    
    // ANGEBOT CALCULATIONS
    private get waehrung() {
        return this.$store.getters.waehrung;
    }
    private get totalOfCartPositions() {
        return this.$store.getters.totalOfAllItemsInCart;
    }
    private get orderDiscount() {
        return this.$store.getters.orderDiscount(this.priceModel);
    }
    private get mwstRatio() {
        if (this.isDeliveryDateValid) {
            return this.$store.getters.mwstRatio(this.deliveryDate);
        }
        return "N/A";
    }
    private get mwstCost() {
        if (this.isDeliveryDateValid) {
            return this.$store.getters.costMwst(this.priceModel, this.deliveryDate);
        }
        return "N/A";
    }
    private get deliveryCost() {
        return this.$store.getters.deliveryCost;
    }
    private get invoiceCharge() {
        return this.$store.getters.invoiceCharge;
    }
    private get nettoOffer() {
        return this.$store.getters.nettoOffer(this.priceModel);
    }
    private get totalOffer() {
        if (this.isDeliveryDateValid) {
            return this.$store.getters.totalOffer(this.priceModel, this.deliveryDate);
        }
        return "N/A";
    }

    // VALIDATION
    private get offerInvalidForCartUpdate() {
        return this.$store.state.offerInvalidForCartUpdate;
    }

}
