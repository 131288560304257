
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import UserSettings from '@/views/Settings/UserSettings.vue'
import CustomerSettings from '@/views/Settings/CustomerSettings.vue'
import AddressesOverview from '@/views/Settings/AddressesOverview.vue'
import { AddressType } from '@/dtos/addressType'
import { ErpAddressType } from '@/dtos/erpAddressType';

@Component({
    components: {
        UserSettings,
        CustomerSettings,
        AddressesOverview
    }
})
export default class Settings extends Vue {
    @Prop()
    private tabName!: string;

    private tab = 0;
    private deliveryType: ErpAddressType = ErpAddressType.Delivery;
	private invoiceType: ErpAddressType = ErpAddressType.Invoice;

    private readonly tabTypes: { key: string, display: string }[] = [
        {
            key: "user",
            display: "Benutzereinstellungen",
        },
        {
            key: "customer",
            display: "Kundenkonto",
        },
        {
            key: "delivery",
            display: "Lieferadressen",
        },
        {
            key: "invoice",
            display: "Rechnungsadressen",
        },
    ]

    private mounted() {
        if(this.tabName && this.tabTypes.some((type:any) => type.key === this.tabName)){
            this.tab = this.tabTypes.findIndex((type:any) => type.key === this.tabName);
        }
    }
}
