var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "ma-0": "" } },
    [
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _vm.savedCartsExist
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "5", lg: "6" } },
                [_c("saved-carts")],
                1
              )
            : _vm._e(),
          _vm.savedCartsExist
            ? _c("v-divider", {
                staticStyle: { "margin-left": "-1px" },
                attrs: { vertical: "", inset: "" },
              })
            : _vm._e(),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: _vm.savedCartsExist ? 7 : 12,
                lg: _vm.savedCartsExist ? 6 : 12,
              },
            },
            [_c("current-cart")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }