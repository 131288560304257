
import { Component, Prop, Vue } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import { AddressDto } from '@/dtos/addressDto';
import ChangeAddress from '@/views/Settings/ChangeAddress.vue'
import { ErpAddressType } from '@/dtos/erpAddressType';
import { AddressType } from '@/dtos/addressType';

@Component({
    components: {
		ChangeAddress
    }
})
export default class AddressTile extends Vue {
	@Prop()
	private propAddress!: AddressDto;
	@Prop()
	private type!: AddressType;
	@Prop()
	private isOrder!: boolean;
	private address: AddressDto = new AddressDto();
	private deliveryType: ErpAddressType = ErpAddressType.Delivery;
	private invoiceType: ErpAddressType = ErpAddressType.Invoice;
	private currentEditAddress: AddressDto = new AddressDto();
	private editAddressDialog: boolean = false;
	private deleteAddressDialog: boolean = false;
	private userCanChangeDeliveryAddress: boolean = false;
	private userCanChangeInvoiceAddress: boolean = false;

    private mounted() {
		this.setRights();
		let address = JSON.parse(JSON.stringify(this.propAddress));
		this.address = Object.assign(new AddressDto, address);
    }

	private get hasName() {
		return this.address.givenName !== '' || this.address.surname !== '';
	}

	private get canEditAddress() {
		if (this.address.type == this.deliveryType) {
			return this.userCanChangeDeliveryAddress;
		} 
		if (this.address.type == this.invoiceType) {
			return this.userCanChangeInvoiceAddress;
		}
		return false;
	}

	private get isUserPreferred() {
		if (this.address.type == this.deliveryType) {
			return this.$store.getters.userPreferredDeliveryAddress.id === this.address.id;
		} 
		if (this.address.type == this.invoiceType) {
			return this.$store.getters.userPreferredInvoiceAddress.id === this.address.id;
		}
		return false;
	}

	private get isNotOrder() {
		return this.type != AddressType.OrderInvoice && this.type != AddressType.OrderDelivery;
	}

	private get isLastAddress() {
		if (this.address.type == this.deliveryType) {
			return this.$store.getters.customerDeliveryAddressCount <= 1;
		} 
		if (this.address.type == this.invoiceType) {
			return this.$store.getters.customerInvoiceAddressCount <= 1;
		}
		return false;
	}

	private async setRights() {
		const deliveryResponse = await httpClient().get('Customer/CanUserChangeDeliveryAddress');
		const InvoiceResponse = await httpClient().get('Customer/CanUserChangeInvoiceAddress');
        this.userCanChangeDeliveryAddress = deliveryResponse.data;
        this.userCanChangeInvoiceAddress = InvoiceResponse.data;
	}

	private openEditDialog() {
		this.currentEditAddress = JSON.parse(JSON.stringify(this.address));
		this.editAddressDialog = true;
	}

	private async deleteAddress() {
		await this.$store.dispatch("deleteAddress", this.address.id);
		this.$emit("removedAddress", this.address.id);
		this.deleteAddressDialog = false;
	}

	private changeUserPreferred() {
		if (this.isUserPreferred) return;
		const addressData = {
			id: this.address.id,
			type: this.address.type == ErpAddressType.Delivery ? 'delivery' : 'invoice',
		}
		this.$store.dispatch("setAddressAsUserPreferred", addressData);
		this.setAddressForOrder();
	}

	private setAddressForOrder() {
		switch (this.address.type) {
			case ErpAddressType.Delivery:
				this.$store.commit('setOrderDeliveryAddress', this.address);
				break;
			case ErpAddressType.Invoice:
				this.$store.commit('setOrderInvoiceAddress', this.address);
				break;
			default:
				throw 'Adresstyp ist ungültig';
		}
		this.$emit("setAddressForOrder");

	}

	private closeEditDialogAndReload(params: any) {
		let address: AddressDto = params.address;
		let changed: boolean = params.changed;
		if (changed) {
			let newAddress = JSON.parse(JSON.stringify(address));
			this.address = Object.assign(new AddressDto, newAddress);
		}
		this.editAddressDialog = false;
	}

}

