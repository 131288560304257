
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import * as path from 'path';
import httpClient from '../../store/httpClient';
import ArticleTile from './ArticleTile.vue';
import AssemblyTile from './AssemblyTile.vue';
import ImportArticle from './ImportArticle.vue';
import ImportAssembly from './ImportAssembly.vue';
import PendingOrder from './PendingOrder.vue';
import MultiEdit from './Multiedit.vue';
import { CatalogueSortingType } from '../../dtos/catalogueSortingType';
import { CatalogueSortingDto } from '../../dtos/catalogueSortingDto';
import { FileSizeLimit } from '@/dtos/fileSizeLimit';
import { ImportFileExtension } from '@/dtos/importFileExtension';
import { PendingOrderDto } from '@/dtos/pendingOrderDto';
import { CustomerArticleDto } from '@/dtos/customerArticleDto';

@Component({
    components: {
        ArticleTile,
        AssemblyTile,
        ImportArticle,
        ImportAssembly,
        PendingOrder,
        MultiEdit,
    }
})
export default class Catalogue extends Vue {
    private lastSearchTerm: string = '';
    private loadBatchSize: number = 22;
    private articleLoading: boolean = false;
    private floatingFilter: boolean = false;
    private initialLoadIsRunning: boolean = false;
    private uploadWarningDialog: boolean = false;
    private errorUploadFiles: string[] = [];
    private warningUploadFiles: string[] = [];
    private invalidFormatUploadFiles: string[] = [];
    private dragging: boolean = false;
    private validFileFormats: Array<string> = new Array<string>();
    
    private sortTypes: CatalogueSortingDto[] = [
        {
            text: 'Neu Erstellte zuerst',
            value: CatalogueSortingType.CreatedAtDescending
        },
        {
            text: 'Neu Erstellte zuletzt',
            value: CatalogueSortingType.CreatedAtAscending
        },
        {
            text: 'Zuletzt Geänderte zuerst',
            value: CatalogueSortingType.LastChangeDescending
        },
        {
            text: 'Zuletzt Geänderte zuletzt',
            value: CatalogueSortingType.LastChangeAscending
        },
        {
            text: 'Nach Artikelname A - Z',
            value: CatalogueSortingType.ArticleNameAscending
        },
        {
            text: 'Nach Artikelname Z - A',
            value: CatalogueSortingType.ArticleNameDescending
        },
        {
            text: 'Nach Materialbezeichnung A - Z',
            value: CatalogueSortingType.MaterialNameAscending
        },
        {
            text: 'Nach Materialbezeichnung Z - A',
            value: CatalogueSortingType.MaterialNameDescending
        },
        {
            text: 'Nach Materialdicke (dünne zuerst)',
            value: CatalogueSortingType.MaterialThicknessAscending
        },
        {
            text: 'Nach Materialdicke (dicke zuerst)',
            value: CatalogueSortingType.MaterialThicknessDescending
        }
    ]

    beforeRouteEnter(to: any, from: any, next: any) {
        next((vm: any) => {
            window.addEventListener('scroll', vm.checkCatalogueBottom);
        });
    }

    beforeRouteLeave(to: any, from: any, next: any) {
        window.removeEventListener('scroll', this.checkCatalogueBottom);
        next();
    }

    private async mounted() {
        this.$store.commit('clearSelectedArticleIds');
        this.initialLoading();
    }

    private get searchTerm() : string {
        return this.$store.state.catalogueSearchTerm;
    }
    private set searchTerm(value: string) {
        this.$store.commit('setCatalogueSearchTerm', value);
    }

    private get selectedUserId() : string {
        return this.$store.state.catalogueUserId;
    }
    private set selectedUserId(value: string) {
        this.$store.commit('setCatalogueUserId', value);
    }

    private get catalogueArticleIds() : number[] {
        return (this.$store.state.catalogueArticles as number[]);
    }

    private get catalogueArticles() : CustomerArticleDto[] {
        const allArticlesInCatalog = this.catalogueArticleIds.map(c => this.$store.state.articles
            .find((a: CustomerArticleDto) => a.articleId == c)) as CustomerArticleDto[];
        return allArticlesInCatalog.filter(a => a.parentArticleId == null)
    }

    private get importingArticles() {
        return this.$store.getters.importingArticles;
    }

    private get isLoading() {
        return this.$store.state.loading;
    }

    private get hasMoreToLoad() {
        return this.$store.state.hasMoreArticlesToLoad;
    }
    private set hasMoreToLoad(hasMoreToLoad: boolean) {
        this.$store.commit('setHasMoreArticlesToLoad', hasMoreToLoad);
    }

    private get showFullscreenImport() {
        return (
            !this.isLoading
            && this.$store.state.articles.length <= 0
            && this.importingArticles.length <= 0
            && (this.searchTerm === undefined || this.searchTerm === '' || this.searchTerm.length < 3)
        )
    }
    private get hasArticles() {
        return this.$store.state.articles.length > 0
            || (this.searchTerm !== undefined && this.searchTerm.length >= 3)
    }

    private get activeSortType() {
        var type = this.sortTypes.find(s => s.value == this.$store.state.activeCatalogueSortTypeValue);
        if (type === undefined) {
            return this.sortTypes[2];
        } else {
            return type;
        }
    }
    private set activeSortType(sortType: CatalogueSortingDto) {
        this.$store.commit('setActiveCatalogueSortTypeValue', sortType.value);
    }

    private get dragAreaHeightStyle() {
        return this.showFullscreenImport ? 'height: calc(100vh - 128px);min-height:300px' : ''
    }
    private get dragAreaContentClass() {
        return this.showFullscreenImport? 'verticalInDragArea' : ''
    }
    private get pendingOrders() {
        return this.$store.state.pendingOrders.filter((o: PendingOrderDto) => o.isInCart == false);
    }
    private get hasMultipleUsers() {
        // Greater than 2 because of the "Alle Benutzer" user that is always created
        return this.users !== undefined && this.users.length > 2;
    }
    private get users() {
        return this.$store.state.users;
    }

    private async initialLoading() {
        this.$store.commit('setLoading', true);
        this.initialLoadIsRunning = true;
        await this.getImportingArticlesOfCustomer();
        if (this.$store.state.catalogueArticles.length <= 0) {
            await this.getArticlesOfCustomer(true);
        }
        this.$store.commit('setLoading', false);
        this.initialLoadIsRunning = false;
        if (this.$store.state.customer.user.canOrderDirectly) {
            this.loadPendingOrders();
        }
    }

    private async loadPendingOrders() {
        try {
            const response = await httpClient().get(`order/GetPendingOrders`);
            this.$store.commit('setPendingOrders', response.data);
        } catch(e) {
            console.error(e);
        }
    }

    private async getArticlesOfCustomer(reload: boolean) {
        try {
            this.articleLoading = true;
            this.$store.commit('setLoading', true);
            // Only send searchTerm if it has more than 2 characters. Otherwise loading won't work.
            var relevantSearchTerm = this.searchTerm.length >= 3 ? this.searchTerm : '';
            var from = this.catalogueArticleIds.length;
            if (reload) {
                from = 0;
            }
            const response = await httpClient().get(`article/getArticlesOfCustomer?` +
                `searchTerm=${encodeURI(relevantSearchTerm)}` +
                `&from=${from}` +
                `&to=${from + this.loadBatchSize}` +
                `&sorting=${this.activeSortType.value}` +
                `&userId=${this.selectedUserId === '-1' ? '' : this.selectedUserId}`);
            response.data.forEach((article: any) => {
                article.calculatingMessage = '';
            });
            this.$store.commit('addOrUpdateArticles', response.data);
            if (!reload && from > 0) {
                this.$store.commit('setCatalogueArticles', {
                    articles: response.data,
                    reload: false,
                });
            } else {
                this.$store.commit('setCatalogueArticles', {
                    articles: response.data,
                    reload: true,
                });
            }
            if (response.data.length === this.loadBatchSize) {
                // wenn alle geladen wurden, gibt es wahrscheinlich noch mehr
                this.hasMoreToLoad = true;
            } else {
                this.hasMoreToLoad = false;
            }
        } catch(err) {
            console.log(`Fehler beim Laden der Artikel: ${err}`);
        } finally {
            this.articleLoading = false;
            if (!this.initialLoadIsRunning) {
                this.$store.commit('setLoading', false);
            }
        }
    }

    private async getImportingArticlesOfCustomer() {
        try {
            const response = await httpClient().get(`article/getImportingArticlesOfCustomer`);
            response.data.forEach((article: any) => article.calculatingMessage = '');
            this.$store.commit('setImportingArticles', response.data);
        } catch(err) {
            console.log(`Fehler beim Laden der Artikel mit Import Status: ${err}`);
        }
    }

    @debounce(700, { leading: false })
    @Watch('searchTerm')
    private reloadOnSearchTermChange() {
        if (!this.articleLoading && !this.initialLoadIsRunning
            && this.lastSearchTerm !== this.searchTerm
            && (this.searchTerm.length >= 3 || this.searchTerm.length === 0)) {                
            this.lastSearchTerm = this.searchTerm;            
            this.getArticlesOfCustomer(true);
        }
    }
    @Watch('activeSortType')
    private reloadOnSortingChange() {
        if (!this.articleLoading && !this.initialLoadIsRunning) {
            this.getArticlesOfCustomer(true);
        }
    }
    @Watch('selectedUserId')
    private reloadOnUserChange() {
        if (!this.articleLoading && !this.initialLoadIsRunning) {
            this.getArticlesOfCustomer(true);
        }
    }

    private checkCatalogueBottom () {
        let bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2
        if (bottomOfWindow && this.hasMoreToLoad && !this.isLoading) {
            this.getArticlesOfCustomer(false);
        }
        var element = document.getElementById('artikelListe') as any;
        var elementPosition = element.getBoundingClientRect();
        if (elementPosition.y - 144 < 0) {
            this.floatingFilter = true;
        } else {
            this.floatingFilter = false;
        }
    }

    private manuallyLoadMoreArticles() {
        if (this.hasMoreToLoad && !this.isLoading) {
            this.getArticlesOfCustomer(false);
        }
    }

    private get fileSizeLimits() {
        return this.$store.state.fileSizeLimits;
    }

    dragover(event: any) {
        event.preventDefault();
        this.dragging = true;
        (this.$refs.upload as any).parentNode.parentNode.className = 'dragArea dragging';
    }
    dragleave(event: any) {
        this.dragging = false;
        (this.$refs.upload as any).parentNode.parentNode.className = 'dragArea';
    }
    drop(event: any) {
        event.preventDefault();
        this.uploadFiles(null, event.dataTransfer.files);
        (this.$refs.upload as any).parentNode.parentNode.className = 'dragArea';
    }

    private async uploadFiles(event: any, files: FileList) {
        console.log('uploading files...');        
        this.errorUploadFiles = [];
        this.warningUploadFiles = [];
        this.invalidFormatUploadFiles = [];
        if (this.validFileFormats.length === 0) {
            // Load valid file formats if not present yet
            await this.loadValidFileFormats();
        }        
        if (files.length === 0) {
            return;
        }
        if (files.length > 10) {
            this.$store.dispatch('setSnackbarText', 'Sie können maximal 10 CAD Modelle gleichzeitig hochladen.');
            return;
        }
        for (const file of files) {
            // Skip invalid file extensions
            let extension = path.extname(file.name).slice(1).toLowerCase();
            if (!this.validFileFormats.map((format:string) => format.toLocaleLowerCase()).includes(extension)) {
                this.invalidFormatUploadFiles.push(file.name);
                continue;
            }
            // Skip very large files or warn for large files
            if (this.fileExceedesLimit(file.name, file.size, 'error')) {
                this.errorUploadFiles.push(file.name);
                continue;
            } else if (this.fileExceedesLimit(file.name, file.size, 'warning')) {
                this.warningUploadFiles.push(file.name);
            }
            const artikel = {
                articleId: this.$store.getters.temporaryImportId,
                state: 4,
                uploadProgress: 0,
                customerArticleId: '',
                customerArticleName: '',
                thickness: 0,
                calculatingMessage: '',
                materialId: 0,
                calculating: true,
                deleteOverlay: false,
                deleting: false,
            };
            this.$store.commit('nextTemporaryImportId');

            this.$store.commit('addImportingArticle', artikel);

            // Sicherstellen, dass DOM gerendet ist, bevor die Hintergrundprozesse starten
            Vue.nextTick(() => {

                const data = new FormData();
                data.append('file', file);
                const config = {
                    header : {
                        'Content-Type' : 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        artikel.uploadProgress = percentCompleted;
                    },
                };

                httpClient().post(
                    'Article/UploadCadModel',
                    data,
                    config,
                ).then((response: any) => {
                    response.data.calculatingMessage = '';
                    this.$store.commit('updateImportingArticle', {
                        articleToReplace: artikel,
                        newArticle: response.data,    
                    });
                }).catch((error: any) => {
                    console.log('Fehler beim Upload');
                });

            });
        }
        if (this.errorUploadFiles.length > 0 || this.warningUploadFiles.length > 0 || this.invalidFormatUploadFiles.length > 0) {
            this.uploadWarningDialog = true;
        }
        if (event != null) {
            event.target.value = '';
        }
    }

    private fileExceedesLimit(name: string, size: number, limitType: string) {
        // If the file is bigger than the highest error limit, return true directly regardless of file extension.
        if (size > this.fileSizeLimits.filter((f: FileSizeLimit) => f.extension === ImportFileExtension.Step)[0].errorLimit) {
            return true;
        }
        let re = /(?:\.([^.]+))?$/;
        if (name === null) {
            return false;
        }
        let extensionGroup = re.exec(name);
        let extension = '';
        if (extensionGroup === null || extensionGroup.length < 2) {
            return false;
        } else {
            extension = extensionGroup[1];
        }
        let result = false
        this.fileSizeLimits.forEach((f: FileSizeLimit) => {
            if (f.extensions.some((e: string) => e === extension.toLowerCase())) {
                if (limitType === 'error') {
                    if (size > f.errorLimit) {
                        result = true;
                    }
                } else if (limitType === 'warning') {
                    if (size > f.warningLimit) {
                        result = true;
                    }
                }
            }
        });
        return result;
    }

    private get user() {
        return this.$store.state.customer.user;
    }

    private removePendingOrder(orderId: number) {
        this.$store.commit('removePendingOrder', orderId);
    }

    private async loadValidFileFormats() {
        let result = await httpClient().get('Article/GetValidArticleFileFormats');
        this.validFileFormats = result.data;
    }

}

