
import { Component, Prop, Vue, } from 'vue-property-decorator';

@Component
export default class TooManyCalculatingDialog extends Vue {
	@Prop()
	private tooManyCalculatingDialog!: boolean;

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}
}

