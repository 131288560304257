
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CartItem } from '@/dtos/cartItem';

@Component
export default class CartTabMenuItem extends Vue {
    @Prop()
    private cartItem!: CartItem;

    private get article() {
        return this.$store.getters.articleById(this.cartItem.articleId);
    }

    private get thumbnailUrl() {
        return this.$store.getters.getThumbnailUrl(this.cartItem.articleId, undefined);
    }

    private removeFromCart() {        
        this.$store.dispatch('removeArticleFromCart', this.cartItem.articleId);        
    }

}
