
import { Component, Prop, Vue } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import { AddressDto } from '@/dtos/addressDto';
import AddressTile from '@/views/Settings/AddressTile.vue'
import ChangeAddress from '@/views/Settings/ChangeAddress.vue'
import { ErpAddressType } from '@/dtos/erpAddressType';
import { AddressType } from '@/dtos/addressType';

@Component({
    components: {
		AddressTile,
		ChangeAddress,
    }
})
export default class AddressesOverview extends Vue {
	@Prop()
	private type!: ErpAddressType;
	@Prop()
	private isOrder!: boolean;
	private addresses: AddressDto[] = [];
	private newAddress: AddressDto = new AddressDto();
	private addressLoaded: boolean = false;
	private deliveryType: ErpAddressType = ErpAddressType.Delivery;
	private invoiceType: ErpAddressType = ErpAddressType.Invoice;
	private customerDeliveryType: AddressType = AddressType.CustomerDelivery;
	private customerInvoiceType: AddressType = AddressType.CustomerInvoice;
	private newAddressdialog: boolean = false;
	private userCanCreateAddresses: boolean = false;

    private async mounted() {		
		this.setAddress();
		switch(this.type) {
			case this.deliveryType:
				const deliveryResponse = await httpClient().get('Customer/CanUserChangeDeliveryAddress');
				if(deliveryResponse.data) {
					this.userCanCreateAddresses = true;
				}
				break;
			case this.invoiceType:
				const invoiceResponse = await httpClient().get('Customer/CanUserChangeInvoiceAddress');
				if(invoiceResponse.data) {
					this.userCanCreateAddresses = true;
				}
				break;
			default:
				break;
		}

    }

	private setAddress() {
		let copyAddresses: AddressDto[] = [];
		switch(this.type) {
			case this.deliveryType:
				copyAddresses = JSON.parse(JSON.stringify(this.$store.state.customer.deliveryAddresses));
				this.addresses = copyAddresses;
				break;
			case this.invoiceType:
				copyAddresses = JSON.parse(JSON.stringify(this.$store.state.customer.invoiceAddresses));
				copyAddresses.forEach((a:AddressDto) => a.isBilledDigitally = a.eMail !== null && a.eMail !== '');
				this.addresses = copyAddresses;
				break;
			default:
				this.addresses = [];
				break;
		}
		this.addressLoaded = true;
	}

	private createNewAddress() {
		if(!this.userCanCreateAddresses) return;
		this.newAddress = new AddressDto();
		this.newAddress.customerId = this.$store.getters.customerId;
		this.newAddress.type = this.type;
		this.newAddress.country = this.$store.getters.customerDefaultDeliveryAddress.country;
		this.newAddress.eMail = this.$store.getters.customerDefaultInvoiceAddress.eMail;
		this.newAddress.isBilledDigitally = true;

		this.newAddressdialog = true;
	}

	private closeEditDialogAndReload(params: any) {
		let address: AddressDto = params.address;
		let changed: boolean = params.changed;
		if (changed) {
			this.setAddress();
		}
		this.newAddressdialog = false;
	}

	private setAddressForOrder() {
		this.$emit("setAddressForOrder");
	}

}
