import './class-component-hooks' // needs to be imported before any components
import Vue from 'vue';
import './assets/main.css';
// @ts-ignore
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store/store';
import Moment from 'moment';
import VueGtm from '@gtm-support/vue2-gtm';
import './log.js';
import FloatingVue, { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'

Vue.filter('formatDate', (value: any) => {
    if (value) {
        return Moment(String(value)).format('DD.MM.YYYY');
    }
});

Vue.filter('formatDateTime', (value: any) => {
    if (value) {
        return Moment(String(value)).format('DD.MM.YYYY HH:mm');
    }
});

Vue.filter('formatTimespan', (value: any) => {
    if (value) {
        return Moment(String(value)).format('hh:mm:ss');
    }
});

Vue.filter('round2Decimal', (value: number) => {
    if (value !== undefined && value !== null) {
        value = Math.round(value * 100) / 100;
        return value.toFixed(2);
    }
});

Vue.filter('round3Decimal', (value: number) => {
    if (value !== undefined && value !== null) {
        value = Math.round(value * 1000) / 1000;
        return value.toFixed(3);
    }
});

Vue.filter('format2Digits', (value: number) => {
    if (value !== undefined && value !== null) {
        return value.toLocaleString(undefined, {minimumIntegerDigits: 2});
    }
});

Vue.filter('format2Decimal', (value: number) => {
    if (value !== undefined && value !== null) {
        return value.toFixed(2);
    }
});

Vue.config.productionTip = false;
console.log('running: ' + process.env.VUE_APP_MODE);

Vue.use(VueGtm, {
    id: 'GTM-KV2VNTX', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  });

// Floating Vue used for hint tooltips
// https://floating-vue.starpad.dev/
Vue.use(FloatingVue);

// Checking if this request is a logout request
// eg. if the user changes their email address they are
// routed to a shop-logout.
// If they are not logged in, they must be routed to the login mask.
const urlHash = window.location.hash;
let isLogoutRedirect = false;
console.log(window.location.hash);
if (urlHash === '#/Logout' || urlHash === '#/Logout/') {
    isLogoutRedirect = true;
}

// Setup main page
store.dispatch('bootstrap')
    .then(async () => {
        console.log('Successfully bootstrapt application');
        if (isLogoutRedirect) {
            store.dispatch('logout');                
        } else {
            store.dispatch('checkAccess')
                .then(async () => {
                    store.dispatch('setupSignalR')
                        .then(() => {
                            const app = new Vue({
                                router,
                                store,
                                vuetify,
                                render: h => h(App)
                            }).$mount('#app');
                        })
                })
                .catch((e) => {
                    console.log('Keine Berechtigung');
                    store.dispatch('logout');
                });
        }
    }).catch((e) => console.log(e));

