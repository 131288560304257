var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.pendingOrders.length > 0
        ? _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-2", attrs: { cols: "12" } }, [
                _c("h1", { staticClass: "text-h5" }, [
                  _vm._v("Offene Bestellungen"),
                ]),
                _c("p", { staticClass: "text-body-2" }, [
                  _vm._v(
                    " Die folgenden Bestellungen wurden von den jeweiligen Benutzern beantragt, aber noch nicht freigegeben. "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.pendingOrders, function (order) {
        return _c("pending-order", {
          key: order.id,
          attrs: { pendingOrder: order },
          on: {
            deleted: function ($event) {
              return _vm.removePendingOrder($event)
            },
          },
        })
      }),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "dragArea",
                style: _vm.dragAreaHeightStyle,
                on: {
                  dragover: _vm.dragover,
                  dragleave: _vm.dragleave,
                  drop: _vm.drop,
                },
              },
              [
                _c(
                  "div",
                  { class: _vm.dragAreaContentClass },
                  [
                    _c("input", {
                      ref: "upload",
                      attrs: {
                        type: "file",
                        multiple: "",
                        accept: ".step, .stp, .dxf",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.uploadFiles($event, $event.target.files)
                        },
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-2 blexon-button",
                        attrs: { title: "Artikel auswählen und hochladen." },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$refs.upload.click()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-upload")])],
                      1
                    ),
                    _c("br"),
                    !_vm.showFullscreenImport
                      ? _c("p", { staticClass: "ma-0 pa-0 text-caption" }, [
                          _c("span", { staticClass: "text-overline" }, [
                            _vm._v("Artikel Import"),
                          ]),
                          _c("br"),
                          _vm._v(
                            " Maximal 10 Dateien (.step / .stp / .dxf) in diesen Bereich ziehen oder den Knopf drücken "
                          ),
                        ])
                      : _c("p", { staticClass: "ma-0 pa-0" }, [
                          _c("span", { staticClass: "text-h6" }, [
                            _vm._v("Artikel Import"),
                          ]),
                          _c("br"),
                          _c("br"),
                          _vm._v(" Noch etwas leer hier..."),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            " Importieren Sie Ihre CAD Modelle im Format .step oder .dxf indem Sie die Dateien auf diese Fläche ziehen oder klicken Sie auf den Knopf oben, um die Dateien auszuwählen."
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            " Blexon importiert anschliessend Ihre Teile, prüft sie auf Machbarkeit und berechnet automatisch die Preise."
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            " Das Video zeigt die ersten Schritte mit Blexon "
                          ),
                          _c("iframe", {
                            staticClass: "video",
                            style: {
                              visibility: _vm.dragging ? "hidden" : "visible",
                            },
                            attrs: {
                              src: "https://www.youtube.com/embed/ey5Rq8y70KU",
                              heigth: "720",
                              title:
                                "So einfach laden Sie CAD Modelle zu Blexon hoch und bestellen direkt online lasergeschnittene Blechteile nach Maß",
                              frameborder: "0",
                              allowfullscreen: "",
                            },
                          }),
                        ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._l(_vm.importingArticles, function (article) {
        return _c(
          "v-row",
          { key: article.articleId },
          [
            !article.isAssembly && article.parentArticleId == null
              ? _c("import-article", {
                  attrs: { articleId: article.articleId },
                })
              : _vm._e(),
            article.isAssembly
              ? _c("import-assembly", {
                  attrs: { articleId: article.articleId },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.hasArticles
        ? _c(
            "v-row",
            {
              staticClass: "mt-5 ustify-center align-start",
              class: { floatingFilter: _vm.floatingFilter },
              attrs: { wrap: "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-1",
                  attrs: {
                    cols: "12",
                    sm: "6",
                    md: _vm.hasMultipleUsers ? 4 : 6,
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Artikel suchen:",
                      placeholder: "Artikelname oder -nummer eingeben...",
                      color: "accent",
                      "prepend-icon": "mdi-magnify",
                    },
                    model: {
                      value: _vm.searchTerm,
                      callback: function ($$v) {
                        _vm.searchTerm = $$v
                      },
                      expression: "searchTerm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-1",
                  attrs: {
                    cols: "12",
                    sm: "6",
                    md: _vm.hasMultipleUsers ? 4 : 6,
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.sortTypes,
                      "return-object": "",
                      label: "Sortierung:",
                      color: "accent",
                    },
                    model: {
                      value: _vm.activeSortType,
                      callback: function ($$v) {
                        _vm.activeSortType = $$v
                      },
                      expression: "activeSortType",
                    },
                  }),
                ],
                1
              ),
              _vm.hasMultipleUsers
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-1",
                      attrs: { cols: "12", sm: "12", md: "4" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.users,
                          "item-text": "name",
                          "item-value": "userId",
                          label: "Artikel anzeigen von:",
                          color: "accent",
                        },
                        model: {
                          value: _vm.selectedUserId,
                          callback: function ($$v) {
                            _vm.selectedUserId = $$v
                          },
                          expression: "selectedUserId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("multi-edit"),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap",
            gap: "15px 15px",
          },
          attrs: { id: "artikelListe" },
        },
        _vm._l(_vm.catalogueArticles, function (article) {
          return _c(
            "div",
            {
              key: article.articleId,
              staticStyle: {
                "min-width": "400px",
                "max-width": "600px",
                flex: "1 1 0",
              },
            },
            [
              _c("article-tile", {
                attrs: {
                  articleId: article.articleId,
                  isAssembly: article.isAssembly,
                  isAssemblyView: false,
                  isDeleteDisabled: false,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.hasMoreToLoad
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-0", attrs: { cols: "12" } },
                [
                  !_vm.articleLoading
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            title:
                              "Lädt weitere Artikel mit den aktuellen Filtereinstellungen.",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.manuallyLoadMoreArticles()
                            },
                          },
                        },
                        [
                          _vm._v(" Mehr laden "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-chevron-down"),
                          ]),
                        ],
                        1
                      )
                    : _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "accent" },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.uploadWarningDialog,
            callback: function ($$v) {
              _vm.uploadWarningDialog = $$v
            },
            expression: "uploadWarningDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Inkompatible Dateien")]),
              _c("v-card-text", [
                _vm.invalidFormatUploadFiles.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " Folgende Dateien haben ein ungültiges Format und können nicht hochgeladen werden: "
                        ),
                        _vm._l(
                          _vm.invalidFormatUploadFiles,
                          function (file, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "mb-0" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "1em" } },
                                  [_vm._v("- " + _vm._s(file))]
                                ),
                              ]
                            )
                          }
                        ),
                        _c("br"),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.invalidFormatUploadFiles.length > 0 &&
                (_vm.warningUploadFiles.length > 0 ||
                  _vm.errorUploadFiles.length > 0)
                  ? _c("div", [_c("v-divider"), _c("br")], 1)
                  : _vm._e(),
                _vm.errorUploadFiles.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " Folgende Dateien überschreiten die maximal erlaubte Dateigröße und können nicht hochgeladen werden: "
                        ),
                        _vm._l(_vm.errorUploadFiles, function (file, index) {
                          return _c("p", { key: index, staticClass: "mb-0" }, [
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "1em" } },
                              [_vm._v("- " + _vm._s(file))]
                            ),
                          ])
                        }),
                        _c("br"),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.errorUploadFiles.length > 0 &&
                _vm.warningUploadFiles.length > 0
                  ? _c("div", [_c("v-divider"), _c("br")], 1)
                  : _vm._e(),
                _vm.warningUploadFiles.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " Folgende Dateien sind sehr groß und werden nicht automatisch weiterverarbeitet: "
                        ),
                        _vm._l(_vm.warningUploadFiles, function (file, index) {
                          return _c("p", { key: index, staticClass: "mb-0" }, [
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "1em" } },
                              [_vm._v("- " + _vm._s(file))]
                            ),
                          ])
                        }),
                        _c("br"),
                        _vm._v(
                          " Bitte definieren Sie für diese Artikel den passenden Werkstoff und die korrekte Dicke. Nach dem Blexonisieren werden die Artikel Ihrem Katalog hinzugefügt. Von dort können Sie die Artikel an unser Helpdesk zur Überprüfung senden. "
                        ),
                        _c("br"),
                        _c("br"),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.errorUploadFiles.length > 0 ||
                _vm.warningUploadFiles.length > 0
                  ? _c(
                      "div",
                      [
                        _c("v-divider"),
                        _c("br"),
                        _vm._v(
                          " Weitere Informationen zu den erlaubten Dateigrößen und zu den Ursachen von großen CAD Modellen erhalten Sie in unserem "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://blexon.com/grosse-cad-modelle-hochladen/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Blog")]
                        ),
                        _vm._v(". "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.uploadWarningDialog = false
                        },
                      },
                    },
                    [_vm._v(" Schliessen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }