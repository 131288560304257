
import { Component, Vue, Watch } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import { UserLoginDto } from '@/dtos/userLoginDto'
import { UserClaimDto } from '@/dtos/userClaimDto'
import rules from '@/store/rules'

@Component({
    components: {
    }
})
export default class UserManagement extends Vue {
	private users: Array<UserLoginDto> = new Array<UserLoginDto>();
	private currentUserId: string = '';
    private deleteOverlay: boolean = false;
	private userDeleteWarningDialog: boolean = false;
	private addUserDialog: boolean = false;
	private newUser: UserLoginDto = new UserLoginDto();
	private isValidEmail: boolean = false;
	private validEmailRule: any = rules.requiredEmailRule;
	private userToRemove: UserLoginDto | null = null;
	private savingClaim: boolean = false;

    private mounted() {
		this.getCurrentUserId();
		this.loadUsers();
		this.newUser.canOrderIndirectly = true;
    }

	@Watch('addUserDialog')
	private validateForm() {
		if (this.addUserDialog === true) {
			(this.$refs.form as any).validate();
		}
	}

	private get usersLoaded(): boolean {
		return this.users.length > 0;
	}

	private get isLoading(): boolean {
		return this.$store.state.loading;
	}

	// Puts the logged in user at first position
	// Then sorts by confirmed/not confirmed
	// Then sorts by email alphabetically
	private get sortedUsers(): UserLoginDto[] {
		let usersCopy = JSON.parse(JSON.stringify(this.users));
		let currentUser = usersCopy.filter((u: UserLoginDto) => u.userId === this.currentUserId)[0];
		let currentUserIndex = usersCopy.indexOf(currentUser);
		if (currentUserIndex > -1) {
			usersCopy.splice(currentUserIndex, 1);
		}
		usersCopy.sort((u1: UserLoginDto, u2: UserLoginDto) => {
			if (u1.confirmed && !u2.confirmed) return -1;
			if (!u1.confirmed && u2.confirmed) return 1;

			var textA = u1.username.toUpperCase();
			var textB = u2.username.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});
		if (currentUserIndex > -1) {
			usersCopy.unshift(currentUser);
		}
		return usersCopy;
	}

	private get currentUser(): UserLoginDto {
		if (this.usersLoaded && this.currentUserId !== '') {
			let user = this.users.filter((u: UserLoginDto) => u.userId === this.currentUserId);
			if (user.length > 0) {
				return user[0];
			}
		}
		return new UserLoginDto();
	}

	private get userCanManageUsers() {
		return this.currentUser.canManageUsers;
	}

	private userIsLoggedInUser(userId: string) {
		return this.currentUserId === userId;
	}

	private isOnlyTrueManageUser(canManageUser: boolean) {
		if (!canManageUser) {
			return false;
		}
		let manageUserUsersCount = this.users.filter((u: UserLoginDto) => u.canManageUsers).length;
		return manageUserUsersCount < 2
	}

	private isOnlyDirectOrderUser(canOrderDirectly: boolean) {
		if (!canOrderDirectly) {
			return false;
		}
		let directOrderUsersCount = this.users.filter((u: UserLoginDto) => u.canOrderDirectly).length;
		return directOrderUsersCount < 2
	}

	private async getCurrentUserId() {
		try {
			const response = await httpClient().get('Customer/GetCurrentUserId');
			this.currentUserId = response.data;
		} catch(err) {
			console.log('Error while getting current user id', err);
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Laden der Benutzer-Id.');
		}
	}

	private async loadUsers() {
		try {
			this.$store.commit('setLoading', true);
			const response = await httpClient().get('Customer/GetUsersOfCustomer');
			this.users = response.data;
		} catch(err) {
			console.log('Error while loading users', err);
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Laden der Benutzer.');
		} finally {
			this.$store.commit('setLoading', false);
		}
	}

	private async saveClaim(userId: string, username: string, claimType: string, claimValue: boolean) {
		console.log(`Setting claim ${claimType} to ${claimValue} for user ${username}...`);
		try {
			this.savingClaim = true;
			this.updateLocalUser(userId, claimType, claimValue);
			let claimDto: UserClaimDto = {
				userId: userId,
				username: username,
				claimType: claimType,
				claimValue: claimValue ? 'true' : 'false'
			};			
			const response = await httpClient().post('Customer/SaveUserClaim', claimDto);
			if (response.status === 200) {
				this.$store.dispatch('setSnackbarText', 'Berechtigung erfolgreich gespeichert.');
			} else {
				throw `Error status code: ${response.status}`;
			}
		} catch(err) {
			console.log('Error while saving user claim', err);
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Speichern der Berechtigung.');
		} finally {
			this.savingClaim = false;
		}
	}

	private updateLocalUser(userId: string, claimType: string, claimValue: boolean) {
		let user = this.users.find((u:UserLoginDto) => u.userId === userId);
		if (!user) return;
		switch (claimType) {
			case 'ManageUser':
				user.canManageUsers = claimValue;
				break;
			case 'DirectOrder':
				user.canOrderDirectly = claimValue;
				break;
			case 'IndirectOrder':
				user.canOrderIndirectly = claimValue;
				break;
			case 'ChangeDeliveryAddress':
				user.canChangeDeliveryAddress = claimValue;
				break;
			case 'ChangeInvoiceAddress':
				user.canChangeInvoiceAddress = claimValue;
				break;
		}
	}

	private async addUser() {
		let successful = false;
		try {
			this.$store.commit('setLoading', true);
			const response = await httpClient().post(`Customer/InviteUser?invitedByUsername=${this.currentUser.username}`, this.newUser);
			if (response.status === 200) {
				this.$store.dispatch('setSnackbarText', 'Einladung erfolgreich an Benutzer gesendet.');
				this.addUserDialog = false;
				this.newUser = new UserLoginDto();
				this.newUser.canOrderIndirectly = true;
				successful = true;
			} else {
				throw `Error status code: ${response.status}`;
			}
		} catch(err) {
			console.error('Error while adding user', err);
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim versenden der Einladung an den Benutzer.');
		} finally {
			this.$store.commit('setLoading', false);
		}
		// Reload users to get the newly invited one
		if (successful) {
			await this.loadUsers();
		}
	}

	private async removeUserFromAccount() {
		try {
			this.$store.commit('setLoading', true);
			await httpClient().post(`Customer/removeUserFromAccount?username=${this.userToRemove?.username}`);
			await this.loadUsers();
			this.userDeleteWarningDialog = false;
			this.userToRemove = null;
		} catch(err) {
			console.error('Error while removing user', err);
			this.$store.dispatch('setSnackbarErrorText', 'Fehler beim Löschen des Benutzers.');
		} finally {
			this.$store.commit('setLoading', false);
		}
	}

	private goToUserSettings() {
		this.$router.push({ 
			name: 'Settings', 
			params: { 
				tab: 'user', 
			} 
		});
	}
}
