
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';
import httpClient from '../../store/httpClient';
import rules from '@/store/rules'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { WerkstoffDto } from '@/dtos/werkstoffDto';
import { ArticleDataDto } from '@/dtos/articleDataDto';
import { MaterialDto } from '@/dtos/materialDto';
import ImportArticle from './ImportArticle.vue';

@Component({
    components: {
        ImportArticle,
    }
})
export default class ImportAssembly extends Vue {
    @Prop()
    private articleId!: number;
    private articleName: string = '';
    private articleNumber: string = '';
    private deleting: boolean = false;
    private articleDataValid: boolean = true;
    private requiredStringMax50Rule: any = rules.requiredStringMax50Rule;
    private optionalStringMax50Rule: any = rules.optionalStringMax50Rule;
    private selectedWerkstoffId: number = 0;

    private mounted() {
        this.articleName = this.article.customerArticleName;
    }

    private get article() : CustomerArticleDto {
        return this.$store.state.importingArticles
            .find((a: CustomerArticleDto) => a.articleId == this.articleId);
    }

    private get subArticles() : CustomerArticleDto[] {
        return this.$store.state.importingArticles
            .filter((a: CustomerArticleDto) => a.parentArticleId == this.articleId);
    }

    private get subArticlesValid() : boolean {
        return this.subArticles.every(a => a.werkstoffId > 0);
    }

    @Watch('selectedWerkstoffId')
    private selectedWerkstoffIdChanged() {
        if (this.selectedWerkstoffId > 0) {
            for (const article of this.subArticles) {
                article.werkstoffId = this.selectedWerkstoffId;
            }
        }
    }

    /**
     * Gets the raw materials that are available for ALL subarticles
     */
    private get selectableWerkstoffe() {
        var availableWerkstoffe: WerkstoffDto[] = [];
        for (const article of this.subArticles) {
            var werkstoffeForArticle = (this.$store.state.werkstoffe as WerkstoffDto[])
                .filter((w: WerkstoffDto) => w.materials.some((m:MaterialDto) => m.thickness === article.thickness));
            if (werkstoffeForArticle.length == 0) {
                werkstoffeForArticle = this.$store.state.werkstoffe as WerkstoffDto[];
            }
            if (availableWerkstoffe.length > 0) {
                for (const [index, werkstoff] of availableWerkstoffe.entries()) {
                    if (!werkstoffeForArticle.some(w => w.blexonId == werkstoff.blexonId)) {
                        // The current article does not allow the raw material.
                        // We need to remove the material from the list.
                        availableWerkstoffe.splice(index, 1);
                    }
                }
            } else {
                // Initial value
                availableWerkstoffe = werkstoffeForArticle;
            }
        }
        return availableWerkstoffe;
    }

    @Watch('isCalculating')
    private resetCalculatingMessage() {
        if(!this.isCalculating) {
            this.article.calculatingMessage = '';
        }
    }

    @Watch('article.customerArticleName')
    private updateArticleName() {
        this.articleName = this.article.customerArticleName;
    }

    private get isCalculating() {
        return this.article.calculating;
    }

    private get articleInitialized() {
        return this.article.articleId >= 0;
    }

    private async blexonisieren() {
        var werkstoffId = 3; // This is AlMg3
        for (const article of this.subArticles) {
            this.$store.dispatch('blexonisieren', {article: article, validate: true});
            werkstoffId = article.werkstoffId;
        }
        // The assembly needs a raw material to disappear from the importing articles
        // but it does not matter what raw material it is.
        this.article.werkstoffId = werkstoffId;
        this.$store.dispatch('blexonisieren', {article: this.article, validate: false});
    }

    private getThumbnailUrl(articleId: number) {
        return this.$store.getters.getThumbnailUrl(articleId, undefined);
    }

    private async deleteImportingArticle() {
        try {
            this.deleting = true;
            const response = await httpClient().post(`article/deleteArticle?articleId=${this.article.articleId}`);
            Vue.nextTick(() => {                       
                console.log(`Artikel ${this.article.articleId} wurde gelöscht`);
                this.$store.commit('deleteArticle', this.article.articleId);
            });
        } catch(err) {
            console.log(`Fehler beim Löschen des Artikels. ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Artikel konnte nicht gelöscht werden.');
        } finally {
            this.deleting = false;
        }
    }

    private async saveArticleData() {
        if(!this.articleDataValid 
            || (this.article.customerArticleName === this.articleName && this.article.customerArticleId === this.articleNumber))
        {
            return;
        }
        let articleData: ArticleDataDto = {
            articleId: this.article.articleId,
            customerArticleId: this.articleNumber,
            customerArticleName: this.articleName,
        };
        try {
            await httpClient().post('article/UpdateArticleData', articleData);
            this.$store.commit('saveArticleData', articleData);
        } catch(err) {
            console.log(`Failed to save article data of article ${this.article.articleId}. Error: ${err}`);
        }
    }
}
