
import { Component, Vue, Prop } from "vue-property-decorator";
import httpClient from "../../store/httpClient";
import ArticlePriceInfoDialog from "@/views/Dialogs/ArticlePriceInfoDialog.vue";
import ArticleEditDialog from "@/views/Dialogs/ArticleEditDialog.vue";
import ArticleDeleteWarningDialog from "@/views/Dialogs/ArticleDeleteWarningDialog.vue";
import SpeechBubbleFloat from "../Dialogs/SpeechBubbleFloat.vue";
import { CustomerArticleDto } from "@/dtos/customerArticleDto";
import ArticleTileHeader from "@/views/Catalogue/ArticleTileComponents/ArticleTileHeader.vue"
import { TokenResponseDto } from "@/dtos/tokenResponseDto";
import { PowderFinish, PowderStructure } from "@/dtos/treatmentTypes";
import { ArticleState } from "@/dtos/articleState";
import ArticleTilePricelist from "./ArticleTileComponents/ArticleTilePricelist.vue";
import { PriceState } from "@/dtos/priceState";
import rules from '@/store/rules'
import { CartItem } from "@/dtos/cartItem";
import { CheckArticleReason } from "@/dtos/checkArticleReason";
import SendToHelpdeskDialog from '@/views/Dialogs/SendToHelpdeskDialog.vue'
import TooManyCalculatingDialog from '@/views/Dialogs/TooManyCalculatingDialog.vue'
import ArticleTreatmentEditDialog from '@/views/Dialogs/ArticleTreatmentEditDialog.vue'
import IsPowdercoatingPossibleDialog from '@/views/Dialogs/IsPowdercoatingPossibleDialog.vue'
import CertificateHelperDialog from '@/views/Dialogs/CertificateHelperDialog.vue'
import { getEntgratartText } from '@/dtos/entgratart';
import { CertificateTypes } from '@/dtos/certificateTypes';
import { AssemblyInfoDto } from '@/dtos/assemblyInfoDto';

@Component({
	components: {
		ArticlePriceInfoDialog,
		ArticleEditDialog,
		ArticleDeleteWarningDialog,
        SendToHelpdeskDialog,
        TooManyCalculatingDialog,
        ArticleTreatmentEditDialog,
        CertificateHelperDialog,
		SpeechBubbleFloat,
        ArticleTileHeader,
        ArticleTilePricelist,
        IsPowdercoatingPossibleDialog
	},
})
export default class ArticleTile extends Vue {
	@Prop()
	private articleId!: number;
	@Prop()
	private isAssembly!: boolean;
    @Prop()
    private isAssemblyView!: boolean;
    @Prop()
    private isDeleteDisabled!: boolean;

    private amountToAddToCart: number = 1;
    private showState: boolean = false;
    private cadDialog: boolean = false;
    private currentWindowHeight: string = '100px';
	private deleteOverlay: boolean = false;
	private articleDeleteWarningDialog: boolean = false;
	private articleCartNames: string[] = [];
	private articleEditDialog: boolean = false;
    private articleToken: TokenResponseDto | null = null;
    private isValidCartAmount: boolean = true;
    private allowedCartAmountRule = rules.allowedCartAmountRule;
    private showHelpdeskDialogue: boolean = false;
    private reasonToSendArticleToHelpdesk: CheckArticleReason = CheckArticleReason.ModelError;
    private tooManyCalculatingDialog: boolean = false;
    private articleTreatmentEditDialog: boolean = false;
    private certificateHelperDialog: boolean = false;
    private getEntgratartText = getEntgratartText;
    private assemblyInfo: AssemblyInfoDto | null = null;

    private isPowdercoatingPossibleDialog: boolean = false;
    private mounted() {
        if (this.isAssembly) {
            this.$store.dispatch('loadMissingArticles', this.article.subArticleIds)
                .then(() => {
                    this.loadAssemblyInfo(this.articleId);
                })
                .then(() => {
                    this.showState = true;
                });
        } else {
            this.showState = true;
        }
        this.setWindowHeight();
        window.addEventListener('resize', event => {
            this.setWindowHeight();
        });
        if (this.article.quantityInAssembly != null) {
            this.amountToAddToCart = this.article.quantityInAssembly;
        }
    }

	private get article(): CustomerArticleDto {
		return this.$store.state.articles.find((a: CustomerArticleDto) => a.articleId == this.articleId);
	}

    private get subArticles() : CustomerArticleDto[] {
        if (!this.isAssembly) return [];
        return this.$store.state.articles.filter((a:CustomerArticleDto) => a.parentArticleId == this.articleId);
    }

    // This is a helper getter to quickly print state to the console
    private get singleArticleState() {
        return {
            state: this.article.state,
            priceState: this.priceState,
            calculating: this.isCalculating,
            locked: this.isLocked,
            migrated: this.isMigrated,
            canBeProduced: this.canBeProduced
        };
    }

    private get assemblyState() {
        const locked : boolean = this.article.locked || this.subArticles.some((a: CustomerArticleDto) => a.locked);
        let state = ArticleState.OK;
        if (this.subArticles.length <= 0 || this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Error)) {
            state = ArticleState.Error;
        } else if (this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Info)) {
            state = ArticleState.Info;
        } else if (this.subArticles.some((a: CustomerArticleDto) => a.state == ArticleState.Warning)) {
            state = ArticleState.Warning;
        }
        const articleCalculating = this.article.calculating || this.subArticles.some((a: CustomerArticleDto) => a.calculating);
        const priceCalculating = this.subArticles.some((a: CustomerArticleDto) => a.priceState == PriceState.Calculating);
        const calculating = articleCalculating || priceCalculating;
        let priceState = PriceState.Ok;
        if (this.subArticles.some((a: CustomerArticleDto) => 
                a.priceState == PriceState.NotCalculated || 
                a.priceState == PriceState.Invalid || 
                a.priceState == PriceState.Failed)) {
            priceState = PriceState.Invalid;
        } else if (priceCalculating) {
            priceState = PriceState.Calculating;
        }
        const canBeProduced = this.subArticles.every((a: CustomerArticleDto) => a.canBeProduced);
        const migrated = this.article.migrated || this.subArticles.some((sa:CustomerArticleDto) => sa.migrated);
        const returnState = {
            locked: locked,
            migrated: migrated,
            state: state,
            priceState: priceState,
            articleCalculating: articleCalculating,
            priceCalculating: priceCalculating,
            calculating: calculating,
            canBeProduced: canBeProduced,
        }
        if (this.article.articleId === -1) {
            console.log({
                states: this.subArticles.map(sa => sa.state),
                priceStates: this.subArticles.map(sa => sa.priceState),
                calculatings: this.subArticles.map(sa => sa.calculating),
            })
            console.log(returnState);
        }
        return returnState;
    }

    private get state() {
        if (this.article.articleId === -1) {
            console.log(this.singleArticleState)
        }
        return this.isAssembly ? this.assemblyState.state : this.article.state;
    }

    private get priceState() {
        return this.isAssembly ? this.assemblyState.priceState : this.article.priceState;
    }

    private get isCalculating() {
        return this.isAssembly ? this.assemblyState.calculating : this.article.calculating;
    }

	private get isLocked() {
        return this.isAssembly ? this.assemblyState.locked : this.article.locked;
	}

	private get isMigrated() {
        return this.isAssembly ? this.assemblyState.migrated : this.article.migrated;
	}

    private get canBeProduced() {
        return this.isAssembly ? this.assemblyState.canBeProduced : this.article.canBeProduced;
    }

    private get assemblyWewicht() {
        return this.subArticles.reduce((previousValue, a) => {
            return previousValue + a.weight * a.quantityInAssembly;
        }, 0);
    }

    private get hasEngravings() {
        return this.isAssembly ? 
            this.subArticles.some((sa:CustomerArticleDto) => sa.hasEngravings) :
            this.article.hasEngravings;
    }

    private get hasWeldlines() {
        return this.article.weldlines != null && this.article.weldlines.length > 0;
    }

    private get isPowderCoatingAvailable() {
        return this.$store.state.isPowderCoatingAvailable;
    }

    private get articleHasPowderCoating() {
        // Powdercoating is assigned to assembly, not subarticles
        return this.article.powderCoatingTreatment != null;
    }

    private get powderCoatingText() {
        if (!this.articleHasPowderCoating) return "Keine";
        return `RAL\u00A0${this.article.powderCoatingTreatment?.powder.surfaceColor.ralCode}`;
    }

    private get hasFeatures() {
        if (this.isAssembly) {
            return this.article.powderCoatingTreatment != null || 
                this.article.weldlines.length > 0;                
        } else {
            return this.article.powderCoatingTreatment != null || 
                this.article.featureDtos.length > 0 || 
                this.article.hasEngravings ||
                this.article.weldlines.length > 0;
        }
    }

    private get isArticleLockedAndNotAdmin() {
        if (this.$store.state.customer.user.isAdmin || this.isImpersonated) {
            return false;
        }
        return this.isLocked;
    }

    private get isArticleUnlockedAndAdmin() {
        return !this.isLocked && (this.$store.state.customer.user.isAdmin || this.isImpersonated);
    }

    private get isArticleLockedAndAdmin() {
        return this.isLocked && (this.$store.state.customer.user.isAdmin || this.isImpersonated);
    }

    private get isImpersonated() {
        var oldToken = window.localStorage.getItem(
            `oidc.user:${process.env.VUE_APP_authorityUrl}:BlexonKundenportalClient_`
        );
        if (oldToken != null) {
            return true;
        }
        return false;
    }

    private get region(){
        return this.$store.getters.customerDefaultDeliveryAddress.country;
    }

    private get isStatusChipLinkToCad() {
        return ((this.state === ArticleState.Warning || 
            this.state === ArticleState.Error ||
            this.state === ArticleState.Info)
            && !this.isMigrated);
    }

    private get isCartButtonRowVisible() {
        if (this.isCalculating) return false;
        if (this.isMigrated) return false;
        if (this.isLocked) return false;
        if (this.isAssemblyView) return false;
        if (this.state === ArticleState.OK || this.state === ArticleState.Warning) {
            if (this.priceState !== PriceState.Failed && this.priceState !== PriceState.Invalid) {
                return true;
            }
        }
        return false;
    }

    private get isCartButtonEnabled() {
        if (this.isAssemblyView) {
            // Articles can't be added to the cart if they're part of an assembly
            return false;
        }
        if (!this.isValidCartAmount || !this.canBeProduced || this.isCalculating ||
            (this.state !== ArticleState.OK && this.state !== ArticleState.Warning) ||
            this.priceState !== PriceState.Ok) {
            return false;
        }
        return true;
    }

    private get isHelpdeskButtonVisible() {
        if (this.isAssembly) return false;
        if (this.isMigrated || this.isCalculating) {
            return false;
        }
        if (this.state === ArticleState.Error || this.isLocked) {
            if (this.priceState !== PriceState.Calculating){
                return true;
            }
        }
        if (this.state === ArticleState.OK || this.state === ArticleState.Warning) {
            if (this.priceState === PriceState.Failed || this.priceState == PriceState.Invalid) {
                return true;
            }
        }
        return false;
    }

    private get isHelpdeskButtonDisabled() {
        if (this.isCalculating) return false;
        if (this.isLocked && this.priceState !== PriceState.Calculating) {
            return true;
        }
        return false;
    }

    private get isCadButtonVisible() {
        if (this.isCalculating || this.isMigrated) {
            return false;
        } 
        return true;
    }

    private getThumbnailUrl(id: number) {
        return this.$store.getters.getThumbnailUrl(id, this.article.lastChange);
    }

    private navigateTo(link:string) {
        this.$router.push({ path: link });
    }

    private get articleCadViewUrl() {
        const email = encodeURI(this.$store.state.customer.user.username);
        const url = process.env.VUE_APP_cadClientUrl + `artikel?id=${this.article.articleId}` +
            `&token=${this.articleToken?.token}&readonly=${this.articleToken?.readonly}` +
            `&defaultEMail=${email}&locked=${this.isLocked}`;
        return url;
    }

    private get certificates() : string[] {
        const material = this.$store.getters.getMaterial(this.article.materialId)
        if (material === undefined) {
            return []
        }
        if (material.certificateTypes !== CertificateTypes.None) {
            return ['3.1']
        } else {
            return []
        }
    }
    private get certificate() : boolean {
        return this.article.certificateTypes > 0
    }
    private set certificate(value: boolean) {
        if (value) {
            this.article.certificateTypes = CertificateTypes.ThreeDotOne
        } else {
            this.article.certificateTypes = CertificateTypes.None
        }
        // This sends an article update via SignalR
        // So the article doesn't have to be updated manually in the frontend.
        httpClient().post(`article/SetCertificate?articleId=${this.article.articleId}&certificateTypes=${this.article.certificateTypes}`)
    }

    private setDeleteOverlay(value: boolean) {
        this.deleteOverlay = value;
    }

    private addToCart(quantity: number | null) {
        let newCartItem = new CartItem();
        const quantityToAdd = quantity ? quantity : this.amountToAddToCart;
        newCartItem.articleId = this.articleId;
        newCartItem.quantity = parseInt(quantityToAdd as any);
        this.$store.dispatch("addToCart", newCartItem);
        this.$store.dispatch(
            'setSnackbarArticleText', 
            { 
                text: `${quantityToAdd} Stück in den Warenkorb gelegt.`, 
                articleId: this.articleId 
            }
        );
        if (this.article.quantityInAssembly != null) {
            this.amountToAddToCart = this.article.quantityInAssembly;
        } else {
            this.amountToAddToCart = 1;
        }
    }

    private addQuantityToCart(quantity: number) {
        console.log(`Adding to cart with quantity: ${quantity}`);
        this.addToCart(quantity);
    }

    private async checkDeleteArticle() {
		try {
			this.articleCartNames = [];
            // If this is in the assembly view (the article is part of an assembly but is itself not an assembly),
            // the id of the parent has to be used to check because subarticles themselves are not in saved carts.
            // It's always the assembly that's in the saved cart.
            const idToCheckForInSavedCarts = this.isAssemblyView ? this.article.parentArticleId : this.articleId;
			const response = await httpClient().get(`Cart/GetCartsOfArticle?articleId=${idToCheckForInSavedCarts}`);
			if (response.data.length > 0) {
				this.articleCartNames = response.data.sort();
			}

            // If this is a subarticle, load the assembly info to find out if the
            // article to be deleted is welded to any others
            if (this.isAssemblyView) {
                await this.loadAssemblyInfo(this.article.parentArticleId);
            }
			this.articleDeleteWarningDialog = true;
		} catch (err) {
			console.error(`Could not retrieve carts of article ${this.articleId}.`);
			this.$store.dispatch(
				"setSnackbarErrorText",
				"Aktuell kann nicht überprüft werden, ob der Artikel in einer Stückliste verwendet wird."
			);
		}
	}

    private async revalidate() {
        try {
            this.article.calculating = true;
            const response = await httpClient().post(`article/revalidate?articleId=${this.articleId}`);
            if (response.data === false) {
                // zu viele von diesem Kunden sind am blexonisieren.
                this.tooManyCalculatingDialog = true;
            }
        } catch(err) {
            console.log(`Fehler beim erneuten Validieren des Artikels. Error: ${err}`);
        }
    }

    private async loadAssemblyInfo(articleId: number) {
        try {
            const response = await httpClient().get(`article/GetAssemblyInfo?articleId=${articleId}`);
            this.assemblyInfo = response.data;
        } catch(ex) {
            console.log("Assembly info could not be loaded for assembly " + this.articleId);
        }
    }

    private async openCadDialog() {
        try {
            if (this.isCalculating || this.isMigrated) {
                return;
            }
            const response = await httpClient().post(`article/GetArticleToken?articleId=${this.articleId}`);
            this.articleToken = response.data;
            if (this.articleToken != null) {
                window.addEventListener('message', this.handleArticleChangedInCad);
                this.cadDialog = true;
            }
        } catch(err) {
            console.log(`Für den Artikel konnte kein Token geladen werden. Error: ${err}`);
        }
    }

    /**
     * This event handler is called, when the cad article in the iframe has changed.
     */
    private handleArticleChangedInCad (event: any) {
        if (event.origin == 'https://cad.test.blexon.com' || 
            event.origin == 'https://cad.blexon.com' || 
            event.origin == 'http://localhost:8180') {
                
            if (event.data == `article-${this.articleId}-closed`) {
                this.closeCadDialog(true);
            } else if (event.data == `article-${this.articleId}-saved`) {
                this.closeCadDialog(false);
            }
        }
    }

    private async closeCadDialog(deleteToken: boolean) {
        console.log("closing cad dialog");
        this.cadDialog = false;
        window.removeEventListener('message', this.handleArticleChangedInCad);
        // delete token only when the article was not saved.
        // Wenn der Artikel gespeichert wurde, wird das Token nach der Validierung gelöscht.
        if (deleteToken) {
            console.log("deleting token", this.articleToken?.token);
            await httpClient().post(`article/DeleteToken?token=${this.articleToken?.token}`);
        }
        this.articleToken = null;
    }

    private setWindowHeight () {
        // 165 besteht aus...
        // - Margins der Dialogbox (variieren etwas je nach Aspect Ratio)
        // - Buttons für Speichern / Schliessen im Dialogfenster
        // - Sicherheitsmarge, sodass sicher keine Scrollbar im Dialog erscheint
        const currentHeight = window.innerHeight - 65
        this.currentWindowHeight = `${currentHeight}px`
    }

	private openArticleEditDialog() {
        if (!this.isArticleLockedAndNotAdmin && !this.isCalculating) {
            this.articleEditDialog = true;
        }
	}

    /**
     * Creates a text to show at the user interface to inform the user
     * about the weldline features.
     */
    private getWeldlineString() : string {
        let text = '';
        
        if (this.article.weldlines.length === 1) {
            text = '1 Schweißnaht';
        } else if (this.article.weldlines.length > 1)  {
            text = `${this.article.weldlines.length} Schweißnähte`
        }
        const numberOfCorners = this.article.weldlines.reduce( (a, b) => {
            return a + b.corners.length;
        }, 0);
        if (numberOfCorners === 1) {
            text += ` | 1 Ecke`
        } else if (numberOfCorners > 1) {
            text += ` | ${numberOfCorners} Ecken`
        }
        if (this.article.weldlines.some(w => w.clean)) {
            text += ` | verputzt`
        } else {
            text += ` | unverputzt`
        }
        return text;
    }

    private getZusatzbezeichnungOberseite() {
        if (this.article.materialname != null) {
            if (this.article.materialname.includes('geschliffen')) {
                return ' / Schliff / Folie'
            }
            if (this.article.materialname.includes('eloxiert')) {
                return ' / Folie'
            }
        }
        return ''
    }

    private getZusatzbezeichnungUnterseite() {
        if (this.article.materialname != null) {
            if (this.article.materialname.includes('Riffel')) {
                return ' / Riffeln'
            }
        }
        return ''
    }

    private getFinishText(value: number) {
        switch (value) {
            case PowderFinish.Matt: {
                return "Matt";
            }
            case PowderFinish.Glossy: {
                return "Glanz";
            }
            default: {
                return "Nicht verfügbar";
            }
        }
    }

    private getStructureText(value: number) {
        switch (value) {
            case PowderStructure.Smooth: {
                return "Glatt";
            }
            case PowderStructure.Fine: {
                return "Fein";
            }
            case PowderStructure.Rough: {
                return "Grob";
            }
            default: {
                return "Nicht verfügbar";
            }
        }
    }

    private get articleStateText() {
        if(this.article.locked) {
            return 'In Überprüfung';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'OK';
            }
            case ArticleState.Warning: { 
                return 'Warnung';
            }
            case ArticleState.Error: { 
                return 'Fehler';
            }
            case ArticleState.New: { 
                return 'Neu';
            }
            case ArticleState.Info: { 
                return 'Aktion erforderlich';
            } 
            default: { 
                return 'Unklar';
            } 
        }     
    }
    private get articleStateIcon() {
        if(this.article.locked) {
            return 'mdi-lock';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'mdi-check';
            } 
            case ArticleState.Warning: { 
                return 'mdi-exclamation';
            } 
            case ArticleState.Error: { 
                return 'mdi-close';
            } 
            case ArticleState.New: { 
                return 'mdi-new-box';
            }
            default: { 
                return 'mdi-help';
            } 
        }     
    }
    private get articleStateColor() {
        if(this.article.locked) {
            return '#CFD8DC';
        }
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return '#C8E6C9';
            }
            case ArticleState.Warning: { 
                return '#FFF9C4';
            }
            case ArticleState.Error: { 
                return '#FFCDD2';
            }
            case ArticleState.Info: { 
                return '#efbb20';
            }
            default: {
                return 'blue';
            }
        }
    }    


}
